import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { checkAccess, paginateData } from "../../../services/utils";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import { InputWrapper } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import api from "../../../services/ControlPanel";
import { toast } from "react-toastify";
import {
  TabLinkContainer,
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  Input,
  MenuDiv,
  Search,
  TabContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Pagination from "../../pagination_one/pagination";
import Modal from "../../modal/Modal";
import Loader from "../../pageLoader/loader";

const YearTutorSetup = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  // const [sQuery, setSQuery] = useState("");
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [staffQuery, setStaffQuery] = useState("");
  const [staffId, setStaffId] = useState("");
  const [rowId, setRowId] = useState("");
  const [assignLoading, setAssignLoading] = useState(false);
  const [yearObj, setYearObj] = useState({
    id: "",
    name: "",
  });

  const years = [
    {
      name: "Js 1",
      id: 1,
    },
    {
      name: "Js 2",
      id: 2,
    },
    {
      name: "Js 3",
      id: 3,
    },
    {
      name: "SS 1",
      id: 4,
    },
    {
      name: "SS 2",
      id: 5,
    },
    {
      name: "SS 3",
      id: 6,
    },
  ];

  console.log(list)

  const filterStaff = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      data.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setList(arr);
    },
    [data]
  );

  const getYears = useCallback(async () => {
    try {
      const res = await api.getYearTutors(admToken, user.schoolId);
      setAllData(res.data);
      setPageLoading(false);
      if (!res) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  }, [admToken, user.schoolId]);
  
  const getAllStaff = useCallback(async () => {
    try {
      const res = await api.getUsersCredential(admToken, user.schoolId, 3);
      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  const assignYearTutor = useCallback(
    async (id, type) => {
      const data = {
        staffId: id,
        schoolId: user.schoolId,
        year: yearObj.id,
        assign: type === "add" ? true : false
      };
      try {
        setAssignLoading(true);
        const res = await api.assignYearTutor(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setOpenAssign(false);
          setCurrentPage(1);
          getYears();
        }
        setAssignLoading(false);
        setRowId("");
      } catch (error) {
        toast.error("Error Assigning Year tutor");
        setRowId("");
      }
    },
    [admToken, getYears, user.schoolId, yearObj.id]
  );

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="13px">
        Assign year tutor to {yearObj.name}
      </BigHeader>
      <InputWrapper>
        <input
          type="text"
          placeholder="Search For Staff"
          onChange={(e) => {
            filterStaff(e);
            setStaffQuery(e.target.value);
          }}
          value={staffQuery}
        />
      </InputWrapper>
      <MenuDiv style={{ padding: isTab ? "0.6rem" : "0.8rem" }}>
        {list.length > 0 &&
          list.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{item.fullName}</div>
              <button
                disabled={staffId === item.credentialId && assignLoading}
                style={{
                  backgroundColor: colors.primary,
                  color: "white",
                  borderRadius: "20px",
                  padding: "0.3rem 0.6rem",
                }}
                onClick={() => {
                  setStaffId(item.credentialId);
                  assignYearTutor(item.credentialId, "add");
                  setStaffQuery("")
                }}
              >
                {staffId === item.credentialId && assignLoading
                  ? "Please wait..."
                  : "Assign"}
              </button>
            </div>
          ))}
      </MenuDiv>
    </>
  );

  useEffect(() => {
    getYears();
    getAllStaff();
  }, [getAllStaff, getYears]);

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <GlobalStyles />
      {openAssign && (
        <Modal content={content} size="large" onClose={setOpenAssign} />
      )}
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          Year Tutor Setup
        </BigHeader>
      </div>
      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        {/* <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Staff"
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
              }}
            />
          </Input>
        </TabLinkContainer> */}
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>Year</th>
              <th>Year tutor</th>
              <th>Class count</th>
              <th></th>
            </tr>
            {years.map((year, index) => {
              const yearTutorData = allData.find(
                (data) => data.year === year.id
              );
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{year.name}</td>
                  <td>
                    {yearTutorData
                      ? `${yearTutorData.firstName} ${yearTutorData.lastName}`
                      : ""}
                  </td>
                  <td>{yearTutorData ? yearTutorData.classCount : ""}</td>
                  <td>
                    {checkAccess(accessObj.rights, "Assign") && (
                      <button
                        disabled={
                          staffId === yearTutorData?.staffId &&
                          assignLoading &&
                          rowId === yearTutorData?.year
                        }
                        style={{
                          backgroundColor: yearTutorData
                            ? colors.primary
                            : "white",
                          color: yearTutorData ? "white" : colors.primary,
                          fontWeight: "bold",
                          border: `1px solid ${colors.primary}`,
                          borderRadius: "08px",
                          padding: "0.3rem 0.6rem",
                          width: "10rem",
                        }}
                        onClick={() => {
                          setRowId(yearTutorData?.year);
                          if (yearTutorData?.staffId) {
                            setStaffId(yearTutorData?.staffId);
                            rowId === yearTutorData?.year &&
                              assignYearTutor(yearTutorData?.staffId, "remove");
                              setStaffQuery("")
                            console.log("Unass");
                          } else {
                            setYearObj({
                              ...yearObj,
                              id: year.id,
                              name: year.name,
                            });

                            setOpenAssign(true);
                          }
                        }}
                      >
                        {staffId === yearTutorData?.staffId &&
                        rowId === yearTutorData?.year &&
                        assignLoading
                          ? "Please wait..."
                          : yearTutorData?.staffId
                          ? "Unassign year tutor"
                          : "Assign year tutor"}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
    </Container>
  );
};

export default YearTutorSetup;
