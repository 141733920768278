import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;

export const MySubject = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const MySubjectClasses = (token, schoolId, subjectId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?schoolId=${schoolId}&subjectId=${subjectId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const AssignSingle = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/AssignSubject/Single`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSessions = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/Results/UpdateScore/Sessions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const getTerms = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/Results/UpdateScore/Terms`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSessionsForPast = (token) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/UpdateScore/LastSessions`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getTermsForPast = (token, sessionId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/UpdateScore/LastSessionTerms?sessionId=${sessionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const StudentListScore = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/UpdateScore/StudentList`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);


export const UpdateStudentScoreSingle = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/UpdateStudentsScore/Single`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getDownloadResult = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/DownloadResult`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const UpdateStudentScore = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/UpdateStudentsScore`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
    
export const MyClasses = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/ClassTeacher?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const TeacherClasses = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/ClassTeacher/MyClasses?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const TeacherClassSubject = (token, schoolId, classCategory) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/GetSubjects/${schoolId}/${classCategory}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const SubjectStudents = (
  token,
  schoolId,
  classId,
  subjectId,
  sessionId
) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/AssignSubject/Students?schoolId=${schoolId}&classId=${classId}&subjectId=${subjectId}&sessionId=${sessionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const AssignMultiple = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/AssignSubject/Multiple`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const GetRemarks = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/Results/Remark/StaffRemarks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const GetRemarkClasses = (token, schoolId, sessionId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/Remark/GetClasses?schoolId=${schoolId}&SessionId=${sessionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const RemarkGetStudent = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/Remark/GetStudents`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const PostRemarkStudent = (token, data) =>
  axios
    .post(`${endpoint_two}/api/v${api_version}/Results/Remark/Update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
//upadte eendpoint below
export const UpdateStudentRemarkSingle = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/Remark/Update/Single`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const GetPsychoStudents = (
  token,
  schoolId,
  classId,
  sessionId,
  termId,
  recordType,
  pageSize,
  pageNumber
) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/Psychocomotor/alldata?schoolid=${schoolId}&classId=${classId}&sessionId=${sessionId}&termId=${termId}&recordType=${recordType}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const GetFixedGrades = ({ token, schoolId }) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/UpdateScore/fixedgrades?schoolId=${schoolId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const studentPrevPsycho = (
  token,
  schoolId,
  sessionId,
  termId,
  classId,
  studentId
) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/Psychocomotor/PreviousPyschomotorResult?schoolid=${schoolId}&sessionId=${sessionId}&termId=${termId}&classId=${classId}&studentId=${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const updatePsycho = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/Psychocomotor/update`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getPromotionStudents = (token, schoolId, classId, sessionId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Results/Promotion/GetStudents?schoolid=${schoolId}&classId=${classId}&sessionId=${sessionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const promoteStudent = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/Promotion/PromoteStudent`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getAttendanceDates = (
  token,
  schoolId,
  sessionId,
  termId,
  classId,
  currentMonth
) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Attendance/GetAttendanceDates?schoolId=${schoolId}&sessionid=${sessionId}&termId=${termId}&classId=${classId}&CurrentMonth=${currentMonth}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getReportAttendanceDates = (
  token,
  schoolId,
  sessionId,
  termId,
  classId
) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/Attendance/Report-AttendanceDates?schoolId=${schoolId}&sessionid=${sessionId}&termId=${termId}&classId=${classId}&CurrentMonth=${0}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const StudentsAttendance = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Attendance/StudentsForAttendance`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const postMultipleAttendance = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Attendance/MarkAttendanceMultiple`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const postSingleAttendance = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Attendance/MarkAttendanceSingle`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const attendanceStudent = (token, studentId, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Attendance/Student/${studentId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getAllClasses = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/GetAllclasses?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const createNewClass = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/CreateNewClass`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const updateClass = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/Update-Class`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const assignClassToTeacher = (token, classId, staffId, type) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/${type}classtoteachers?classID=${classId}&staffID=${staffId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const assignStudentNewClass = (token, studentId, classId, isConfirmed) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/Assign-Student-NewClass?studentId=${studentId}&clasId=${classId}&isConfirmed=${isConfirmed}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const classesPastResult = (
  token,
  schoolId,
  sessionId,
  termId,
  subjectId,
  requestType
) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/Classes-for-past-result?schoolId=${schoolId}&sessionId=${sessionId}&termId=${termId}&subjectId=${subjectId}&requestType=${requestType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const subjectsPastResult = (token, schoolId, sessionId, termId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/Subjects-for-past-result?schoolId=${schoolId}&sessionId=${sessionId}&termId=${termId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
