import { WarningAmberRounded } from "@mui/icons-material";
import { useState } from "react";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { useGetEachReportQuery, useSubmitReportMutation } from "../../api/reportAPI";

export default function SubmitReportModal({isOpen, onClose, id}) {
      const { data, isLoading, refetch } = useGetEachReportQuery(id);
      const [formLoading, setFormLoading] = useState(false);
      const [submitReport] = useSubmitReportMutation();
    
    if (!id) return;
    console.log(data)

    const selectedId = data?.data
  
    const handleSubmit = async () => {
        if(selectedId.path === "N"){
            setFormLoading(false);
            toast.error("You need to submit a file before proceeding");
            return;
        }

        if (selectedId.path && selectedId.path !== "N") {
          setFormLoading(true);

            try {
            const response = await submitReport(id).unwrap();
            if (response.status === true && response.statusCode === 2) {
                    toast.success(response.message);
                    onClose();
                    refetch();
                    } else if (response.status === false && response.statusCode === 5) {
                    setFormLoading(false);
                    toast.error(response.message);
                    } else {
                              setFormLoading(false);
                              toast.error(response.message);
                            }
            } catch (error) {
                console.error("Error submitting lesson note:", error);
            } finally {
              setFormLoading(false);
            }
        };
    }
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 backdrop-blur-[1px] grid place-items-center z-100">
        <div className='bg-white rounded-2xl py-5 px-6 max-w-md border border-[#691e78]'>
          <div className=" flex justify-center rounded-full">
            <WarningAmberRounded sx={{
                bgcolor: "#fff",
                color: "red",
                fontSize: "60px",
              }} 
            />
          </div>
          <div className='mt-5'>
            <h2 className="text-4xl text-primaryRed text-center">Confirm Submit</h2>
            <div>
              <p className="text-center py-4">
                Are you sure you want to Submit this Report?
              </p>
            </div>
            <div className='flex items-center justify-center gap-x-6 mt-5'>
              <button className="text-[#691e78] border border-[#691e78] px-6 py-2 text-center rounded-md w-full" onClick={onClose}>Cancel</button>
              <button
                disabled={formLoading}
                className="text-white border border-[#691e78] bg-[#691e78] px-6 py-2 text-center rounded-md hover:bg-[#691e78]/80 w-full"  onClick={handleSubmit}>
                {formLoading ? (
                        <FormLoader />
                    ) : (
                    <span>submit</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
