import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableHead,
  TextField,
  TableCell,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  Skeleton,
  Modal,
  Typography,
  IconButton,
  Divider,
  Input,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../assets/colors.json";
import { 
  useGetLessonSessionsQuery, useGetLessonSubjectQuery, useGetLessonTermsQuery, useGetLessonWeeksQuery, useGetLessonClassessQuery, usePostLessonNoteMutation
} from "../../api/lessonNoteApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { useSelector } from "react-redux";

const CreateNoteModal = ({ onClose }) => {
  const [formLoading, setFormLoading] = useState(false);
  const userInfo = useSelector((state) => state.auth.user);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const { data: sessions, isLoading: sessionsLoading } = useGetLessonSessionsQuery();
  const { data: lessonTerm, isLoading: termLoading } = useGetLessonTermsQuery();
  const { data: lessonSubject, isLoading: subjectLoading } = useGetLessonSubjectQuery();
  const { data: lessonWeeks, isLoading: weekLoading } = useGetLessonWeeksQuery();
  const { data: lessonNoteClass, isLoading: classLoading } = useGetLessonClassessQuery(selectedSubjectId);
  const [postLessonNote, { isLoading, isError, isSuccess }] = usePostLessonNoteMutation();
  
  useEffect(() => {
    if (sessions?.data?.length > 0 && !formik.values.SessionId) {
      const lastSession = sessions.data[sessions.data.length - 1];
      formik.setFieldValue("SessionId", lastSession.id);
    }
  }, [sessions]);
  


  useEffect(() => {
    if (selectedSubjectId) {
      console.log("Fetching lesson classes for Subject ID:", selectedSubjectId);
    }
    if (!classLoading && lessonNoteClass?.data?.length === 0) {
      toast.info("No classes available for the selected subject.");
    }
  }, [selectedSubjectId, classLoading, lessonNoteClass]);
    
  const validationSchema = Yup.object().shape({
    SessionId: Yup.string().required("School Year is required"),
    Term: Yup.string().required("Term is required"),
    SubjectId: Yup.string().required("Subject is required"),
    ClassArm: Yup.string().required("Class Arm is required"),
    WeekId: Yup.string().required("Week is required"),
  });

  const initialValues = {
    SessionId: "",
    SubjectId: "",
    WeekId: "",
    Term: "",
    ClassArm: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      setFormLoading(true);
      const formData = new FormData();
      formData.append("SessionId", values.SessionId);
      formData.append("SubjectId", values.SubjectId);
      formData.append("WeekId", values.WeekId);
      formData.append("Term", values.Term);
      formData.append("ClassArm", values.ClassArm);

      try {
        const response = await postLessonNote(formData);

        if( response?.error){
          toast.error(response?.error.data.message)
          return;
        }

        if (response.data.status === true && response.data.statusCode === 2) {
          setFormLoading(false);
          toast.success(response.data.message);
          onClose();
        } else if (response.data.status === false && response.data.statusCode === 5) {
          setFormLoading(false);
          toast.error(response.data.message);
        } else if (response.data.status === false && response.data.statusCode === 0) {
          setFormLoading(false);
          toast.error("Internal Server Error");
        } else {
          setFormLoading(false);
          toast.error(response.data.message);
          toast.error(response.error.data.message);
        }
      } catch (error) {
        console.error("Error submitting lesson note:", error);
      } finally{
        setFormLoading(false)
      }
    },
  });

  return (
    <div className="modal fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
      <div className="m-8 w-[360px] sm:w-[400px] md:w-[600px] lg:w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4">
        <div className="flex justify-end ">
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </div>
        <h1 className="text-center text-2xl lg:text-3xl font-semibold">
          Add Lesson Note Setup
        </h1>
        <form onSubmit={formik.handleSubmit}>
          <Box
            p={3}
            sx={{
              overflowY: {
                xs: "scroll",
                sm: "scroll",
                md: "scroll",
                lg: "scroll",
                xl: "auto",
              },
              height: {
                xs: "540px",
                sm: "540px",
                md: "450px",
                lg: "450px",
                xl: "auto",
              },
            }}
          >
            <Box>
              <Grid container spacing={2}>
                {/* session */}
                <Grid item md={6} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      School Year
                    </InputLabel>

                    <Select
                      fullWidth
                      placeholder="dssss"
                      size="small"
                      id="SessionId"
                      name="SessionId"
                      value={formik.values.SessionId ?? ""} 
                      onChange={(event) => {
                        formik.setFieldValue("SessionId", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em> Choose Year</em>
                      </MenuItem>
                      {sessionsLoading && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton
                            variant="rounded"
                            height={60}
                            sx={{ mx: 2 }}
                          />
                        </Box>
                      )}
                      {(!sessions?.data || sessions?.data?.length === 0) && !sessionsLoading ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No Sessions Available
                          </Typography>
                        </Box>
                      ) : (
                        sessions?.data.map((session) => (
                          <MenuItem
                            key={session.id}
                            value={session.id}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {session.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {formik.touched.SessionId && formik.errors.SessionId ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.SessionId}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* term */}
                <Grid item md={6} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Term
                    </InputLabel>

                    <Select
                      fullWidth
                      placeholder="dssss"
                      size="small"
                      id="Term"
                      name="Term"
                      value={formik.values.Term ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("Term", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em> Choose Term</em>
                      </MenuItem>
                      {termLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", mx: 2 }}
                        />
                      </Box>
                    )}
                      {(lessonTerm?.data?.length === 0) && !termLoading ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No term
                        </Typography>
                      </Box>
                    ) : (lessonTerm?.data?.length > 0 && 
                      lessonTerm?.data?.map((term) => (
                        <MenuItem
                          key={term.id}
                          value={term.value}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {term.name}
                        </MenuItem>
                      ))
                    )}
                    </Select>
                    {formik.touched.Term && formik.errors.Term ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.Term}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* Subject */}
                <Grid item md={6} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Subject
                    </InputLabel>
                    <Select
                      fullWidth
                      placeholder="dssss"
                      size="small"
                      id="SubjectId"
                      name="SubjectId"
                      value={formik.values.SubjectId ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("SubjectId", event.target.value);
                        setSelectedSubjectId(event.target.value)
                        console.log("selectedSubjectId", event.target.value)
                      }}
                      onBlur={formik.handleBlur}
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Choose Subject</em>
                      </MenuItem>

                      {/* Loading state */}
                      {subjectLoading && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1rem", mx: 2 }}
                          />
                        </Box>
                      )}

                      {/* No data available state */}
                      {!subjectLoading && !lessonSubject? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No Subject Available
                          </Typography>
                        </Box>
                      ) : (lessonSubject?.map((subject) => (
                          <MenuItem
                            key={subject.id}
                            value={subject.id}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {subject.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {formik.touched.SubjectId && formik.errors.SubjectId ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.SubjectId}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* classArm */}
                <Grid item md={6} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Class
                    </InputLabel>

                    <Select
                      fullWidth
                      placeholder="dssss"
                      size="small"
                      id="ClassArm"
                      name="ClassArm"
                      value={formik.values.ClassArm ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("ClassArm", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em> Choose Class</em>
                      </MenuItem>
                      {classLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", mx: 2 }}
                        />
                      </Box>
                    )}
                      {!lessonNoteClass?.data ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No Subject Available
                        </Typography>
                      </Box>
                    ) : (lessonNoteClass?.data?.length > 0 &&
                      lessonNoteClass?.data.map((classArm) => (
                        <MenuItem
                          key={classArm.id}
                          value={classArm.value}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {classArm.name}
                        </MenuItem>
                      ))
                    )}
                    </Select>
                    {formik.touched.ClassArm && formik.errors.ClassArm ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.ClassArm}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* weeks */}
                <Grid item md={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Week
                    </InputLabel>

                    <Select
                      fullWidth
                      placeholder="dssss"
                      size="small"
                      id="WeekId"
                      name="WeekId"
                      value={formik.values.WeekId ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("WeekId", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em> Choose Week</em>
                      </MenuItem>
                      {weekLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", mx: 2 }}
                        />
                      </Box>
                    )}
                      {!weekLoading && !lessonWeeks?.data ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No Subject Available
                        </Typography>
                      </Box>
                    ) : ( lessonWeeks?.data?.length > 0 &&
                      lessonWeeks?.data.map((weeks) => (
                        <MenuItem
                          key={weeks.id}
                          value={weeks.id}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {weeks.name}
                        </MenuItem>
                      ))
                    )}
                    </Select>
                    {formik.touched.WeekId && formik.errors.WeekId ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.WeekId}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Button
                  disabled={formLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "initial",
                    backgroundColor: colors.primary,
                  }}
                >
                  {formLoading ? (
                      <FormLoader />
                    ) : (
                  <span>Add Lesson Note Setup</span>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CreateNoteModal;
