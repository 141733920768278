import { useState, useCallback, useEffect } from "react";
import { getAllClasses, getSessions, getTerms, getDownloadResult } from "../../../services/Subject";
import api from "../../../services/ControlPanel";
import Select from "../../select/select";
import { useSelector } from "react-redux";
import TableLoader from "../../TableLoader";

import {
  Container,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../../pages/Dashboard/styles/dashboard.style";
import PreviewBulkDownload from './PreviewBulkDownload'
import FormLoader from "../../FormLoader";
import { toast } from "react-toastify";

const DownloadResult = () => {
  const { admToken, user } = useSelector((state) => state.auth);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [terms, setTerms] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const [classObj, setClassObj] = useState({ id: "", name: "" });
  const [session, setSession] = useState({ id: "", name: "" });
  const [term, setTerm] = useState({ id: "", name: "" });

  
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);
      if (res.status && res.statusCode === 2) setSessions(res.data);
    } catch (error) {
      console.error(error);
    }
  }, [admToken]);
  
  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      if (res.status && res.statusCode === 2) setTerms(res.data);
    } catch (error) {
      console.error(error);
    }
  }, [admToken]);

  const getClasses = useCallback(async () => {
    try {
      const res = await getAllClasses(admToken, user.schoolId);
      if (res.status && res.statusCode === 2){ 
        setClasses(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [admToken, user.schoolId]);

  const fetchResults = useCallback(async () => {
    if (!session.id || !term.id || !classObj.id) return;
      const data = {
        sessionId: session.id,
        currentTermId: term.id,
        classId: Number(classObj.id),
        schoolId: user.schoolId,
      }
    try {
      setLoading(true);
      const res = await getDownloadResult(admToken, data);
      if (res.status && res.statusCode === 2){ 
        setResults(res.data);
        setLoading(false);
      } else{
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    } finally{
      setLoading(false);
    }
  }, [session.id, term.id, classObj.id, user.schoolId]);

  useEffect(() => {
    schoolSessions();
    getClasses();
    schoolTerms();
  }, [schoolSessions, getClasses, schoolTerms]);

  const handleDownload = (studentId, termId) => {
    console.log(`Downloading result for studentId: ${studentId}, termId: ${termId}`);
    // Implement actual download logic here
  };

  return (
    <Container>
      <InlineLabelGroup>
        <InlineLabelWrapper flex={3}>
          <Select
            title="Session"
            index="session"
            selected={session.id}
            options={sessions}
            setSelected={(val) => setSession({ id: val.id, name: val.academicSession })}
          />
          <Select
            title="Term"
            index="term"
            selected={term.id}
            options={terms}
            setSelected={(val) => setTerm({ id: val.id, name: val.term })}
          />
            <select
              id="classId"
              name="classId"
              value={classObj.id ?? ""} 
              onChange={(event) => {
                setClassObj({ id: event.target.value });
              }}
              style={{
                width: "100%",
                padding: "14px 16px",
                fontSize: "13px",
                border: "1px solid #671E78",
                borderRadius: "4px",
                fontFamily: "gilroy-regular",
                backgroundColor: "white",
                appearance: "none",
                cursor: "pointer",
                marginTop: "4px"
              }}
            >
              <option className="text-sm text-gray-400" value="">Select Class</option> 
              {classes?.map((classItem) => (
                <option key={classItem.classId} value={classItem.classId}>
                  {classItem.className}
                </option>
              ))}
            </select>

        </InlineLabelWrapper>
      </InlineLabelGroup>
      <div className="flex items-center justify-between pt-5">
        <button onClick={fetchResults} className="text-xs md:text-base px-3 md:px-6 py-2 bg-[#6A0DAD] text-white font-medium rounded-lg hover:bg-[#6A0DAD]/70 transition">
        { loading ? ( 
          <FormLoader />
          ) :
          (
            <span>Search Results</span>
          )
        }</button>

        <div mt={5} align="right">
            <PreviewBulkDownload data={results}/>
        </div>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>Student Name</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Admission No</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Term</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Class</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Session</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {results.map((item, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "8px" }}>{item.studentDetails?.name || "N/A"}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{item.studentDetails?.admissionNo || "N/A"}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{item.studentDetails?.term || "N/A"}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{item.studentDetails?.studentClass || "N/A"}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{item.studentDetails?.academicSession || "N/A"}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <button 
                  onClick={() => handleDownload(item.studentDetails.studentId, item.resultSheet[0]?.subJectResult.termId)}
                  style={{ backgroundColor: "#7F56D9", color: "#fff", padding: "6px 12px", borderRadius: "5px", cursor: "pointer" }}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default DownloadResult;
