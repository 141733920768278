import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  MenuList,
  Skeleton,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Typography,
  Modal,
  FormControl,
  Table,
  TableBody,
  TableContainer,
  Select,
  Popover,
  InputBase,
  TableHead,
  TableRow,
} from "@mui/material";
import { useGetAllLessonNotesQuery, useGetLessonSessionsQuery, useGetLessonTermsQuery, useGetLessonWeeksQuery } from "../../api/lessonNoteApi";
import CreateNoteModal from "./CreateNoteModal";
import { FormateDate } from "../../utils";
import DeleteModal from "./DeleteModal";
import AttachFileModal from "./AttachFileModal";
import PreviewLessonNoteModal from "./PreviewLessonNoteModal";
import SubmitModal from "./SubmitModal";
import TableLoader from "../TableLoader";
import EditLessonNoteModal from "./EditLessonNoteModal";
import RejectedModal from "./RejectedModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  border: "0.5px solid #891e78",
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  session,
  createdBy,
  phone,
  studentClass,
  name,
  status,
  email
) {
  return { session, createdBy, phone, studentClass, name, status, email };
}

const CreateLesson = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allRecords, setAllRecords] = useState([]);
  const [school, setSchool] = useState("");
  const [week, setWeek] = useState("");
  const [term, setTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRejected, setIsRejected] = useState(false);

  const { data, isLoading } = useGetAllLessonNotesQuery({
    searchText: searchTerm,
    pageNumber: page,
    pageSize,
  });
  const { data: sessions, isLoading: sessionsLoading } = useGetLessonSessionsQuery();
  const { data: lessonWeeks, isLoading: weekLoading } = useGetLessonWeeksQuery();
  const { data: lessonTerms, isLoading: termLoading } = useGetLessonTermsQuery();

  const record = data?.data.records;

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (record) {
      setAllRecords(record);
    }
  }, [data]);

  const handleClick = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(record.id);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //generate lessonNote Filter
  const handleFilter = () => {
    if (!allRecords.length) return;
  
    const filtered = allRecords.filter((record) => {
      const recordYear = record?.session;
      const recordWeek = record?.week;
      const recordTerm = record?.term;
  
      return (
        (school ? recordYear === school : true) &&
        (week ? recordWeek === week : true) &&
        (term ? recordTerm === term : true) 
      );
    });
  
    setFilteredRecords(filtered);
  };

  //search 
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  //edit LessonNote
  const handleEditLessonNote = (id) => {
    setSelectedItem(id);
    setIsDrawerOpen(true);
    handleClose();
  };

  //Delete LessonNote
  const handleDeleteLessonNote = (id) => {
    setSelectedItem(id);
    setIsDeleteOpen(true);
    handleClose();
  };

  //upload File
  const handleUploadFile = (id) => {
    setSelectedItem(id);
    setIsFileOpen(true);
    handleClose();
  };

  //preview File
  const handlePreviewFile = (id) => {
    setSelectedItem(id);
    setIsPreviewOpen(true);
    handleClose();
  };

  //Submit File
  const handleSubmitNote = (id) => {
    setSelectedItem(id);
    setIsSubmitOpen(true);
    handleClose();
  };

  //Rejected FIle
  const handleRejectedLessonNote = (id) =>{
    setSelectedItem(id);
    setIsRejected(true);
    handleClose();
  }

   // Calculate the total pages dynamically
const totalPages = data?.data?.totalRecordCount
? Math.ceil(data?.data?.totalRecordCount / pageSize)
: 0;

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          bgcolor: "#691e78",
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          height: "fit",
          paddingBottom: "20px",
        }}
      >
        <Grid item sm={3} xs={5}>
          <Box>
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              School Year
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                value={school}
                onChange={(e) => {
                  e.preventDefault();
                  setSchool(e.target.value)
                }}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="" disabled
                >
                 <em>Choose year</em>
                </MenuItem>
                {sessionsLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {(!sessions?.data || sessions?.data?.length === 0) && !sessionsLoading ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (sessions?.data?.length > 0 &&
                    sessions?.data.map((session) => (
                      <MenuItem
                        key={session.id}
                        value={session.name}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {session.name}
                      </MenuItem>
                    ))
                  )}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={3} xs={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Term
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                value={term}
                onChange={(e) => {
                  e.preventDefault();
                  setTerm(e.target.value)
                }}
              >
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value=""
                  disabled
                >
                 <em>Choose term</em>
                </MenuItem>
                {termLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {!termLoading && !lessonTerms?.data ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Term Available
                      </Typography>
                    </Box>
                  ) : (lessonTerms?.data?.length > 0 &&
                    lessonTerms?.data?.map((terms) => (
                      <MenuItem
                        key={terms.id}
                        value={terms.value}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {terms.name}
                      </MenuItem>
                    ))
                  )}
                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={3} xs={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Week
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                value={week}
                onChange={(e) => {
                  e.preventDefault();
                  setWeek(e.target.value)
                }}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value=""
                  disabled
                >
                 <em>Choose week</em>
                </MenuItem>
                {weekLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {!weekLoading && !lessonWeeks?.data ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (lessonWeeks?.data?.length > 0 &&
                    lessonWeeks?.data?.map((weeks) => (
                      <MenuItem
                        key={weeks.id}
                        value={weeks.name}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {weeks.name}
                      </MenuItem>
                    ))
                  )}
                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={2} xs={5}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                bgcolor: "#fff",
                color: "#691e78",
                "&:hover": { color: "#fff" },
              }}
              onClick={handleFilter}
            >
              <RefreshIcon />
              Generate
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* create lesson Note */}
      <Box mt={5} align="right">
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{ textTransform: "initial", fontFamily: "gilroy-regular" }}
        >
          Create New Lesson Note Setup
        </Button>
      </Box>
      <Box
        mt={2}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{ backgroundColor: "rgba(103, 30, 120, 0.1)", width: "100%" }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>School Year</StyledTableCell>
                <StyledTableCell>Term</StyledTableCell>
                <StyledTableCell>Week</StyledTableCell>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created Date</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {isLoading ? (
                <StyledTableRow>
                <StyledTableCell colSpan={9} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                  <TableLoader />
                </StyledTableCell>
              </StyledTableRow>
              ) :
              ((filteredRecords ?? allRecords)?.length > 0) && (
                (filteredRecords ?? allRecords).map((record, index) => (
                  <StyledTableRow
                    style={
                      index % 2
                        ? { background: "rgba(103, 30, 120, 0.1)" }
                        : { background: "white" }
                    }
                   key={record.id || index}
                   >
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{record?.session}</StyledTableCell>
                    <StyledTableCell>{record?.term}</StyledTableCell>
                    <StyledTableCell>{record?.week}</StyledTableCell>
                    <StyledTableCell>{record?.subject}</StyledTableCell>
                    <StyledTableCell>{record?.className}</StyledTableCell>
                    <StyledTableCell>
                      {record?.status === "Endorsed" && (
                        <Box sx={{ color: "#67a116", bgcolor: "#67a1161a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Pending" && (
                        <Box sx={{ color: "#ffc710", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Rejected" && (
                        <Box sx={{ color: "#FF0000", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Submitted" && (
                        <Box sx={{ color: "#008000", bgcolor: "#D9ECD9", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Declined" && (
                        <Box sx={{ color: "#b22929", bgcolor: "#b229291a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{record.createdBy}</StyledTableCell>
                    <StyledTableCell>{record?.dateCreated ? FormateDate(record.dateCreated) : "N/A"}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleClick(e, record)}>
                        <MoreVertIcon />
                      </IconButton>
                      
                      <Popover
                        id={id}
                        open={open && selectedItem === record.id}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: "#671E78",
                            color: "#fff",
                            fontFamily: "Gilroy-Regular",
                            px: 3,
                          }}
                        >
                          <MenuList sx={{ fontFamily: "Gilroy-Regular" }} >
                            <MenuItem
                              disabled={record?.status === "Submitted" || record?.status === "Endorsed"}
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleEditLessonNote(record.id)}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem 
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleUploadFile(record.id)}
                            >
                              Attach File
                            </MenuItem>
                            <MenuItem 
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handlePreviewFile(record)}
                            >
                              Preview Attach File
                            </MenuItem>
                            <MenuItem
                              disabled={record?.status === "Submitted" || record?.status === "Endorsed"} 
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleSubmitNote(record.id)}
                            >
                              Submit
                            </MenuItem>
                            <MenuItem 
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleDeleteLessonNote(record.id)}
                            >
                              Delete
                            </MenuItem>
                              <MenuItem 
                                sx={{ fontFamily: "Gilroy-Regular" }} 
                                onClick={() => handleRejectedLessonNote(record)}
                              >
                                Reason
                              </MenuItem>
                            
                          </MenuList>
                        </Box>
                      </Popover>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
              <>
              {(!isLoading && (filteredRecords ?? allRecords)?.length === 0) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={9} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                    No records available.
                  </StyledTableCell>
                </StyledTableRow>

              )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 3 }}>
        <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateNoteModal onClose={handleCloseModal} />
      </Modal>
      <EditLessonNoteModal
        item={selectedItem}
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      />
      <DeleteModal
        id={selectedItem}
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
      />
      <AttachFileModal
        id={selectedItem}
        isOpen={isFileOpen}
        onClose={() => setIsFileOpen(false)}
      />
      <PreviewLessonNoteModal
        record={selectedItem}
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
      />
      <SubmitModal
        id={selectedItem}
        isOpen={isSubmitOpen}
        onClose={() => setIsSubmitOpen(false)}
      />
      <RejectedModal
        record={selectedItem}
        isOpen={isRejected}
        onClose={() => setIsRejected(false)}
      />
    </Box>
  );
};

export default CreateLesson;
