import React, { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FormLoader from "../FormLoader";
import { toast } from "react-toastify";
import TableLoader from "../TableLoader";
import { useEndorseReportMutation, useRejectReportMutation } from "../../api/reportAPI";

const PreviewReportPDF = ({ isOpen, onClose, record }) => {
    const [isRejecting, setIsRejecting] = useState(false); 
    const [comments, setComments] = useState("");
    const [formLoading, setFormLoading] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    const [endorseReject] = useEndorseReportMutation();
    const [rejectReject] = useRejectReportMutation();

    const handleClose = () => {
        onClose()
        setIsRejecting(false)
    }

    //Reject Report
    const handleReject = async () => {
        if (!isRejecting) {
            setIsRejecting(true);
            return;
        }

        if (record && record?.id) {
            setFormLoading(true);
            setDisableButtons(true);

            try {
                const response = await rejectReject({
                    id: record?.id,
                    comment: comments.trim(),
                }).unwrap();

                console.log(response)
                if (response.statusCode === 2) {
                    toast.success(response.message);
                    onClose();
                } else if (response.statusCode === 5){
                    setErrorMsg(response.message)
                    toast.error(response.message)
                } else {
                    setErrorMsg(response.message)
                    toast.error(response.message);
                }
            } catch (error) {
                console.error("Error rejecting lesson note:", error);
            } finally {
                setFormLoading(false);
                setDisableButtons(false);
            }
        }
    };


    //Endorse Report
    const handleEndorse = async () => {
        if (record && record?.id) {
            setFormLoading(true);
            setDisableButtons(true);

            try {
                const response = await endorseReject({ id: record?.id }).unwrap();
                console.log(response)

                if (response.statusCode === 2) {
                    toast.success(response.message);
                    onClose();
                } else {
                    setErrorMsg(response.message)
                    toast.error(response.message);
                }
            } catch (error) {
                console.error("Error endorsing lesson note:", error);
            } finally {
                setFormLoading(false);
                setDisableButtons(false);
            }
        }
    };

    if (!isOpen) return null;
    if (!record) return null;

    return (
        <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
            <div className="w-full mx-6 md:mx-0 max-w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4 overflow-y-auto">
                <div className="flex justify-end">
                    <IconButton onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </div>
                {errorMsg !=="" &&
                    <p className="text-red-500 text-xs text-center">{errorMsg}</p>
                }
                <h1 className="text-center text-xl lg:text-2xl font-semibold mb-2">Endorse or Reject Attachment</h1>

                <div>
                    {pdfLoading && <TableLoader />}
                    <iframe
                        title="Preview Report"
                        src={record?.path} 
                        width="100%" 
                        height="450px"
                        onLoad={() => setPdfLoading(false)}
                        style={{ display: pdfLoading ? "none" : "block"}}
                    />
                </div>

                {isRejecting && (
                    <TextField 
                        fullWidth 
                        label="Reason for Rejection" 
                        value={comments} 
                        onChange={(e) => setComments(e.target.value)}
                        sx={{ marginTop: 2 }}
                    />
                )}

                <div className="flex items-center justify-center gap-x-6 mt-4">
                    <button 
                        className="text-white border border-red-500 bg-red-500 px-6 py-1 text-center rounded-md w-full"
                        type="submit"
                        disabled={disableButtons || (isRejecting && comments.trim() === "")}
                        onClick={handleReject}
                    >
                        {formLoading && isRejecting ? <FormLoader /> : isRejecting ? "Submit Rejection" : "Reject"}
                    </button>

                    <button
                        className="text-white border border-green-600 bg-green-600 px-6 py-1 text-center rounded-md hover:bg-green-400 w-full"  
                        disabled={disableButtons}
                        onClick={handleEndorse}
                    >
                        {formLoading ? <FormLoader /> : "Endorse"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PreviewReportPDF;
