/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;

export default {
  postSchoolStatus: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/MySchool/Change-SchoolStatus?activate=${data.activate}&schoolId=${data.schoolId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
};

export const viewStudentDetails = (token, schoolId, studentId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/MySchool/View-Student-Details?schoolId=${schoolId}&phoneNumber=${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          authToken: token,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const viewStaffDetails = (token, schoolId, staffId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/MySchool/ViewStaff-Details?schoolId=${schoolId}&staffId=${staffId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const userCreatedSchools = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/MySchool/User-Created-Schools`, {
      headers: {
        authToken: `${token}`,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
export const createNewSchool = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/MySchool/Create-New-School`,
      data,
      {
        headers: {
          authToken: `${token}`,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const postSchoolColor = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/MySchool/Save-School-Color?colorCode=${data.colorCode}&schoolId=${data.schoolId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const getSchoolWebsiteInfo = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/MySchool/WebsiteInfo?schoolId=${id}`,
      {
        headers: {
          authToken: `${token}`,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getAllSubjects = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/GetAllSubjects?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const createSubject = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/CreateSubject`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const updateSubject = (token, data) =>
  axios
    .patch(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/UpdateSubject`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const deleteSubject = (token, id) =>
  axios
    .delete(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/DeleteSubject?id=${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const deleteClass = (token, id) =>
  axios
    .delete(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/DeleteClass?id=${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const GetAssignSubjectTeacher = (token, schoolId, subjectId) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/GetAssign-Subject-Teachers?schoolId=${schoolId}&subjectId=${subjectId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const GetAllDepartments = (token, schoolId) =>
  axios
  .get(
    `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AllDepartments?schoolId=${schoolId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  .then((res) => res.data)
  .catch((err) => err.response);

export const AssignSubjectTeacher = (token, staffId, subjectId) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/Assign-SubjectToteacher?staffID=${staffId}&subjectId=${subjectId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const GetSubjectTeachers = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectsTeachers?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const GetSubjectTeacherClasses = (token, staffId, subjectId, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacherClasses?staffid=${staffId}&subjectId=${subjectId}&schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const AssignSubjectClasses = (token, classId, subjectId, staffId) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/Assign-Claass-To-SubjectTeachers?classid=${classId}&staffID=${staffId}&subjectId=${subjectId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSchools = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/MySchool/Schools`, {
      headers: {
        authToken: `${token}`,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
export const deleteSchoolDetails = (id, token, schoolID, type) =>
  axios
    .delete(
      `${endpoint_two}/api/v${api_version}/MySchool/${type}?Id=${id}&schoolId=${schoolID}`,
      // data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const postSchoolDetails = (token, data, type) =>
  axios
    .post(`${endpoint_two}/api/v${api_version}/MySchool/${type}`, data, {
      headers: {
        authToken: `${token}`,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const classStudentInfo = (token, classId, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/MySchool/Class-Student-Info?classId=${classId}&schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSchoolList = (token, type, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/MySchool/${type}?schoolId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSchoolStampAndSignature = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/MySchool/get-SchoolStampAndSignature?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const postSchoolStampAndSign = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/MySchool/Update-SchoolStampAndSignature`,
      data,
      {
        headers: {
          authToken: `${token}`,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
