/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const endpoint_one = process.env.REACT_APP_API_ENDPOINT_ONE;
const api_version = process.env.REACT_APP_API_VERSION;

export default {
  getAdminWebInfo: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/Get-lasmab-websiteinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getWebInfoDetails: (token, type) =>
    axios
      .get(`${endpoint_one}/api/v${api_version}/Website/${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  getRightsStaff: (token, staffId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/Rights/${staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getMenuItemsStaff: (token, staffId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/MenuItems/${staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getDashboardItemsStaff: (token, staffId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/DashboardItems/${staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getMenuItems: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/MenuItems`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getDashboardItems: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/DashboardItems`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getPriviledges: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/Priviledges`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getRights: (token) =>
    axios
      .get(`${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/Rights`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  postUpdateRole: (token, data) => {
    // let data = {
    //   roleName,
    //   roleDescription,
    //   departmentId,
    //   categoryId,
    // };
    console.log(data, token);
    return axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelUserSetup/UpdateRole`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response);
  },

  postCreateRole: (token, data) => {
    // let data = {
    //   roleName,
    //   roleDescription,
    //   departmentId,
    //   categoryId,
    // };
    console.log(data, token);
    return axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelUserSetup/CreateRole`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  deleteControlPanelLasmabSetup: (id, token, type) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/${type}?id=${id}`,
        // data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  activateControlPanelLasmabSetup: (id, bool, token, type) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/${type}?NewsId=${id}&id=${id}&isActivated=${bool}&isActivate=${bool}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  postControlPanelLasmabSetup: (data, token, type) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/${type}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // Staff Account Setup
  postActivateStaffAccount: (token, id, bool) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Activate-Staff-Account?staffId=${id}&activate=${bool}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getStaffList: (token, id) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Staff-List?schoolId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getStaffWithRoleSetUp: (token, id) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Get-Staffs-WithRoleSetUp?schoolId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getStaffContactList: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Staff-Contact-List/${schoolId}`
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // done
  getSchoolDepartments: (token, schoolId, categoryId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/School-Departments/${categoryId}?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  // Get Staff Categories One 1
  getStaffAccountCategories: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Staff-Account-Categories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // Get All Staff Roles Categorized By Staff Categories Two Don't To Add DepartmentId
  getRoles: (token, categoryId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/Roles?CategoryId=${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // Roles Setup Three
  addRoleSetups: (data, token) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelUserSetup/Add-RoleSetups`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // Get Setups For Staff Roles
  getUserRolesSetUps: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPaneluserSetup/UserRoleSetUps`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  postSetupStaffAccount: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/SetUp-StaffAccount`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getStaffRoleSetups: (token, id) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/StaffRoleSetups?phoneNumber=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  updateStaffRoleSetups: (data, token) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/UpdateStaffRoleSetUps`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  deleteStaffRole: (token, staffId) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Delete-StaffRole?staffId=${staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  contactList: (token, id) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Staff-Contact-List/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getUsersCredential: (token, schoolId, accountType) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Users-Credential?schoolId=${schoolId}&accountType=${accountType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getUserCredential: (token, schoolId, accountType, phone) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetUserCredential?schoolId=${schoolId}&accountType=${accountType}&phoneNumber=${phone}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  searchUserCredential: (token, schoolId, accountType, searchQuery) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/SearchUser/${accountType}?schoolId=${schoolId}&Searchitem=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  activateStudent: (token, schoolId, phone, bool) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Activate-Deactivate-Student-Account?schoolId=${schoolId}&PhoneNumber=${phone}&activate=${bool}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  activateParent: (token, phone, bool) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Activate-Deactivate-Parent-Account?PhoneNumber=${phone}&activate=${bool}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  editCredentials: (token, data, accountType) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/UpdateUserCredential/${accountType}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  createStaff: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/CreateStaff`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  createParent: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/CreateParentProfile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getSelectedSchool: (token, phoneNumber, priviledgeId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetSelectedSchools?phoneNumber=${phoneNumber}&activ=${priviledgeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getUnSelectedSchool: (token, phoneNumber) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetUnSelectedSchools?phoneNumber=${phoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  addDeleteSchoolStaff: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AddDeleteselectedschools`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSelectedDepartment: (token, phoneNumber, priviledgeId, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AllSelectedDepartments?schoolId=${schoolId}&phoneNumber=${phoneNumber}&activ=${priviledgeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getUnSelectedepartment: (token, phoneNumber, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AllUnSelectedDepartments?phoneNumber=${phoneNumber}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  addDeleteDepartmentStaff: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AddDeleteselectedDepartments`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getUserGuides: (token, accountType) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetUserGuide?accounttype=${accountType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getUserGuideLink: (token, accountType, qid) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetUserguideLink?accounttype=${accountType}&qid=${qid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getResultSummaries: (token, schoolId, sessionId, classId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/ResultSummaries?schoolId=${schoolId}&sessionId=${sessionId}&classId=${classId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

      DownloadResult: (token, currentTermId, schoolId, sessionId, classId) =>
        axios
          .get(
            `https://lasmabadmindev.azurewebsites.net/api/v1/Results/DownloadResult?currentTermId=${currentTermId}&schoolId=${schoolId}&sessionId=${sessionId}&classId=${classId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'blob', // Handle binary response (PDF)
            }
          )
          .then((res) => res)
          .catch((err) => err.response),
    
    

  publishResult: (token, id, bool) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/Set-RSummary/${id}?setPublish=${bool}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  LockResult: (token, id, bool) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelLasmabSetup/Set-LockSummary/${id}?setLock=${bool}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  GenerateResult: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Results/GenerateStudentResult`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  ReverseResult: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Results/Reverse-Result`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  assignAdmission: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/Assign-AdmissionNumber`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  removeAdmission: (token, schoolId, studentId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/RemoveAdmissionNumber?schoolId=${schoolId}&studentId=${studentId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  dashboardCount: (token, phoneNumber, departId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetStaffDepartmentDashboardItems?phoneNumber=${phoneNumber}&departmentID=${departId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  assignDefaultSchoolToStaff: (token, schoolId, staffId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AssignDefaultSchooltoStaff?schoolID=${schoolId}&staffID=${staffId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  assignDefaultClassToStaff: (token, schoolId, staffId, classId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AssignDefaultClass-toClassTeacher?schoolID=${schoolId}&staffID=${staffId}&classId=${classId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  assignAdmissionStudentList: (token, schoolId, classId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AssignAdmissionNumber/StudentList?schoolId=${schoolId}&classId=${classId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  hostelAccomodationStudentsList: (token, schoolId, classId, sessionId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/Hostel/GetStudent-ForHostelAllocation?schoolId=${schoolId}&classId=${classId}&sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getAllHostels: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/Hostel/GetHostels?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  clearCredential: (token, accountType, userId) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/ClearUserCredentials/${accountType}?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getTopicsQuestions: (token, type) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/UserGuide/${type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  addTopicQuestion: (token, data, type) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/UserGuide/${type}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  createResultSummary: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/CreateResultSummary`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSessionTermPeriod: (token, schoolId, sessionId, termId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Get-SessionTermPeriod?schoolId=${schoolId}&sessionId=${sessionId}&termId=${termId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  addUpdateTermPeriod: (token, data, type) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/${type}-SessionTermPeriod`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  deleteTopicStepQuestion: (token, type, id) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/UserGuide/${type}?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getAttendanceSetupDates: (token, schoolId, sessionId, termId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AttendanceSetup/getDates?schoolId=${schoolId}&sessionId=${sessionId}&termId=${termId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  addAttendanceSetupDate: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AttendanceSetup/Add`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  lockAttendanceSetupDate: (token, attendanceId, setLock) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AttendanceSetup/Lock-UnLock?attendanceId=${attendanceId}&setLock=${setLock}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  deleteAttendanceSetupDate: (token, id) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AttendanceSetup/Delete?attendanceId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getHostelsForManagers: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AssignHostelmanager/getHostels?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  assignHostelManager: (token, schoolId, staffId, hostelId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/Assign-hostelManager?schoolId=${schoolId}&staffId=${staffId}&hostelId=${hostelId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  unAssignHostelManager: (token, schoolId, hostelId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/UnAssign-hostelManager?schoolId=${schoolId}&hostelId=${hostelId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSchoolsForPrincipalOrVice: (token, vp) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/Assign${
          vp ? "-Vice" : ""
        }Prinpal/getSchools`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  unAssignPrincipalOrVice: (token, staffId, vp) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/UnAssign-${
          vp ? "Vice" : ""
        }Principal?staffId=${staffId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  assignPrincipal: (token, schoolId, staffId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/Assign-Principal?schoolId=${schoolId}&staffId=${staffId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  assignVicePrincipal: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/Assign-VicePrincipal`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getSchoolDepartmentsForHod: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AssignHod/getdepartments?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  unAssignHod: (token, schoolId, staffId, departmentId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/UnAssign-Hod?schoolId=${schoolId}&staffid=${staffId}&departmentId=${departmentId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  
  assignHod: (token, schoold, staffId, departmentId) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/Assign-Hod?schoolId=${schoold}&staffId=${staffId}&departmentId=${departmentId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  createDepartment: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/Createdepartment`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

      editDepartment: (token, { departmentName, Id }) =>
        axios
        .put(
            `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/UpdatedepartmentName?Id=${Id}&departmentName=${encodeURIComponent(departmentName)}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res) => res.data)
        .catch((err) => err.response),

    deleteDepartment: (token, id) =>
      axios
        .delete(
          `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/deletedepartment?id=${id}`,
    
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .catch((err) => err.response),

  getYearTutors: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/GetYearTutors?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
      
  assignYearTutor: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetup/AddYearTutor`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSingleResultStudents: (token, schoolId, classId, termId, sessionId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/Results/GenerateSingleResult/studentList?schoolId=${schoolId}&classId=${classId}&termId=${termId}&sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  GenerateSingleResult: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Results/GenerateStudentResult-Single`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  ReverseSingleResult: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Results/Reverse-Result-Single`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getAuditReport: (
    token,
    {
      pageNumber = 1,
      pageSize = 10,
      email = "",
      name = "",
      phoneNumber = "",
      from = "",
      to = "",
      operation = "",
    }
  ) => {
    if (!email && !name && !phoneNumber) {
      throw new Error(
        "At least one parameter (email, name, or phoneNumber) is required"
      );
    }

    if (from && !to) {
      throw new Error("'to' date is required when 'from' date is provided");
    }

    if (to && !from) {
      throw new Error("'from' date is required when 'to' date is provided");
    }
    const params = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      ...(email && { Email: email }),
      ...(name && { Name: name }),
      ...(phoneNumber && { PhoneNumber: phoneNumber }),
      ...(operation && { OperationId: operation }),
      ...(from && { From: from }),
      ...(to && { To: to }),
    };
    const queryString = new URLSearchParams(params).toString();
    return axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/AuditReport/Search?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data.errors
            ? Object.values(error.response.data.errors).flat().join(", ")
            : error.response.data.title || error.response.data.message
          : error.message;
        throw new Error(errorMessage);
      });
  },
};
