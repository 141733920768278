import React, { useCallback, useEffect, useState } from "react";
import colors from "../../../assets/colors.json";
import { toast } from "react-toastify";
import { GiCancel } from "react-icons/gi";

import {
  TabContainer,
  AccountSummaryWrapper,
  BigHeader,
  Search,
  Icon,
  MenuDiv,
  Input,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";

import { BsSearch } from "react-icons/bs";
import api from "../../../services/ControlPanel";
import Pagination from "../../../components/pagination_one/pagination";
import { checkAccess, paginateData } from "../../../services/utils";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import { useSelector } from "react-redux";
import {
  AssignSubjectTeacher,
  createSubject,
  updateSubject,
  deleteSubject,
  getAllSubjects,
  GetAssignSubjectTeacher,
  GetAllDepartments,
} from "../../../services/MySchool";
import FormLoader from "../../../components/FormLoader";
import ErrorService from "../../../services/errorService";
import Loader from "../../../components/pageLoader/loader";
import Modal from "../../modal/Modal";
import Select from "../../select/select";

const CreateSubject = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [showModal, setShowModal] = useState(false);
  const [staffQuery, setStaffQuery] = useState("");
  const [subjectObj, setSubjectObj] = useState({
    id: "",
    name: "",
  });

  const cates = [
    { id: 1, val: "E", name: "Elective" },
    { id: 2, val: "C", name: "Compulsory" },
  ];
  const sections = [
    { id: 1, val: "J", name: "Junior" },
    { id: 2, val: "S", name: "Senior" },
    { id: 3, val: "G", name: "General" },
    { id: 4, val: "K", name: "Kindergateen" },
    { id: 5, val: "N", name: "Nursery" },
    { id: 6, val: "P", name: "Primary" },
  ];

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [listData, setListData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [department, setDepartment] = useState([]);
  const [tLoading, setTLoading] = useState([]);

  const getSubjectTeachers = useCallback(
    async (id) => {
      try {
        setTLoading(true);
        const res = await GetAssignSubjectTeacher(admToken, user.schoolId, id);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setTeachers(res.data);
          setShowAssignModal(true);
        }
        setTLoading(false);
      } catch (error) {
        setTLoading(false);
        console.log(error);
      }
    },
    [admToken, user.schoolId]
  );

  const getAllDepartment = useCallback(
    async () => {
      try {
        setTLoading(true);
        const res = await GetAllDepartments(admToken, user.schoolId);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setDepartment(res.data);
        } else if (res.status === 500 || res.status === 400) {
          ErrorService.displayErrorAlert(res.data);
        }
        setTLoading(false);
      } catch (error) {
        setTLoading(false);
        console.log(error);
      }
    },
    [admToken, user]
  );
  

  const filterStaff = useCallback(
    async (e) => {
      const query = e.target.value.toLowerCase();
      const filteredData = data.filter((item) =>
        Object.values(item).some(
          (val) => typeof val === "string" && val.toLowerCase().includes(query)
        )
      );
      setListData(filteredData);
    },
    [data]
  );

  const [id, setId] = useState("");

  const getAllStaff = useCallback(async () => {
    try {
      const res = await api.getUsersCredential(admToken, user.schoolId, 3);
      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
        // setShowAssignModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  const [loadSub, setLoadSub] = useState(true);
  const [sQuery, setSQuery] = useState("");

  const filterSearch = async (e) => {

    const queryRegex = new RegExp(e.target.value, "i");
    const arr = allData.filter((item) => {
      return Object.values(item).some((val) => queryRegex.test(val));
    });
    console.log(arr);
    setList(arr);
    setPageCount(0);
  };

  const getSubjects = useCallback(async () => {
    try {
      const res = await getAllSubjects(admToken, user.schoolId);
      console.log(res);
      res.status && res.statusCode === 2 && setAllData(res.data);
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      setLoadSub(false);
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  // getALl subject
  useEffect(() => {
    getSubjects();
    getAllStaff();
    getAllDepartment()
  }, [getAllStaff, getSubjects, getAllDepartment]);

  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  };

  useEffect(() => {
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage]);

  let _obj = {
    subname: "",
    subshort: "",
    section: "",
    category: "",
    sort_order: 2,
    schoolid: user.schoolId,
    unit: "N",
    grouped: 0,
    main_sub: 0,
    departmentID: 0,
  };
  const [obj, setObj] = useState(_obj);
  console.log("list", list)

  const performAction = async () => {
    try {
      setFormLoading(true);
      const res = obj.subid
        ? await updateSubject(admToken, obj)
        : await createSubject(admToken, obj);
      setFormLoading(false);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        setShowModal(false);
      res.status && res.statusCode === 2 && getSubjects();
      !res.status && toast.error(res.message);
      res.status === 400 && ErrorService.displayErrorAlert(res);
    } catch (error) {
      setFormLoading(false);
    }
  };

  const delAction = async (id) => {
    try {
      setFormLoading(true);
      const res = await deleteSubject(admToken, id);
      setFormLoading(false);

      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        getSubjects();
      !res.status && toast.error(res.message);
      res.status === 400 && ErrorService.displayErrorAlert(res);

      console.log(res);
    } catch (error) {
      setFormLoading(false);
    }
  };
  const [assignLoading, setAssignLoading] = useState(false);
  const [queryId, setQueryId] = useState("");

  const assignFunc = useCallback(
    async (id) => {
      try {
        setAssignLoading(true);
        const res = await AssignSubjectTeacher(admToken, id, subjectObj.id);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });

          getSubjectTeachers(subjectObj.id);
          // setCurrentPage(1);
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        !res.status && ErrorService.displayErrorAlert(res);
        setAssignLoading(false);
      } catch (error) {
        setAssignLoading(false);
      }
    },
    [admToken, getSubjectTeachers, subjectObj.id]
  );

  const isGlobalUser = accessObj?.privileges?.some((obj) => obj.id === 1);
  console.log("obj", obj);

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {" "}
        {obj.subid ? "Update" : "Add"} Subject
      </BigHeader>
      <InputWrapper>
        <label htmlFor="">Subject Name</label>
        <input
          type="text"
          value={obj.subname}
          placeholder="Subject's Name"
          onChange={(e) => setObj({ ...obj, subname: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Subject Short Name</label>
        <input
          type="text"
          value={obj.subshort}
          placeholder="Subject's Short Name"
          onChange={(e) => setObj({ ...obj, subshort: e.target.value.trim() })}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Category</label>
        <Select
          title="Category"
          index="name"
          thirdOption="val"
          options={cates}
          selected={obj.category}
          setSelected={(val) => {
            setObj({ ...obj, category: val.val });
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Department</label>
        <Select
          title="Department"
          index="department_name"
          selected={obj.departmentID}
          thirdOption="id"
          options={department} 
          setSelected={(val) => {
            setAllData([]);
            setObj({ ...obj, departmentID: val.id });
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Section</label>
        <Select
          title="Section"
          index="name"
          thirdOption="val"
          options={sections}
          selected={obj.section}
          setSelected={(val) => {
            setObj({ ...obj, section: val.val });
          }}
        />
      </InputWrapper>
      <div>
        <AuthButton
          onClick={performAction}
          width="100%"
          mt="0.6rem"
          disabled={
            formLoading ||
            obj.subname === "" ||
            obj.subshort === "" ||
            obj.section === "" ||
            obj.section === null ||
            obj.category === "" ||
            obj.category === null
          }
        >
          {formLoading ? (
            <FormLoader />
          ) : obj.subid ? (
            "Update Subject"
          ) : (
            "Create Subject"
          )}
        </AuthButton>
      </div>
    </>
  );
  const assignContent = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="15px">
        Assign Teachers To {subjectObj.name}
      </BigHeader>
      <InputWrapper>
        <input
          type="text"
          placeholder="Search For Staff"
          onChange={(e) => {
            filterStaff(e);
            setStaffQuery(e.target.value);
          }}
          value={staffQuery}
        />
      </InputWrapper>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: isMobile ? "column" : "row",
          padding: "0.2rem 0.3rem",
          justifyContent: "start",
        }}
      >
        {teachers.length > 0 &&
          teachers.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.4rem",
                backgroundColor: colors.primary,
                display: "flex",
                alignItems: "center",
                padding: "0.3rem 0.6rem",
                borderRadius: "10px",
                marginRight: "0.2rem",
                flexWrap: "wrap",
                border: `1px solid ${colors.primary}`,
                cursor: "pointer",
              }}
              onClick={() => {
                setQueryId(item.credentialId);
                assignFunc(item.credentialId);
              }}
            >
              <SmallHeader
                style={{
                  color: "white",
                  marginRight: "0.2rem",
                  fontSize: isTab ? "12px" : "13px",
                }}
              >
                {item.fullName}
              </SmallHeader>

              <GiCancel
                color="red"
                style={{
                  marginLeft: "0.2rem",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
      </div>
      <MenuDiv style={{ padding: isTab ? "0.6rem" : "0.8rem" }}>
        {listData.length > 0 &&
          listData.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{item.fullName}</div>
              <button
                disabled={queryId === item.credentialId && assignLoading}
                style={{
                  backgroundColor: colors.primary,
                  color: "white",
                  borderRadius: "20px",
                  padding: "0.3rem 0.6rem",
                }}
                onClick={() => {
                  setQueryId(item.credentialId);
                  assignFunc(item.credentialId);
                }}
              >
                {queryId === item.credentialId && assignLoading
                  ? "Please wait..."
                  : checkAccess(teachers, item.credentialId)
                  ? "Unassign"
                  : "Assign"}
              </button>
            </div>
          ))}
      </MenuDiv>
    </>
  );
  if (loadSub) {
    return <Loader />;
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {checkAccess(accessObj.rights, "Create") && (
          <AuthButton
            disabled={!isGlobalUser}
            onClick={() => {
              setObj(Object.assign({}, _obj));
              setShowModal(!showModal);
            }}
          >
            Add Subject
          </AuthButton>
        )}
      </div>
      {showAssignModal && (
        <Modal
          show={showAssignModal}
          content={assignContent}
          size={"large"}
          onClose={setShowAssignModal}
        />
      )}

      {showModal && (
        <Modal
          content={content}
          show={showModal}
          size={"large"}
          onClose={setShowModal}
        />
      )}

      <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table>
            <tr
              className="table-head"
              style={{ backgroundColor: colors.primary, color: "white" }}
            >
              <th></th>
              <th>Subject Name ({allData.length > 0 && allData.length}) </th>
              <th>Category</th>
              <th>Section</th>
              <th>Department</th>
              <th>
                <Input style={{ width: "100%" }}>
                  <Icon>
                    <BsSearch color={colors.primary} />
                  </Icon>
                  <Search
                    style={{ background: "white" }}
                    value={sQuery}
                    type="text"
                    placeholder="Search For Subject"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setSQuery(e.target.value);
                      if (e.target.value.length > 3) {
                        console.log("inside");
                        filterSearch(e);
                      }

                      if (e.target.value === "") {
                        resetData();
                      }
                    }}
                  />
                </Input>
              </th>
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td> {index + 1}.</td>
                  <td>
                    {item.subjectName === null ? "No Name" : item.subjectName}
                  </td>
                  <td>
                    {item.category === "C"
                      ? "Compulsory"
                      : item.category === "E"
                      ? "Elective"
                      : ""}
                  </td>
                  <td>
                    {item.section === "J"
                      ? "Junior"
                      : item.section === "S"
                      ? "Senior"
                      : item.section === "G"
                      ? "General"
                      : item.section === "K"
                      ? "Kindergateen"
                      : item.section === "N"
                      ? "Nursery"
                      : item.section === "P"
                      ? "Primary"
                      : ""}
                  </td>
                  <td>{item?.department ? item?.department : "NA"}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {checkAccess(accessObj.rights, "Assign") && (
                        <button
                          onClick={() => {
                            setQueryId(item.subjectId);
                            setSubjectObj({
                              ...subjectObj,
                              id: item.subjectId,
                              name: item.subjectName,
                            });
                            getSubjectTeachers(item.subjectId);
                            // setShowAssignModal(true);
                          }}
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                            width: "135px",
                          }}
                          disabled={item.subjectId === queryId && tLoading}
                        >
                          {item.subjectId === queryId && tLoading
                            ? "Please wait..."
                            : "Assign Teacher"}
                        </button>
                      )}
                      {checkAccess(accessObj.rights, "Edit") && (
                        <button
                          disabled={!isGlobalUser}
                          onClick={() => {
                            setObj({
                              ...obj,
                              subid: item.subjectId,
                              category: item.category,
                              section: item.section,
                              // schoolid: item.schoolId,
                              schoolid: user.schoolId,
                              subname: item.subjectName,
                              subshort: item.subjectShort,
                              // unit: item.unit,
                            });

                            setShowModal(true);
                          }}
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                          }}
                        >
                          Edit
                        </button>
                      )}

                      {checkAccess(accessObj.rights, "Delete") && (
                        <button
                          disabled={!isGlobalUser}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                          }}
                          onClick={() => {
                            setId(item.subjectId);
                            delAction(item.subjectId);
                          }}
                        >
                          {formLoading && id === item.subjectId ? (
                            <FormLoader />
                          ) : (
                            " Delete"
                          )}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  There Are No Subjects Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>

      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default CreateSubject;
