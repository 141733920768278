import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../assets/colors.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { useGetEachReportQuery, useUploadFileMutation } from "../../api/reportAPI";

const AttachFile = ({ isOpen, onClose, id }) => {
  const [formLoading, setFormLoading] = useState(false);
    const { data, error, isLoading, refetch } = useGetEachReportQuery(id); 
  const [uploadAttachment] = useUploadFileMutation();

  const validationSchema = Yup.object().shape({
    File: Yup.mixed().required("File is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ReportId: id,
      File: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setFormLoading(true);

      try {
        const formData = new FormData();
        formData.append("ReportId", values.ReportId);
        formData.append("File", values.File);

        const response = await uploadAttachment(formData).unwrap();

        if (response?.status === true && response?.statusCode === 2) {
          setFormLoading(false);
          toast.success(response?.message);
          onClose();
          refetch();
        } else {
          setFormLoading(false);
          toast.error(response?.message || "Internal Server Error");
        }
      } catch (error) {
        setFormLoading(false);
        console.error("Error uploading file:", error);
        toast.error("Error uploading file");
      }
    },
  });

 // Handle file selection
 const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    formik.setFieldValue("File", file);
  }
};

  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
        <div className="w-full mx-6 md:mx-0 max-w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4">
        <div className="flex justify-end ">
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </div>
        <h1 className="text-center text-2xl lg:text-3xl font-semibold">
          Attach a File
        </h1>
        <p className="text-sm text-center text-black/90 mt-4">Only PDF file is allowed to be uploaded</p>
        <form onSubmit={formik.handleSubmit}>
          <Box
            p={8}
            sx={{
              overflowY: "auto",
              height: "auto",
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Upload Note
                    </InputLabel>
                  </Box>
                  <input
                    type="file"
                    id="File"
                    name="File"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    className="font-Gilroy-regular text-[13px] rounded-[5px] border border-[#671E78] cursor-pointer w-full"
                    placeholder="choose a file"
                  />
                  {formik.touched.File && formik.errors.File ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.File}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Box mt={5}>
                <Button
                  disabled={formLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "initial",
                    backgroundColor: colors.primary,
                  }}
                >
                  {formLoading ? <FormLoader /> : <span>Upload File</span>}
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AttachFile;
