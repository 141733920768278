import { IconButton, MenuItem, MenuList } from "@mui/material";
import { useEffect, useState } from "react";
import CreateReportModal from "./CreateReportModal";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, 
  Button, 
  Modal,
  Table,
  TableBody,
  TableContainer,
  Popover,
  InputBase,
  TableHead,
  TableRow,
} from "@mui/material";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { formatDateTime } from "../../utils";
import TableLoader from "../TableLoader";
import { useGetAllReportQuery, useGetEachReportQuery } from "../../api/reportAPI";
import DeleteReport from "./DeleteReport.js";
import EditReport from "./EditReport.js";
import AttachFile from "./AttachFile.js";
import SubmitReportModal from "./SubmitReportModal.js";
import RejectedModal from "../LessonNote/RejectedModal.js";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  border: "0.5px solid #891e78",
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CreateReport = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allRecords, setAllRecords] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isRejected, setIsRejected] = useState(false);

  
  const { data: singleReport } = useGetEachReportQuery(selectedReport)
  const { data, isLoading } = useGetAllReportQuery({
    searchText: searchTerm,
    pageNumber: page,
    pageSize,
  });

  const record = data?.data.records;
  const reportStatus = data?.data?.status;

  const isEditable = reportStatus === "Pending" || reportStatus === "Rejected";
  const isSubmittable = reportStatus !== "Submitted";

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

   useEffect(() => {
      if (record) {
        setAllRecords(record);
      }
    }, [data]);


    const handleClick = (event, record) => {
      setAnchorEl(event.currentTarget);
      setSelectedItem(record.id);
    }
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

   //search 
   const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

   //edit report
   const handleEdit = (id) => {
    setSelectedItem(id);
    setIsDrawerOpen(true);
    handleClose();
  };

  //Delete report
  const handleDelete = (id) => {
    setSelectedItem(id);
    setIsDeleteOpen(true);
    handleClose();
  };

  //upload File
  const handleUploadFile = (id) => {
    setSelectedItem(id);
    setIsFileOpen(true);
    handleClose();
  };

  //Submit File
  const handleSubmit = (id) => {
    setSelectedItem(id);
    setIsSubmitOpen(true);
    handleClose();
  };

  //Rejected FIle
  const handleRejectedLessonNote = (id) =>{
    setSelectedItem(id);
    setIsRejected(true);
    handleClose();
  }

      // Calculate the total pages dynamically
  const totalPages = data?.data?.totalRecordCount
  ? Math.ceil(data?.data?.totalRecordCount / pageSize)
  : 0;

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  return <Box>
    <Box mt={5} align="right">
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{ textTransform: "initial", fontFamily: "gilroy-regular" }}
        >
          Create New Report
        </Button>
    </Box>
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CreateReportModal onClose={handleCloseModal} />
    </Modal>

    {/* Table */}
    <Box
        mt={2}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{ backgroundColor: "rgba(103, 30, 120, 0.1)", width: "100%" }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Session</StyledTableCell>
                <StyledTableCell>Term</StyledTableCell>
                <StyledTableCell>Week</StyledTableCell>
                <StyledTableCell>Report Type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created Date</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {isLoading ? (
                <StyledTableRow>
                <StyledTableCell colSpan={9} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                  <TableLoader />
                </StyledTableCell>
              </StyledTableRow>
              ) :
              (allRecords?.length > 0) && (
                allRecords.map((record, index) => (
                  <StyledTableRow 
                    style={
                      index % 2
                        ? { background: "rgba(103, 30, 120, 0.1)" }
                        : { background: "white" }
                    }
                    key={record.id || index} hover
                  >
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{record?.session}</StyledTableCell>
                    <StyledTableCell>{record?.term}</StyledTableCell>
                    <StyledTableCell>{record?.week}</StyledTableCell>
                    <StyledTableCell>{record?.reportType}</StyledTableCell>
                    <StyledTableCell>
                      {record?.status === "Endorsed" && (
                        <Box sx={{ color: "#67a116", bgcolor: "#67a1161a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Pending" && (
                        <Box sx={{ color: "#ffc710", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Rejected" && (
                        <Box sx={{ color: "#FF0000", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Submitted" && (
                        <Box sx={{ color: "#008000", bgcolor: "#D9ECD9", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Declined" && (
                        <Box sx={{ color: "#b22929", bgcolor: "#b229291a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{record.createdBy}</StyledTableCell>
                    <StyledTableCell>{record?.dateCreated ? formatDateTime(record.dateCreated) : "N/A"}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleClick(e, record)}>
                        <MoreVertIcon />
                      </IconButton>
                      
                      <Popover
                        id={id}
                        open={open && selectedItem === record.id}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: "#671E78",
                            color: "#fff",
                            fontFamily: "Gilroy-Regular",
                            px: 3,
                          }}
                        >
                          <MenuList sx={{ fontFamily: "Gilroy-Regular" }} >
                            <MenuItem
                              disabled={record?.status === "Submitted" || record?.status === "Endorsed"}
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleEdit(record.id)}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem 
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleUploadFile(record.id)}
                            >
                              Attach File
                            </MenuItem>
                            <MenuItem
                              disabled={record?.status === "Submitted" || record?.status === "Endorsed"}
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleSubmit(record.id)}
                            >
                              Submit
                            </MenuItem>
                            <MenuItem 
                              sx={{ fontFamily: "Gilroy-Regular" }} 
                              onClick={() => handleDelete(record.id)}
                            >
                              Delete
                            </MenuItem>
                             {record?.status === "Rejected" && (
                              <MenuItem 
                                sx={{ fontFamily: "Gilroy-Regular" }} 
                                onClick={() => handleRejectedLessonNote(record)}
                              >
                                Reason
                              </MenuItem>
                            )}
                          </MenuList>
                        </Box>
                      </Popover>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
              <>
              {(!isLoading && allRecords?.length === 0) && (
                // Show a message when no records are available
                <StyledTableRow>
                  <StyledTableCell colSpan={9} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                    No records available.
                  </StyledTableCell>
                </StyledTableRow>

              )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 3 }}>
        <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
      </Box>
      <EditReport
          item={selectedItem}
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
        />
        <DeleteReport
          id={selectedItem}
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
        />
        <SubmitReportModal
          id={selectedItem}
          isOpen={isSubmitOpen}
          onClose={() => setIsSubmitOpen(false)}
        />
        <AttachFile
          id={selectedItem}
          isOpen={isFileOpen}
          onClose={() => setIsFileOpen(false)}
        />
        <RejectedModal
        record={selectedItem}
        isOpen={isRejected}
        onClose={() => setIsRejected(false)}
      />
  </Box>;
};

export default CreateReport;
