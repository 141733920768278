import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IoMdCheckmarkCircle } from "react-icons/io";
import React from "react";
import PropTypes from "prop-types";

const Card = ({
  onClick,
  icon,
  smallHeading,
  label,
  value,
  isChecked,
  showCheckIcon,
  height,
  jc,
  ta,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        width: "100%",
        bgcolor: !isChecked ? "white" : "#671E78",
        // height: "143px",
        height: height,
        borderRadius: "20px",
        padding: "5px 10px",
        display: "flex",
        color: !isChecked ? "#671E78" : "#fff",
        justifyContent: jc,
        alignItems: "center",
        cursor: "pointer",
        border: !isChecked ? "1px solid #671E78" : "none",
      }}
    >
      {isChecked && showCheckIcon && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <IoMdCheckmarkCircle style={{ color: "green", fontSize: "20px" }} />
        </div>
      )}

      <Box>
        <Avatar
          sx={{
            width: "50px",
            height: "50px",
            bgcolor: !isChecked ? "#671E78" : "#fff",
            fontSize: "25px",
            color: !isChecked ? "white" : "#671E78",
          }}
        >
          {icon}
        </Avatar>
        <Typography
          variant="subtitle"
          sx={{ fontFamily: "Gilroy-Medium", textAlign: "center" }}
        >
          {smallHeading}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle"
          sx={{ fontFamily: "Gilroy-Bold", textAlign: ta }}
        >
          {label}
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontFamily: "Gilroy-Medium", textAlign: "center" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

Card.propType = {
  isChecked: PropTypes.bool.isRequired,
  showCheckIcon: PropTypes.bool.isRequired,
  height: PropTypes.string,
  jc: PropTypes.string,
  ta: PropTypes.string,
};

Card.defaultProps = {
  isChecked: true,

  ta: "center",
  height: "143px",
  showCheckIcon: false,
  jc: "space-around",
};

export default Card;
