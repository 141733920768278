import { useState, useEffect, useCallback } from "react";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
} from "../Dashboard/styles/dashboard.style";
import axios from "axios";
import Loader from "../../components/pageLoader/loader";
import Select from "../../components/select/select";
import { toast } from "react-toastify";
import { AuthButton, InputWrapper } from "../auth/Login.style";
import { Box } from "@mui/system";
import { BsSearch } from "react-icons/bs";
import FormLoader from "../../components/FormLoader";
import Pagination from "../../components/pagination_one/pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { useMediaQuery } from "react-responsive";
import { createNewSchool, userCreatedSchools } from "../../services/MySchool";
import Modal from "../../components/modal/Modal";
import { BigHeader } from "../Dashboard/styles/dashboard.style";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import moment from "moment";
import { Link } from "react-router-dom";
import { paginateData } from "../../services/utils";
import schoolApi from '../../services/MySchool'

const CreateSchools = () => {





  const { admToken } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [SID, setSID] = useState(null);

  const schTypes = [
    {
      id: 0,
      name: "Primary",
    },
    {
      id: 1,
      name: "Secondary",
    },

  ];

  const getSchoolTypeName = (typeId) => {
    const type = schTypes.find((schType) => schType.id === typeId);
    return type ? type.name : 'Unknown';
  };

  const [schoolObj, setSchoolObj] = useState({
    schoolName: "",
    schoolAbbr: "",
    type: { schTypes }
  });
  const [selectedType, setSelectedType] = useState('');

  const handleTypeChange = (event) => {
    const { value } = event.target;
    setSelectedType(value);
    setSchoolObj((prev) => ({
      ...prev,
      type: value,
    }));
  };

  const [editedSchoolObj, setEditedSchoolObj] = useState({
    schoolName: "",
    schoolAbbr: "",
    type: { schTypes }
  });
  const [selectedEditType, setSelectedEditType] = useState('');

  const handleEditTypeChange = (event) => {
    const { value } = event.target;
    setSelectedEditType(value);
    setEditedSchoolObj((prev) => ({
      ...prev,
      type: value,
    }));
  };


  const [arr, setArr] = useState([]);
  const [allData, setAllData] = useState([]);
  // const handleClick = (event, obj) => {
  //   setAnchorEl(event.currentTarget);
  //   setSID(obj);
  // };
  const handleClick = (event, obj) => {
    setAnchorEl(event.currentTarget);
    setSID(obj);
    setEditedSchoolObj(obj); // Load selected school details
  };

  const handleClose = () => {
    setAnchorEl(null);
  };




  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const { user, isLoading } = useSelector(
    (state) => state.auth
  );




  // const createSchool = async () => {
  //   setFormLoading(true);
  //   try {
  //     console.log(schoolObj);
  //     const res = await createNewSchool(admToken, schoolObj);
  //     res.status &&
  //       res.statusCode === 2 &&
  //       toast.success(res.message, {
  //         autoClose: 4000,
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  //     res.status &&
  //       res.statusCode === 2 &&
  //       setSchoolObj({
  //         schoolName: "",
  //         schoolAbbr: "",
  //       });

  //     (res.status === 500 || res.status === 400) &&
  //       ErrorService.displayErrorAlert(res.data);
  //     console.log(res);
  //     setShowModal(false);
  //     getAllSchools();
  //     setFormLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setFormLoading(false);
  //   }
  // };


  const createSchool = async () => {
    setFormLoading(true);
    try {
      console.log(schoolObj);
      const res = await createNewSchool(admToken, schoolObj, {
        headers: {
          authToken: admToken,
          Authorization: `Bearer ${admToken}`,
        },
      });
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });

        setSchoolObj({
          schoolName: "",
          schoolAbbr: "",
        });

        // Fetch all schools to update the list
        await getAllSchools();

        setShowModal(false);
      } else if (res.status === 500 || res.status === 400) {
        ErrorService.displayErrorAlert(res.data);
      }
      setFormLoading(false);
    } catch (error) {
      console.error("Error creating school:", error);
      ErrorService.displayErrorAlert(error.message);
      setFormLoading(false);
    }
  };


  const getAllSchools = useCallback(async () => {
    try {
      setLoading(true);
      const res = await userCreatedSchools(admToken);

      if (res.status && res.statusCode === 2) {
        setAllData(res.data);
      }
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  }, [admToken]);
  
  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage]);

  useEffect(() => {
    getAllSchools();
  }, [getAllSchools]);



  // To get all schools
  const getSchools = async (token, staffId) => {
    try {
      const endpoint = `${endpoint_two}/api/v${api_version}/MySchool/Schools`;
      const response = await axios.get(endpoint, {
        headers: {
          authToken: token,
          Authorization: `Bearer ${admToken}`,
        },
      });

      if (response.data.status === true && response.data.statusCode === 2) {
        return response.data.data; // Return only the data array
      } else {
        throw new Error('Failed to fetch schools');
      }
    } catch (error) {
      console.error('Error fetching schools:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSchools(admToken, user.staffId);
        setSchools(data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, [admToken, user.staffId]);

  // edit school
  const editSchool = async (schoolId, updatedData) => {
    try {
      const endpoint = `${endpoint_two}/api/v${api_version}/MySchool/update-School`;
      const response = await axios.put(endpoint, updatedData, {
        headers: {
          authToken: admToken,
          Authorization: `Bearer ${admToken}`,
        },
      });

      if (response.data.status === true && response.data.statusCode === 2) {
        const updatedSchools = schools.map((school) =>
          school.schoolId === schoolId ? { ...school, ...updatedData } : school
        );
        setSchools(updatedSchools);
        setShowEditModal(false);
        toast.success(response.data.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        throw new Error("Failed to edit school");
      }
    } catch (error) {
      console.error("Error editing school:", error);
      ErrorService.displayErrorAlert(error.message);
    }
  };


  useEffect(() => {
    if (editedSchoolObj.schoolType !== undefined) {
      const selectedType = schTypes.find((type) => type.id === editedSchoolObj.schoolType);
      setSelectedEditType(selectedType ? selectedType.name : '');
    }
  }, [editedSchoolObj.schoolType]);
  

  const updateSchool = async () => {
    setFormLoading(true);
    try {
      await editSchool(editedSchoolObj.schoolId, editedSchoolObj);
      getAllSchools();
    } catch (error) {
      console.error("Error updating school:", error);
    } finally {
      setShowEditModal(false);
      setFormLoading(false);
    }
  };

  // Delete school 
  const deleteSchool = async (schoolId) => {
    try {
      const endpoint = `${endpoint_two}/api/v${api_version}/MySchool/Remove-School?schoolId=${schoolId}`;
      console.log(`Deleting school with ID: ${schoolId}`); // Debugging: log schoolId

      const response = await axios.delete(endpoint, {
        headers: {
          authToken: admToken,
          Authorization: `Bearer ${admToken}`,
        }
      });

      console.log('Response:', response.data); // Debugging: log response

      if (response.data.status === true) {
        // Assume successful deletion
        const updatedSchools = schools.filter((school) => school.schoolId !== schoolId);
        setSchools(updatedSchools);

        toast.success(response.data.message || 'School deleted successfully', {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if (response.data.status === false && response.data.statusCode === 6) {
        // Specific error case when deletion is not allowed
        toast.error(response.data.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        // General error case
        throw new Error(response.data.message || 'Failed to delete school');
      }
    } catch (error) {
      console.error('Error deleting school:', error);
      toast.error('Error deleting school. Please try again.', {
        autoClose: 4000,
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };



  const handleDeleteClick = (schoolId) => {
    deleteSchool(schoolId);
  };




  const handleEditClick = (event, obj) => {
    setAnchorEl(event.currentTarget);
    setSID(obj);
    setEditedSchoolObj(obj); // Load selected school details
  };


  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Add New School</BigHeader>

      <div>
        <InputWrapper>
          <label htmlFor="School Name">School Name</label>
          <input
            type="text"
            placeholder="Name of school"
            // value={schoolObj.schoolName}
            onChange={(e) =>
              setSchoolObj({
                ...schoolObj,
                schoolName: e.target.value,
              })
            }
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="School Name">School Abbr</label>
          <input
            type="text"
            placeholder="School Abbreviation"
            maxLength={8}
            // value={schoolObj.schoolAbbr}
            onChange={(e) =>
              setSchoolObj({
                ...schoolObj,
                schoolAbbr: e.target.value,
              })
            }
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="Degree">Type</label>
          <Select
            title="Type"
            index="name"
            options={schTypes}
            setSelected={(val) => {
              setSelectedType(val.name);
              setSchoolObj({
                ...schoolObj,
                schoolType: val.id
              })
            }}
            selected={selectedType}
            onChange={handleTypeChange}
          />
        </InputWrapper>
      </div>

      <div>
        <AuthButton
          width="100%"
          mt="0.6rem"
          disabled={
            schoolObj.schoolName === "" ||
            schoolObj.schoolAbbr === "" ||
            formLoading
          }
          onClick={createSchool}
        >
          {formLoading ? <FormLoader /> : "Add New School"}
        </AuthButton>
      </div>
    </>
  );

  const editContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Edit School</BigHeader>
  
      <div>
        <InputWrapper>
          <label htmlFor="School Name">School Name</label>
          <input
            type="text"
            placeholder="Name of school"
            value={editedSchoolObj.schoolName}
            onChange={(e) =>
              setEditedSchoolObj({
                ...editedSchoolObj,
                schoolName: e.target.value,
              })
            }
          />
        </InputWrapper>
  
        <InputWrapper>
          <label htmlFor="School Abbr">School Abbr</label>
          <input
            type="text"
            placeholder="School Abbreviation"
            maxLength={8}
            value={editedSchoolObj.schoolAbbr}
            onChange={(e) =>
              setEditedSchoolObj({
                ...editedSchoolObj,
                schoolAbbr: e.target.value,
              })
            }
          />
        </InputWrapper>
  
        <InputWrapper>
          <label htmlFor="Degree">Type</label>
          <Select
            title="Type"
            index="name"
            options={schTypes}
            setSelected={(val) => {
              setSelectedEditType(val.name);
              setEditedSchoolObj({
                ...editedSchoolObj,
                schoolType: val.id,
              });
            }}
            selected={selectedEditType}
            onChange={handleEditTypeChange}
          />
        </InputWrapper>
      </div>
  
      <div>
        <AuthButton
          width="100%"
          mt="0.6rem"
          disabled={
            editedSchoolObj.schoolAbbr === "" ||
            editedSchoolObj.schoolName === "" ||
            formLoading
          }
          onClick={updateSchool}
        >
          {formLoading ? <FormLoader /> : "Update School"}
        </AuthButton>
      </div>
    </>
  );
  

  if (loading) {
    return <Loader />;
  }
  return (
    <Box>
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}

      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton
          mt="0.6rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowModal(!showModal)}
        >
          <div>
            <span>Create New School</span>
          </div>
          {/* Send Code */}
        </AuthButton>
      </div>

      <TabContainer style={{ marginTop: "0.5rem", paddingBottom: "0px" }}>
        <TabLinkContainer bg="rgb(238, 224, 241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr>
              <th>S/N</th>
              <th>School Name</th>
              <th>School Abbreviation</th>
              <th> Address</th>
              <th>School Type</th>
              <th> Date Created </th>
              <th>Created by</th>
              <th></th>
            </tr>

            {schools.length > 0 ? (
              schools.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.schoolName}</td>
                  <td>{item.schoolAbbr}</td>
                  <td>{item.schoolAddress}</td>
                  <td>{getSchoolTypeName(item.schoolType)}</td>
                  <td>{moment(item.createdDate).format("DD/MM/YYYY")}</td>
                  <td>{item.createdBy}</td>
                  <td>
                    <IconButton onClick={(e) => handleClick(e, item)}>
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} style={{ textAlign: "center" }}>
                  No Schools Created Yet
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>

      {pageCount > 1 && (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                console.log("Hello");
                handleClose();
              }}
            >
              Activate
            </MenuItem>
            <MenuItem>
              <span
                onClick={() => {
                  setShowEditModal(!showEditModal);
                  handleClose();
                }}
              >
                Edit
              </span>
              {/* <Link
          to={{
            pathname: "/myschool/website-info",
            search: `?SID=${SID && SID.schoolId}`,
          }}
        >
          Edit
        </Link> */}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteClick(SID.schoolId);
                handleClose();
              }}
            >
              <span>Delete</span>
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>

      {showEditModal && (
        <Modal content={editContent} size={'large'} onClose={setShowEditModal} />
      )}
    </Box>
  );
};

export default CreateSchools;
