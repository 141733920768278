import React, { useState, useEffect, useCallback } from "react"; 
import {
  TabContainer,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import LinkedUnLinkedStudent from "../../components/StudentMgt/LinkedUnLinkedStudent";
import GraduatedStudent from "../../components/StudentMgt/GraduatedStudent";
import ExitedStudent from "../../components/StudentMgt/ExitedStudent";
import Loader from "../../components/pageLoader/loader";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import { getLinkedUnLinkStudents, getGraduatedStudents, getExitedStudents } from "../../services/ParentStudent";

const StudentList = () => {
  const { admToken, user } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(1);
  const [obj, setObj] = useState({
    linkedStudent: [],
    unLinkedStudent: [],
    graduatedStudent: [],
    exitedStudent: []
  });
  const [loading, setLoading] = useState(false);

  // Fetching Linked, Unlinked, Graduated, and Exited Students
  const getStudentsData = useCallback(async () => {
    try {
      setLoading(true);

      const [linkedRes, graduatedRes, exitedRes] = await Promise.all([
        getLinkedUnLinkStudents(admToken, user.schoolId, user.classId, ""),
        getGraduatedStudents(admToken, user.schoolId, user.classId, ""),
        getExitedStudents(admToken, user.schoolId, user.classId, "")
      ]);

      console.log("Linked/Unlinked Response:", linkedRes);
      console.log("Graduated Response:", graduatedRes);
      console.log("Exited Response:", exitedRes);

      if (linkedRes.status && linkedRes.statusCode === 2) {
        setObj({
          linkedStudent: linkedRes?.data?.linkedStudent || [],
          unLinkedStudent: linkedRes?.data?.unLinkedStudent || [],
          graduatedStudent: graduatedRes?.data || [],
          exitedStudent: exitedRes?.data || []
        });
      }

      if (linkedRes.status === 400) {
        ErrorService.displayErrorAlert(linkedRes.data);
      }

      if (graduatedRes.status === 400) {
        ErrorService.displayErrorAlert(graduatedRes.data);
      }

      if (exitedRes.status === 400) {
        ErrorService.displayErrorAlert(exitedRes.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching student data:", error);
    }
  }, [admToken, user]);

  useEffect(() => {
    getStudentsData();
  }, [getStudentsData]);

  return (
    <>
      {loading && <Loader />}
      {!loading && obj && (
        <TabContainer style={{ paddingBottom: "0px", border: "none" }}>
          <TabLinkContainer style={{ border: "1px solid" }}>
            <TabLink isActive={tab === 1} onClick={() => setTab(1)} minWidth={"25%"}>
              Linked Student ({obj.linkedStudent.length})
            </TabLink>
            <TabLink isActive={tab === 2} onClick={() => setTab(2)} minWidth={"25%"}>
              Unlinked Student ({obj.unLinkedStudent.length})
            </TabLink>
            <TabLink isActive={tab === 3} onClick={() => setTab(3)} minWidth={"25%"}>
              Graduated Student ({obj.graduatedStudent.length})
            </TabLink>
            <TabLink isActive={tab === 4} onClick={() => setTab(4)} minWidth={"25%"}>
              Exited Student ({obj.exitedStudent.length})
            </TabLink>
          </TabLinkContainer>

          {tab === 1 && (
            <LinkedUnLinkedStudent allData={obj.linkedStudent} type="Linked" schoolId={user.schoolId} />
          )}
          {tab === 2 && (
            <LinkedUnLinkedStudent allData={obj.unLinkedStudent} type="Unlinked" schoolId={user.schoolId} action={getStudentsData} />
          )}
          {tab === 3 && (
            <GraduatedStudent allData={obj.graduatedStudent} type="Graduated" schoolId={user.schoolId} />
          )}
          {tab === 4 && (
            <ExitedStudent allData={obj.exitedStudent} type="Exited" schoolId={user.schoolId} />
          )}
        </TabContainer>
      )}
    </>
  );
};

export default StudentList;
