import { API } from "./constant";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const lessonNoteApi = createApi({
  reducerPath: "lessonNote",
  baseQuery,
  tagTypes: ["LessonNotes"],
  endpoints: (builder) => ({
    getAllLessonNotes: builder.query({
      query: ({ searchText = "", pageNumber = 1, pageSize = 10 } = {}) => ({
        url: API.ALL_LESSON_NOTES,
        method: "GET",
        params: {
          pageNumber,
          pageSize,
          ...(searchText ? { searchText } : {}),
        },
      }),
      providesTags: (result) =>
        result && result.status // Check if result is defined and has status
          ? [
              ...result.data.records.map(({ id }) => ({
                type: "LessonNotes",
                id,
              })),
              { type: "LessonNotes", id: "LIST" },
            ]
          : [{ type: "LessonNotes", id: "LIST" }],
    }),
    getLessonSessions: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_SESSIONS,
        method: "GET",
      }),
    }),
    getLessonWeeks: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_WEEKS,
        method: "GET",
      }),
    }),
    getLessonTerms: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_TERMS,
        method: "GET",
      }),
    }),
    getLessonSubject: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_SUBJECTS,
        method: "GET",
      }),
    }),
    getLessonClassess: builder.query({
      query: (id) => ({
        url: `${API.LESSON_NOTES_CLASSES}?subjectId=${id}`,
        method: "GET",
      }),
    }),

    // New Mutation for creating Lesson Notes
    postLessonNote: builder.mutation({
      query: (formData) => ({
        url: API.SUBMIT_LESSON_NOTE,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),
    // New endpoint for getting each lesson note by ID
    getEachLessonNote: builder.query({
      query: (id) => ({
        url: `${API.GET_EACH_LESSON_NOTES}/${id}`,
        method: "GET",
      }),
    }),
    //Edit lessonNote
    postEditedLessonNote: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${API.UPDATE_LESSON_NOTES}/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),
    //Delete LessonNote
    deleteLessonNote: builder.mutation({
      query: (id) => ({
        url: `${API.DELETE_LESSON_NOTES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),
    //Upload an attachment
    uploadFile: builder.mutation({
      query: (payload) => ({
        url: `${API.UPLOAD_ATTACHMENT}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),
    //submit LessonNote
    submitLessonNote: builder.mutation({
      query: (id) => ({
        url: `${API.SUBMIT_LESSON_NOTE}/${id}/Submit`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),
    
    //Endorsement
    lessonStaff: builder.query({
      query: ({
        StaffId,
        SubjectId,
        WeekId,
        ClassName,
        SearchText = "",
        PageSize = 10,
        PageNumber = 1,
        }) => ({
        url: API.LESSON_NOTES_STAFF,
        method: "GET",
        params: { StaffId, SubjectId, WeekId, ClassName, SearchText, PageSize, PageNumber },
      }),
    }),    
    
    // fetchStaff
    getStaffId: builder.query({
      query: () => ({
        url: API.ENDORSEMENT_TEACHERS_HOD,
        method: "GET",
      }),
    }),
    // subjectId for Endorsement
    getEndorsementSubject: builder.query({
      query: (id) => ({
        url: `${API.ENDORSEMENT_SUBJECT}/${id}`,
        method: "GET",
      }),
    }),
    // Week for Endorsement
    getEndorsementWeek: builder.query({
      query: (id) => ({
        url: `${API.ENDORSEMENT_WEEKS}/${id}`,
        method: "GET",
      }),
    }),

    // ClassArm for Endorsement
    getEndorsementClassArm: builder.query({
      query: ({teacherId, subjectId}) => ({
        url: `${API.ENDORSEMENT_CLASS}/${teacherId}/${subjectId}`,
        method: "GET",
      }),
    }),

    //Endorse LessonNote
    endorseLessonNote: builder.mutation({
      query: ({id}) => ({
        url: `${API.ENDORSE_LESSON_NOTE}/${id}/Staff/Endorse`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),
    //Reject LessonNote
    rejectLessonNote: builder.mutation({
      query: ({id, comment}) => ({
        url: `${API.REJECT_LESSON_NOTE}/${id}/Staff/Reject?comment=${encodeURIComponent(comment)}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "LessonNotes", id: "LIST" }],
    }),

  }),
});

export const { 
useGetAllLessonNotesQuery, useGetLessonSessionsQuery, useGetLessonWeeksQuery, useGetLessonTermsQuery, useGetLessonSubjectQuery, useGetLessonClassessQuery, usePostLessonNoteMutation, useGetEachLessonNoteQuery, usePostEditedLessonNoteMutation, useEndorseLessonNoteMutation, useRejectLessonNoteMutation, useDeleteLessonNoteMutation, useUploadFileMutation, useSubmitLessonNoteMutation, useLessonStaffQuery, useGetStaffIdQuery, useGetEndorsementSubjectQuery, useGetEndorsementWeekQuery, useGetEndorsementClassArmQuery, useGetReportTypeQuery, useCreateReportMutation, 
} = lessonNoteApi;
