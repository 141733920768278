import { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, FormControl, IconButton, Grid, InputLabel, MenuItem, MenuList, Pagination, Popover, Select, Skeleton, styled, Table, TableContainer, TableHead, TableRow, Typography, TableBody, InputBase,
} from "@mui/material";
import { BsSearch } from "react-icons/bs";
// import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { FormattedText, FormateDate } from "../../utils";
import colors from "../../assets/colors.json";

import TableLoader from "../TableLoader";
import { useLazyGetAllEndorsedReportQuery, useGetReportTypeQuery } from "../../api/reportAPI";
import { 
  useGetLessonSessionsQuery, useGetLessonTermsQuery, useGetLessonWeeksQuery,
} from "../../api/lessonNoteApi";
import PreviewReportPDF from "./PreviewReportPDF";
import FormLoader from "../FormLoader";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  border: "0.5px solid #891e78",
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


export default function Report() {
  const [page, setPage] = useState(1);
  const PageSize = 10;
  const [allRecords, setAllRecords] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRecords, setFilteredRecords] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  
  const [filter, setFilter ] = useState({
    ReportTypeId: "",
    SessionId: "",
    Term: "",
    WeekId: "",
    SearchText: searchTerm,
    PageNumber: page,
    PageSize,
  });
  
  
  const { data: sessions, isLoading: sessionsLoading } = useGetLessonSessionsQuery();
  const { data: lessonTerm, isLoading: termLoading } = useGetLessonTermsQuery();
  const { data: lessonWeeks, isLoading: weekLoading } = useGetLessonWeeksQuery();
  const { data: reportType, isLoading: reportTypeLoading } = useGetReportTypeQuery();
  const [fetchEndorsedReports, { data, isLoading }] = useLazyGetAllEndorsedReportQuery();

  const record = data?.data?.records

  useEffect(() => {
    if (record && Array.isArray(record)) {
      setAllRecords(record);
    }
  }, [record]);

  const handleSubmit = async () => {
    setFormLoading(true);
    try {
      const response = await fetchEndorsedReports(filter);
      console.log(response)
      if (response.data) {
        setAllRecords(response?.data?.data?.records);
      }
    } catch (error) {
      console.error("Error fetching submitted reports:", error);
    } finally {
      setFormLoading(false);
    }
  };

  const handleClick = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(record.id);
  }

  //search 
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

     // Calculate the total pages dynamically
  const totalPages = data?.data?.totalRecordCount
  ? Math.ceil(data?.data?.totalRecordCount / PageSize)
  : 0;

    // Handle pagination change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  //preview File
   const handlePreviewFile = (record) => {
    setSelectedItem(record);
    setIsPreviewOpen(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

    
    return (
     <Box>
      <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        bgcolor: "#691e78",
        borderRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
        height: "fit",
        paddingBottom: "20px"
      }}
    >

      {/* session */}
      <Grid item lg={3} xs={3.5}>
        <Box>
          <InputLabel
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontFamily: "gilroy-regular",
            }}
          >
            Session
          </InputLabel>
          <FormControl fullWidth>
            <Select
              sx={{
                fontFamily: "Gilroy-Regular",
                border: "1px solid white",
                color: "#fff",
              }}
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              size="small"
              name="SessionId"
              value={filter.SessionId ?? ""}
              onChange={(e) => setFilter((prev) => ({ ...prev, SessionId: e.target.value }))}
            >
              <MenuItem
                sx={{ fontFamily: "Gilroy-Regular" }}
                value="" disabled
              >
              <em>Choose Session</em>
              </MenuItem>
              {sessionsLoading && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                  {(!sessions || sessions?.data?.length === 0) && !sessionsLoading ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Session
                    </Typography>
                  </Box>
                ) : (sessions?.data?.length > 0 &&
                  sessions?.data?.map((session) => (
                    <MenuItem
                      key={session.id}
                      value={session.id}
                      sx={{
                        fontFamily: "gilroy-regular",
                        fontSize: "13px",
                      }}
                    >
                      {session.name}
                    </MenuItem>
                  ))
                )}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      {/* lessonTerm */}
      <Grid item lg={2.5} xs={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <InputLabel
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontFamily: "gilroy-regular",
            }}
          >
            Term
          </InputLabel>
          <FormControl fullWidth>
            <Select
              sx={{
                fontFamily: "Gilroy-Regular",
                border: "1px solid white",
                color: "#fff",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              size="small"
              name="Term"
              value={filter.Term ?? ""}
              onChange={(e) => setFilter((prev) => ({ ...prev, Term: e.target.value }))}
              displayEmpty
            >
              <MenuItem
                sx={{ fontFamily: "Gilroy-Regular" }}
                value=""
                disabled
              >
              <em>Choose Term</em>
              </MenuItem>
              {termLoading && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {!termLoading && !lessonTerm ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Subject Available
                    </Typography>
                  </Box>
                ) : (lessonTerm?.data.length > 0 &&
                  lessonTerm?.data.map((Term, index) => (
                    <MenuItem
                      key={Term.id || index}
                      value={Term.value}
                      sx={{
                        fontFamily: "gilroy-regular",
                        fontSize: "13px",
                      }}
                    >
                      {Term.name}
                    </MenuItem>
                  ))
                )}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      {/* week */}
      <Grid item lg={2} xs={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <InputLabel
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontFamily: "gilroy-regular",
            }}
          >
            Week
          </InputLabel>
          <FormControl fullWidth>
            <Select
              sx={{
                fontFamily: "Gilroy-Regular",
                border: "1px solid white",
                color: "#fff",
              }}
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              size="small"
              name="WeekId"
              value={filter.WeekId ?? ""}
              onChange={(e) => setFilter((prev) => ({ ...prev, WeekId: e.target.value }))} 
            >
              <MenuItem
                sx={{ fontFamily: "Gilroy-Regular" }}
                value=""
                disabled
              >
              <em>Choose week</em>
              </MenuItem>
              {weekLoading && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {!weekLoading && !lessonWeeks?.data ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Week Available
                    </Typography>
                  </Box>
                ) : (lessonWeeks?.data.length > 0 &&
                  lessonWeeks?.data.map((weeks) => (
                    <MenuItem
                      key={weeks.id}
                      value={weeks.id}
                      sx={{
                        fontFamily: "gilroy-regular",
                        fontSize: "13px",
                      }}
                    >
                      {weeks.name}
                    </MenuItem>
                  ))
                )}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      {/* Report */}
      <Grid item lg={2} xs={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <InputLabel
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontFamily: "gilroy-regular",
            }}
          >
            Report Type
          </InputLabel>
          <FormControl fullWidth>
            <Select
              sx={{
                fontFamily: "Gilroy-Regular",
                border: "1px solid white",
                color: "#fff",
              }}
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              size="small"
              name="ReportTypeId" 
              value={filter.ReportTypeId}
              onChange={(e) => setFilter((prev) => ({ ...prev, ReportTypeId: e.target.value }))}
            >
              
              <MenuItem
                sx={{ fontFamily: "Gilroy-Regular" }}
                value=""
                disabled
              >
              <em>Choose Report</em>
              </MenuItem>
              {reportTypeLoading && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {!reportTypeLoading && reportType?.data === 0 ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No report
                    </Typography>
                  </Box>
                ) : (reportType?.data?.length > 0 &&
                  reportType?.data?.map((reportType) => (
                    <MenuItem
                      key={reportType.id}
                      value={reportType.id}
                      sx={{
                        fontFamily: "gilroy-regular",
                        fontSize: "13px",
                      }}
                    >
                      {reportType.name}
                    </MenuItem>
                  ))
                )}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      {/* submit */}
      <Grid item lg={2} xs={2}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Button
            disabled={formLoading}
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            sx={{
              textTransform: "initial",
              backgroundColor: "white",
              color: "#671E78"
            }}
          >
            {formLoading ? (
                <FormLoader />
              ) : (
            <span>Submit</span>
            )}
          </Button>
        </Box>
      </Grid>
      </Grid>
      {/* Table */}
      <Box
        mt={2}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{ backgroundColor: "rgba(103, 30, 120, 0.1)", width: "100%" }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Staff Name</StyledTableCell>
                <StyledTableCell>Week</StyledTableCell>
                <StyledTableCell>Session</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Term</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Approve Date</StyledTableCell>
                <StyledTableCell>Attach File</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {isLoading ? (
                <StyledTableRow >
                <StyledTableCell colSpan={10} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                  <TableLoader />
                </StyledTableCell>
              </StyledTableRow>
              ) :
              (allRecords?.length > 0) && (
                allRecords.map((record, index) => (
                  <StyledTableRow 
                    style={
                      index % 2
                        ? { background: "rgba(103, 30, 120, 0.1)" }
                        : { background: "white" }
                    }
                    key={record.id || index} hover
                  >
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{record?.createdBy ? FormattedText(record?.createdBy, 12):"N/A"}</StyledTableCell>
                    <StyledTableCell>{record?.week}</StyledTableCell>
                    <StyledTableCell>{record?.session}</StyledTableCell>
                    <StyledTableCell>
                      {record?.status === "Endorsed" && (
                        <Box sx={{ color: "#67a116", bgcolor: "#67a1161a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Pending" && (
                        <Box sx={{ color: "#ffc710", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Rejected" && (
                        <Box sx={{ color: "#FF0000", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Submitted" && (
                        <Box sx={{ color: "#008000", bgcolor: "#D9ECD9", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                      {record?.status === "Declined" && (
                        <Box sx={{ color: "#b22929", bgcolor: "#b229291a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                          {record?.status}
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{record?.term}</StyledTableCell>
                    <StyledTableCell>{record.createdBy}</StyledTableCell>
                    <StyledTableCell>{record?.dateCreated ? FormateDate(record.dateCreated) : "N/A"}</StyledTableCell>
                    <StyledTableCell>{record?.path ? FormattedText(record?.path, 14) : "N/A"}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleClick(e, record)}>
                        <MoreVertIcon />
                      </IconButton>
                      
                      <Popover
                        id={id}
                        open={open && selectedItem === record.id}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: "#671E78",
                            color: "#fff",
                            fontFamily: "Gilroy-Regular",
                            px: 3,
                          }}
                        >
                          <MenuList sx={{ fontFamily: "Gilroy-Regular" }} >
                            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }} onClick={() => handlePreviewFile(record)}>Preview File</MenuItem>
                          </MenuList>
                        </Box>
                      </Popover>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
              <>
              {(!isLoading && allRecords?.length === 0) && (
                // Show a message when no records are available
                <StyledTableRow>
                  <StyledTableCell colSpan={10} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                    No records available.
                  </StyledTableCell>
                </StyledTableRow>

              )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 3 }}>
        <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
      </Box>
      <PreviewReportPDF
        record={selectedItem}
        isOpen={isPreviewOpen}
        onclose={() => setIsPreviewOpen(false)}
      />
     </Box>
    );
  }
