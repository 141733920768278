import { API } from "./constant";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const reportApi = createApi({
  reducerPath: "report",
  baseQuery,
  tagTypes: ["Report"],
  endpoints: (builder) => ({
    getAllReport: builder.query({
        query: ({ searchText = "", pageNumber = 1, pageSize = 10 } = {}) => ({
          url: API.ALL_REPORTS,
          method: "GET",
          params: {
            pageNumber,
            pageSize,
            ...(searchText ? { searchText } : {}),
          },
        }),
        providesTags: (result) =>
          result && result.status // Check if result is defined and has status
            ? [
                ...result.data.records.map(({ id }) => ({
                  type: "Report",
                  id,
                })),
                { type: "Report", id: "LIST" },
              ]
            : [{ type: "Report", id: "LIST" }],
    }),
     // Report type
     getReportType: builder.query({
        query: () => ({
          url: API.REPORT_TYPES,
          method: "GET",
        }),
      }),
  
       // New Mutation for creating Report
       createReport: builder.mutation({
        query: (data) => ({
          url: API.POST_REPORT,
          method: "POST",
          body: data,
        }),
        invalidatesTags: [{ type: "Report", id: "LIST" }],
      }),

       // New endpoint for getting each report note by ID
    getEachReport: builder.query({
        query: (id) => ({
          url: `${API.EACH_REPORT}/${id}`,
          method: "GET",
        }),
      }),
      //Edit Report
      postEditedReport: builder.mutation({
        query: ({ id, payload }) => ({
          url: `${API.EDIT_REPORT}/${id}`,
          method: "PUT",
          body: payload,
        }),
        invalidatesTags: [{ type: "Report", id: "LIST" }],
      }),
      //Delete Report
      deleteReport: builder.mutation({
        query: (id) => ({
          url: `${API.DELETE_REPORT}/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: [{ type: "Report", id: "LIST" }],
      }),
      //Upload an attachment
      uploadFile: builder.mutation({
        query: (payload) => ({
          url: `${API.UPLOAD_REPORT}`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: [{ type: "Report", id: "LIST" }],
      }),
      //submit Report
      submitReport: builder.mutation({
        query: (id) => ({
          url: `${API.SUBMIT_REPORT}/${id}/Submit`,
          method: "PUT",
        }),
        invalidatesTags: [{ type: "Report", id: "LIST" }],
      }),
      
      //get all submitted Report
      getAllSubmittedReport: builder.query({
        query: (params) => ({
          url: API.SUBMITTED_REPORT,
          method: "GET",
          params,
        }),
      }),
      //Endorse Report
    endorseReport: builder.mutation({
      query: ({id}) => ({
        url: `${API.ENDORSE_REPORT}/${id}/Endorse`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Report", id: "LIST" }],
    }),
    //Reject Report
    rejectReport: builder.mutation({
      query: ({id, comment}) => ({
        url: `${API.REJECT_REPORT}/${id}/Reject?comment=${encodeURIComponent(comment)}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Report", id: "LIST" }],
    }),

    //get all Endorsed Report
    getAllEndorsedReport: builder.query({
      query: (params) => ({
        url: API.ALL_ENDORSE_REPORT,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
    useGetReportTypeQuery, useCreateReportMutation, useGetAllReportQuery, useDeleteReportMutation, useGetEachReportQuery, usePostEditedReportMutation, useSubmitReportMutation, useUploadFileMutation, useLazyGetAllSubmittedReportQuery, useEndorseReportMutation, useRejectReportMutation, useLazyGetAllEndorsedReportQuery,
} = reportApi;