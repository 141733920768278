import spinner from '../assets/loader/spinner.svg'

const TableLoader = () => {
  return (
    <div className="flex justify-center items-center z-index-90">
        <div>
            <img src={spinner} alt="loading..." className='h-[80px] w-[80px]' />
        </div>
    </div>
  )
}

export default TableLoader