import React, { useState, useEffect } from "react";

import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, MenuList, Pagination, Popover, Select, Skeleton, styled, Table, TableContainer, TableHead, TableRow, Typography, TableBody, InputBase,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BsSearch } from "react-icons/bs";
import { useLessonStaffQuery, useGetStaffIdQuery, useGetEndorsementSubjectQuery, useGetEndorsementWeekQuery, useGetEndorsementClassArmQuery } from "../../api/lessonNoteApi";
import TableLoader from "../TableLoader";
import { FormateDate } from "../../utils";
import PreviewPDF from "./PreviewPDF";
import { toast } from "react-toastify";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  border: "0.5px solid #891e78",
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Comment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [allRecords, setAllRecords] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [filters, setFilters] = useState({
    StaffId: "",
    SubjectId: "",
    WeekId: "",
    ClassName: "",
    searchText: searchTerm,
    pageNumber: page,
    pageSize,
  });
  
  const { data: staffList, isLoading: staffListLoading } = useGetStaffIdQuery();
  const { data: lessonSubject, isLoading: subjectLoading } = useGetEndorsementSubjectQuery(selectedStaffId, { skip: !selectedStaffId });
  const { data: lessonWeeks, isLoading: weekLoading } = useGetEndorsementWeekQuery(selectedStaffId, { skip: !selectedStaffId });
  const { data: lessonNoteClass, isLoading: classLoading } = useGetEndorsementClassArmQuery(
    { teacherId: selectedStaffId, subjectId: selectedSubjectId },
    { skip: !selectedStaffId || !selectedSubjectId }
  );
  
  const { data, error, isLoading } = useLessonStaffQuery(filters, { skip: !triggerFetch });
  
  const response = data?.data

  useEffect(() => {
    if (staffList?.data.length === 0) {
      toast.info("No available teacher for HOD")
    }
  },[staffList])

  useEffect(() => {
      if (response) {
        setAllRecords(response);
      }
    }, [data]);
  
  const handleTeacherChange = (event) => {
    const staffId = event.target.value;
    setSelectedStaffId(staffId);
    setSelectedSubjectId("");
    setFilters({ StaffId: staffId, SubjectId: "", WeekId: "", ClassName: "" });
  };

  //submit button
  const handleSubmit = () => {
    setTriggerFetch(true);
  };

  const handleClick = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(record.id);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  //search 
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

   //preview File
   const handlePreviewFile = (record) => {
    setSelectedItem(record);
    setIsPreviewOpen(true);
    handleClose();
  };

     // Calculate the total pages dynamically
  const totalPages = data?.data?.totalRecordCount
  ? Math.ceil(data?.data?.totalRecordCount / pageSize)
  : 0;
  // Handle pagination change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          bgcolor: "#691e78",
          borderRadius: "10px",
          justifyContent: "space-around",
          alignItems: "center",
          height: "fit",
          paddingBottom: "20px"
        }}
      >

        {/* Teacher */}
        <Grid item lg={3} xs={4}>
          <Box>
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Teacher
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                name="StaffId"
                value={filters.StaffId ?? ""}
                onChange={handleTeacherChange}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="" disabled
                >
                 <em>Choose Teacher</em>
                </MenuItem>
                {staffListLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {(!staffList || staffList?.data?.length === 0) && !staffListLoading ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Teacher
                      </Typography>
                    </Box>
                  ) : (staffList?.data?.length > 0 &&
                    staffList?.data?.map((staff) => (
                      <MenuItem
                        key={staff.id}
                        value={staff.id}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {staff.name}
                      </MenuItem>
                    ))
                  )}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* subject */}
        <Grid item lg={2.5} xs={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Subject
            </InputLabel>
            <FormControl fullWidth disabled={!selectedStaffId || subjectLoading}>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                name="SubjectId"
                value={filters.SubjectId}
                onChange={(e) => {
                  const newSubjectId = e.target.value;
                  setFilters((prev) => ({ ...prev, SubjectId: newSubjectId }));
                  setSelectedSubjectId(newSubjectId);
                }}
                displayEmpty
              >
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value=""
                  disabled
                >
                 <em>Choose Subject</em>
                </MenuItem>
                {subjectLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                  {!subjectLoading && (!lessonSubject || lessonSubject?.data.length === 0) ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (lessonSubject?.data.length > 0 &&
                    lessonSubject?.data.map((weeks) => (
                      <MenuItem
                        key={weeks.id}
                        value={weeks.id}
                        disabled={!selectedStaffId} 
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {weeks.name}
                      </MenuItem>
                    ))
                  )}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* week */}
        <Grid item lg={2} xs={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Week
            </InputLabel>
            <FormControl fullWidth disabled={!selectedStaffId || subjectLoading}>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                name="WeekId"
                value={filters.WeekId}
                onChange={(e) => setFilters((prev) => ({ ...prev, WeekId: e.target.value }))} 
              >
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value=""
                  disabled
                >
                 <em>Choose week</em>
                </MenuItem>
                {weekLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                  {!weekLoading && !lessonWeeks?.data ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Week Available
                      </Typography>
                    </Box>
                  ) : (lessonWeeks?.data.length > 0 &&
                    lessonWeeks?.data.map((weeks) => (
                      <MenuItem
                        key={weeks.id}
                        value={weeks.id}
                        disabled={!selectedStaffId} 
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {weeks.name}
                      </MenuItem>
                    ))
                  )}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* classArm */}
        <Grid item lg={2} xs={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Class
            </InputLabel>
            <FormControl fullWidth disabled={!selectedStaffId || subjectLoading}>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                name="ClassName" 
                value={filters.ClassName}
                onChange={(e) => setFilters((prev) => ({ ...prev, ClassName: e.target.value }))}
              >
                
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value=""
                  disabled
                >
                 <em>Choose ClassName</em>
                </MenuItem>
                {classLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                  {!classLoading && lessonNoteClass?.data === 0 ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No class Available
                      </Typography>
                    </Box>
                  ) : (lessonNoteClass?.data?.length > 0 &&
                    lessonNoteClass?.data?.map((lesson) => (
                      <MenuItem
                        key={lesson.id}
                        value={lesson.value}
                        disabled={!selectedStaffId} 
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {lesson.name}
                      </MenuItem>
                    ))
                  )}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={2} xs={5}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Button
              fullWidth
              variant="contained"
              disabled={!selectedStaffId}
              sx={{
                bgcolor: "#fff",
                color: "#691e78",
                "&:hover": { color: "#fff" },
              }}
              onClick={handleSubmit}
            > 
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box
        mt={2}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{ backgroundColor: "rgba(103, 30, 120, 0.1)", width: "100%" }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>School Year</StyledTableCell>
                <StyledTableCell>Week</StyledTableCell>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created Date</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {isLoading ? (
                 <StyledTableRow>
                 <StyledTableCell colSpan={9} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                   <TableLoader />
                 </StyledTableCell>
               </StyledTableRow>
              ) :(allRecords?.records?.length > 0 && (
                allRecords?.records.map ((record, index) => (
                <StyledTableRow
                  style={
                    index % 2
                      ? { background: "rgba(103, 30, 120, 0.1)" }
                      : { background: "white" }
                  }
                  key={record.id || index} hover
                >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{record?.session}</StyledTableCell>
                <StyledTableCell>{record?.week}</StyledTableCell>
                <StyledTableCell>{record?.subject}</StyledTableCell>
                <StyledTableCell>{record?.className}</StyledTableCell>
                <StyledTableCell>
                  {record?.status === "Endorsed" && (
                    <Box sx={{ color: "#67a116", bgcolor: "#67a1161a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                      {record?.status}
                    </Box>
                  )}
                  {record?.status === "Pending" && (
                    <Box sx={{ color: "#ffc710", bgcolor: "#ffc7101a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                      {record?.status}
                    </Box>
                  )}
                  {record?.status === "Submitted" && (
                    <Box sx={{ color: "#008000", bgcolor: "#D9ECD9", textAlign: "center", borderRadius: "3px", px: 1 }}>
                      {record?.status}
                    </Box>
                  )}
                  {record?.status === "Declined" && (
                    <Box sx={{ color: "#b22929", bgcolor: "#b229291a", textAlign: "center", borderRadius: "3px", px: 1 }}>
                      {record?.status}
                    </Box>
                  )}
                </StyledTableCell>
                <StyledTableCell>{record.createdBy}</StyledTableCell>
                <StyledTableCell>{record?.dateCreated ? FormateDate(record.dateCreated) : "N/A"}</StyledTableCell>
                <StyledTableCell>
                  <IconButton onClick={(e) => handleClick(e, record)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open && selectedItem === record.id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "#671E78",
                        color: "#fff",
                        fontFamily: "Gilroy-Regular",
                        px: 3,
                      }}
                    >
                      <MenuList sx={{ fontFamily: "Gilroy-Regular" }} >
                        <MenuItem sx={{ fontFamily: "Gilroy-Regular" }} onClick={() => handlePreviewFile(record)}>
                          Preview Attach File
                        </MenuItem>
                      </MenuList>
                    </Box>
                  </Popover>
                </StyledTableCell>
              </StyledTableRow>
                ))
              )   
              )}
              <>
              {(!isLoading && !allRecords?.records?.length) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={9} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                    No records available.
                  </StyledTableCell>
                </StyledTableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 3 }}>
        <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
      </Box>
      <PreviewPDF
        record={selectedItem}
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
      />
    </Box>
  );
};

export default Comment;