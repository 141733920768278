import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Title from "../../Dashboard/Title";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TableCell,
  Popover,
  MenuList,
  MenuItem,
  Divider,
  TextField,
  InputLabel,
  Skeleton,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddQuestion from "./AddQuestion";
import Edit from "./Edit";
import { BsSearch } from "react-icons/bs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import LoaderLine from "../../LoaderLine";
import Comment from "./Comment";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import FormLoader from "../../FormLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const Submission = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const score = queryParams.get("score");
  const isClosed = queryParams.get("isClosed");
  const isClosedBoolean = isClosed === "true";
  const [openModal, setOpenModal] = React.useState(false);
  const [submissionData, setSubmissionData] = React.useState(null);
  
  const handleOpenModal = (item) => {
    setOpenModal(true);
    if (data) {
      setSubmissionData(item);
    }
  };
  const { user } = useSelector((state) => state.auth);
  const handleCloseModal = () => setOpenModal(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [grade, setGrade] = useState();
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const adtoken = localStorage.getItem("admToken");
  const [data, setData] = useState([]);
  const [submitted, setSubmitted] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sLoading, setSLoading] = useState(false);
  const { id: assid } = useParams();

  const convertDate = (dateTimeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const convertedTime = new Date(dateTimeString).toLocaleTimeString(
      "en-US",
      options
    );
    return convertedTime;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInputChange = (e) => {
    const mark = Number(score);
    const inputValue = parseFloat(e.target.value);
    if (mark < inputValue) {
      toast.warn(`grade score is higher than expected score which is ${mark}`);
    } else {
      setGrade(inputValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();

  const headers = {
    Authorization: `Bearer ${adtoken}`,
  };

  useEffect(() => {
    fetchSubmission();
  }, []);

  const fetchSubmission = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/Assignment/AssignmentSubmissions?AssignmentId=${assid}`,
        { headers }
      );
      if (response.status !== 200) {
        setLoading(false);
        console.log("Error: Youa", response);
        return;
      }

      const data = response.data;
      if (!data?.status) {
        setLoading(false);
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setLoading(false);
      setData(data?.data);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
    }
  };

  const fetchSubmittedAnswers = async () => {
    setSLoading(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/Assignment/ViewSubmittedAnswers?assignmentId=${assid}&studentId=${submissionData.studentId}`,
        { headers }
      );
      if (response.status !== 200) {
        setSLoading(false);
        console.log("Error: Youa", response);
        return;
      }

      const data = response.data;
      if (!data?.status) {
        setSLoading(false);
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setSLoading(false);
      setSubmitted(data?.data);
    } catch (error) {
      setSLoading(false);
      console.error("Error:", error.message);
    }
  };

  const handleGradeAssignment = async () => {
    const gradeData = {
      gradeScore: grade,
      submissionId: submissionData.submissionId,
      // studentId: studentId,
      // staffId: userInfo?.staffCategoryId,
      staffId: user.staffId,
    };
    if (isClosedBoolean) {
      toast.warn("This assignment is closed");
      return;
    } else {
      setLoading(!loading);
      try {
        const res = await axios.post(
          `${endpoint_two}/api/v${api_version}/Assignment/GradeAssignment`,
          gradeData,
          { headers }
        );
        if (res) {
          setLoading(false);
          fetchSubmission();
          toast.success("Grade successfully added");
          handleCloseModal();
        }
      } catch (err) {
        setLoading(false);
        toast.error("there was an error grading this student", err);
      }
    }
  };

  const handleCloseAssignment = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${endpoint_two}/api/v${api_version}/Assignment/ActivateORDeactivateAssignment?AssignmentId=${assid}&IsClosed=${true}`,
        { headers }
      );
      if (res) {
        setLoading(false);
        toast.success("You have successfully closed this assignment");
        handleCloseModal();
      }
    } catch (err) {
      setLoading(false);
      toast.error("there was an error closing the assignment", err);
    }
  };
  return (
    <>
      {loading && <LoaderLine />}
      <Box
        sx={{
          pb: 2,
          // borderBottom: "2px solid #671E78",
        }}
      >
        <Title title="Assignment - Submission" />
        {/* <Typography sx={{ fontFamily: "gilroy-medium", fontSize: "20px" }}>
          English language(Oral English)
        </Typography> */}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Link to="/academicActivities?tab=2">
          <IconButton
            sx={{ bgcolor: "#671e781a", width: "30px", height: "30px", mr: 2 }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Link>
        <Typography variant="caption" sx={{ fontFamily: "gilroy-regular" }}>
          Back to Assignment
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer
          sx={{ border: "1px solid #671E78", borderRadius: "20px" }}
        >
          <Tabs
            sx={{ bgcolor: "#E5E5E5" }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="Submission" sx={{ width: "50%" }} {...a11yProps(0)} />
            <Tab label="Comments" sx={{ width: "50%" }} {...a11yProps(1)} />
          </Tabs>

          <CustomTabPanel value={value} index={0}>
            <Box
              sx={{
                pt: 4,
                background: "rgba(103, 30, 120, 0.1)",
                height: "100px",
                pl: { xs: 2.5 },
              }}
            >
              <Search
                sx={{
                  width: {
                    lg: "345px",
                    md: "330px",
                    sm: "auto",
                    xs: "auto",
                    pl: { xs: 6 },
                  },
                }}
              >
                <SearchIconWrapper>
                  <BsSearch />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>

            <Table
              sx={{
                minWidth: 700,
                fontFamily: "Gilroy-Regular",
              }}
              aria-label="customized table"
            >
              <TableHead
                sx={{
                  backgroundColor: "rgba(103, 30, 120, 0.1)",
                  width: "100%",
                }}
              >
                <TableRow>
                  <StyledTableCell>S/N</StyledTableCell>
                  <StyledTableCell>Admission No</StyledTableCell>
                  <StyledTableCell>Student Name</StyledTableCell>
                  <StyledTableCell>Time Submitted</StyledTableCell>
                  <StyledTableCell>Date Submitted</StyledTableCell>
                  <StyledTableCell>Score</StyledTableCell>

                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                {data &&
                  data.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      style={
                        index % 2
                          ? { background: "rgba(103, 30, 120, 0.1)" }
                          : { background: "white" }
                      }
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        {row.admissionNo ? row.admissionNo : "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.name ? row.name : "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {convertDate(row.submittedDateTime)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(row.submittedDateTime).toLocaleDateString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.sCore ? row.sCore : "--"}
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Button
                          onClick={() => {
                            fetchSubmittedAnswers();
                            handleOpenModal(row);
                          }}
                          variant="outlined"
                          size="small"
                          sx={{ textTransform: "initial" }}
                        >
                          View Submission
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.length === 0 && (
              <>
                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    height: "300px",
                    // border: "1px solid red",
                  }}
                >
                  <Typography sx={{ fontFamily: "Gilroy-Regular" }}>
                    No Submitted Assignment Available
                  </Typography>
                </Box>
              </>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Comment />
          </CustomTabPanel>
        </TableContainer>
      </Box>
      <Box sx={{ marginTop: "20px", marginBottom: "50px" }}>
        {isClosedBoolean === false ? (
          <Box align="right">
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: "initial" }}
              s
              onClick={handleCloseAssignment}
              disabled={loading}
            >
              {loading ? <FormLoader /> : <span>Close assignment</span>}
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={() => {
                setOpenEdit(true);
                setAnchorEl(null);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Delete</MenuItem>
          </MenuList>
        </Box>
      </Popover>

      <Edit open={openEdit} onClose={handleCloseEdit} />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="flex justify-center mt-5 mb-2">
            <Typography
              sx={{ textTransform: "initial", fontFamily: "gilroy-regular" }}
            >
              View Submission
            </Typography>
          </Box>
          <Box className="mb-2 flex justify-center">
            <Box className="gap-3 md:flex items-center">
              <Box>
                <Typography>Name: {submissionData?.name ?? "--"}</Typography>
              </Box>
              <Box>
                <Typography>
                  Admission No: {submissionData?.admissionNo ?? "--"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className={`bg-gray-300 w-full overflow-auto h-auto md:min-h-[300px] p-[10px]`}
          >
            {sLoading && (
              <Box className="w-full h-full">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: "2rem",
                    mx: 2,
                    height: "600px",
                    width: "650px",
                  }}
                />
              </Box>
            )}
            <Box>
              {submitted ? (
                <Box>
                  {submitted?.map((items, _index) => {
                    const index = _index + 1;
                    return (
                      <Box
                        sx={{ display: "flex" }}
                        className={`gap-5 ${index > 0 && "border-b-[2px]"} p-3`}
                        key={index}
                      >
                        <Box>
                          <Typography
                            sx={{
                              textTransform: "initial",
                              fontFamily: "gilroy-regular",
                            }}
                          >
                            {index}
                          </Typography>
                        </Box>
                        <Box>
                          <Box className="gap-5 w-full  items-center lg:flex">
                            <Typography className="md:text-[20px] text-[16px]">
                              Question:
                            </Typography>
                            <Typography
                              sx={{
                                textTransform: "initial",
                                fontFamily: "gilroy-regular",
                              }}
                              className="text-[14px]  lg:text-[16px]"
                            >
                              {items?.question}
                            </Typography>
                          </Box>
                          <Box className="gap-5 w-full  items-center lg:flex">
                            <Typography>Answer:</Typography>
                            <Typography
                              sx={{
                                textTransform: "initial",
                                fontFamily: "gilroy-regular",
                              }}
                              className="text-[14px]  lg:text-[16px]"
                            >
                              {items?.submittedAnswer}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "gilroy-regular",
                    }}
                  >
                    This student is yet to submit any classwork
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 3, width: "100%" }}>
            <Box>
              <InputLabel>Grade</InputLabel>
              <TextField
                fullWidth
                type="number"
                onChange={handleInputChange}
                value={grade}
                // disabled={fixedGrade > 0}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                      fontFamily: "gilroy-regular",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
              <Button
                disabled={loading || !grade}
                variant="contained"
                onClick={handleGradeAssignment}
                fullWidth
                sx={{ my: 3 }}
              >
                {loading ? <FormLoader /> : <span>Grade</span>}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Submission;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
