import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Typography,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../assets/colors.json";
import { 
  useGetLessonSessionsQuery, useGetLessonTermsQuery, useGetLessonWeeksQuery
} from "../../api/lessonNoteApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { useCreateReportMutation, useGetReportTypeQuery } from "../../api/reportAPI";

const CreateReportModal = ({ onClose }) => {
  const [formLoading, setFormLoading] = useState(false);
  const { data: sessions, isLoading: sessionsLoading } = useGetLessonSessionsQuery();
  const { data: lessonTerm, isLoading: termLoading } = useGetLessonTermsQuery();
  const { data: lessonWeeks, isLoading: weekLoading } = useGetLessonWeeksQuery();
  const { data: reportType, isLoading: reportTypeLoading } = useGetReportTypeQuery();
  const [createReport] = useCreateReportMutation();
  
  useEffect(() => {
     if (sessions?.data?.length > 0 && !formik.values.sessionId) {
       const lastSession = sessions.data[sessions.data.length - 1];
       formik.setFieldValue("sessionId", lastSession.id);
     }
  }, [sessions]);
  
  const validationSchema = Yup.object().shape({
    sessionId: Yup.string().required("School Year is required"),
    term: Yup.string().required("Term is required"),
    weekId: Yup.number().required("Week is required"),
    reportTypeId: Yup.number().required("Report Type is required"),
  });

  const initialValues = {
    reportTypeId: "",
    sessionId: "",
    weekId: "",
    term: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      setFormLoading(true);
    
      try {
        const response = await createReport(values);

        if( response?.error){
          toast.error(response?.error.data.message)
          return;
        }

        if (response.data.status === true && response.data.statusCode === 2) {
          setFormLoading(false);
          toast.success(response.data.message);
          onClose();
        } else if (response.data.status === false && response.data.statusCode === 5) {
          setFormLoading(false);
          toast.error(response.data.message);
        }else if (response?.error.status === 400){
          console.log("yeah true")
        } else if (response.data.status === false && response.data.statusCode === 0) {
          setFormLoading(false);
          toast.error("Internal Server Error");
        } else {
          setFormLoading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error submitting lesson note:", error);
      } finally{
        setFormLoading(false)
      }
    },
  });

  return (
    <div className="absolute top-1/2 left-1/2 w-full max-w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4 transform -translate-x-1/2 -translate-y-1/2">
      <div className="flex justify-end ">
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>
      <h1 className="text-center text-2xl lg:text-3xl font-semibold">
        Create Report
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <Box
          p={3}
          sx={{
            overflowY: {
              xs: "scroll",
              sm: "scroll",
              md: "scroll",
              lg: "scroll",
              xl: "auto",
            },
            height: {
              xs: "450px",
              sm: "550px",
              md: "450px",
              lg: "450px",
              xl: "auto",
            },
          }}
        >
          <Box>
            <Grid container spacing={2}>
              {/* session */}
              <Grid item lg={6} xs={12}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    School Year
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    id="sessionId"
                    name="sessionId"
                    value={formik.values.sessionId ?? ""} 
                    onChange={(event) => {
                      formik.setFieldValue("sessionId", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Year</em>
                    </MenuItem>
                    {sessionsLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton
                          variant="rounded"
                          height={60}
                          sx={{ mx: 2 }}
                        />
                      </Box>
                    )}
                    {(!sessions?.data || sessions?.data?.length === 0) && !sessionsLoading ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No Sessions Available
                        </Typography>
                      </Box>
                    ) : (
                      sessions?.data.map((session) => (
                        <MenuItem
                          key={session.id}
                          value={session.id}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {session.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {formik.touched.sessionId && formik.errors.sessionId ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.sessionId}
                    </div>
                  ) : null}
                </Box>
              </Grid>
              {/* term */}
              <Grid item lg={6} xs={12}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Term
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    id="term"
                    name="term"
                    value={formik.values.term ?? ""}
                    onChange={(event) => {
                      formik.setFieldValue("term", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Term</em>
                    </MenuItem>
                    {termLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {(lessonTerm?.data?.length === 0) && !termLoading ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No term
                      </Typography>
                    </Box>
                  ) : (lessonTerm?.data?.length > 0 && 
                    lessonTerm?.data?.map((term) => (
                      <MenuItem
                        key={term.id}
                        value={term.value}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {term.name}
                      </MenuItem>
                    ))
                  )}
                  </Select>
                  {formik.touched.term && formik.errors.term ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.term}
                    </div>
                  ) : null}
                </Box>
              </Grid>
              {/* weeks */}
              <Grid item lg={12} xs={6}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Week
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    id="weekId"
                    name="weekId"
                    value={formik.values.weekId ?? ""}
                    onChange={(event) => {
                      formik.setFieldValue("weekId", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Week</em>
                    </MenuItem>
                    {weekLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {!weekLoading && !lessonWeeks?.data ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : ( lessonWeeks?.data?.length > 0 &&
                    lessonWeeks?.data.map((weeks) => (
                      <MenuItem
                        key={weeks.id}
                        value={weeks.id}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {weeks.name}
                      </MenuItem>
                    ))
                  )}
                  </Select>
                  {formik.touched.weekId && formik.errors.weekId ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.weekId}
                    </div>
                  ) : null}
                </Box>
              </Grid>
              {/* Report */}
              <Grid item lg={12} xs={6}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Report Type
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    id="reportTypeId"
                    name="reportTypeId"
                    value={formik.values.reportTypeId ?? ""}
                    onChange={(event) => {
                      formik.setFieldValue("reportTypeId", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Report</em>
                    </MenuItem>
                    {reportTypeLoading && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )}
                    {!reportTypeLoading && !reportType?.data ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Report Type
                      </Typography>
                    </Box>
                  ) : ( reportType?.data?.length > 0 &&
                    reportType?.data.map((reportId) => (
                      <MenuItem
                        key={reportId.id}
                        value={reportId.id}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {reportId.name}
                      </MenuItem>
                    ))
                  )}
                  </Select>
                  {formik.touched.reportTypeId && formik.errors.reportTypeId ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.reportTypeId}
                    </div>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Button
                disabled={formLoading}
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "initial",
                  backgroundColor: colors.primary,
                }}
              >
                {formLoading ? (
                    <FormLoader />
                  ) : (
                <span>Create</span>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default CreateReportModal;
