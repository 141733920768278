import { Box } from "@mui/system";
import React, { useState } from "react";
import Title from "../../components/Dashboard/Title";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import CreateParent from "../../components/Parent/CreateParent";
// import { Box } from "@mui/system";
// import React from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { Button, Avatar, IconButton, MenuItem, MenuList } from "@mui/material";
// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import Personal from "../../components/profile/Personal";
import Education from "../../components/profile/Education";
// import Experience from "../../components/profile/Experience";
// import Certifications from "../../components/profile/Certifications";
import GlobalStyles from "../../assets/globalStyle";
import Select from "../../components/select/select";
import HostelSetup from "../../components/hostel/HostelSetup";
import { useSelector } from "react-redux";
import { checkAccess } from "../../services/utils";
// import UserInformation from "../../components/profile/UserInformation";

const Hostel = () => {
  const { accessObj } = useSelector((state) => state.auth);
  const [mainTab, setMainTab] = useState(0);
  const [tab, setTab] = useState(0);
  const [opt, setOpt] = useState("Value 1");
  
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  return (
    <div>
      <Title title="Hostel Management" />
      <div className="flex justify-between gap-4 mb-5 cursor-pointer border-b-2 overflow-x-scroll mt-10">
        {checkAccess(accessObj.menuItems, "Hostel Setup") && (
          <span
            className={`${
              mainTab === 1
                ? "text-[#691e78] border-b-2 border-b-[#691e78]  "
                : "text-gray-500"
            } text-lg md:text-[20px] pb-3 whitespace-nowrap `}
            onClick={() => setMainTab(1)}
          >
            Hostel Setup
          </span>
        )}

        {/* <span
          className={`${
            mainTab === 1
              ? "text-[#691e78] border-b-2 border-b-[#691e78] "
              : "text-gray-300"
          } text-lg md:text-[20px] pb-3 whitespace-nowrap `}
          onClick={() => setMainTab(1)}
        >
          Online Hostel Application
        </span>
        <span
          className={`${
            mainTab === 2
              ? "text-[#691e78] border-b-2 border-b-[#691e78] "
              : "text-gray-300"
          } text-lg md:text-[20px] pb-3 whitespace-nowrap `}
          onClick={() => setMainTab(2)}
        >
          Hostel Allocation Management
        </span> */}
      </div>

      {mainTab === 1 && checkAccess(accessObj.menuItems, "Hostel Setup") && (
        <div>
          <HostelSetup />
        </div>
      )}
      {mainTab === 2 && (
        <div>
          <div className="flex bg-[#691e78] px-3 py-3 gap-3 mb-5 rounded-lg">
            <input
              type="date"
              className="bg-[#691e78] text-white ring-1 w-[400px] ring-white"
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <div className="items-center text-[#691e78] bg-white flex justify-center px-10 py-3 rounded-sm">
              Load
            </div>
          </div>

          <div className="ring-1 ring-[#691e78] rounded-xl">
            <div className="flex justify-between items-center px-10 py-5 cursor-pointer rounded-t-xl bg-gray-300">
              <div
                className={
                  tab === 0
                    ? "bg-white px-10 py-3 rounded-xl ring-1 ring-black"
                    : ""
                }
                onClick={() => setTab(0)}
              >
                <p>Pending</p>
              </div>
              <div
                className={
                  tab === 1
                    ? "bg-white px-10 py-3 rounded-xl ring-1 ring-black"
                    : ""
                }
                onClick={() => setTab(1)}
              >
                <p>Approved</p>
              </div>
              <div
                className={
                  tab === 2
                    ? "bg-white px-10 py-3 rounded-xl ring-1 ring-black"
                    : ""
                }
                onClick={() => setTab(2)}
              >
                <p>Declined</p>
              </div>
            </div>

            {tab === 0 && (
              <div>
                <ParentsMgt />
              </div>
            )}
            {tab === 1 && (
              <div>
                <ParentsMgt />
              </div>
            )}

            {tab === 2 && (
              <div>
                <ParentsMgt />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Hostel;

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});
const ParentsMgt = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <CreateSchools />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  session,
  createdBy,
  phone,
  studentClass,
  name,
  status,
  email
) {
  return { session, createdBy, phone, studentClass, name, status, email };
}

const rows = [
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 2 Grace",
    "Adeola Grace (2)",
    "Saved",
    "jss 1"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Submitted",
    "Jss 3"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Submitted",
    "Jss 3"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Submitted",
    "Jss 3"
  ),
];

const CreateSchools = (index) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate("/parentmanagement/student");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box>
      <Box>
        <Box
          sx={{
            // borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 700,
              fontFamily: "Gilroy-Regular",
              borderRadius: "0",
            }}
            aria-label="customized table"
          >
            <TableHead
              sx={{ backgroundColor: "rgba(103, 30, 120, 0.1)", width: "100%" }}
            >
              <TableRow>
                <StyledTableCell>
                  <Checkbox />
                </StyledTableCell>
                <StyledTableCell>Admission ID</StyledTableCell>
                <StyledTableCell>Application Date</StyledTableCell>
                <StyledTableCell>Student Name</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular", width: "100%" }}>
              {rows.map((row, index) => (
                <StyledTableRow
                  key={index}
                  hover
                  // onClick={handleRowClick}
                  className="w-full"
                >
                  <StyledTableCell component="th" scope="row">
                    <Checkbox />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.session}</StyledTableCell>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      sx={{ borderRadius: "20px 20px 20px 20px" }}
                    >
                      View Apllication
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ mt: 6, textAlign: "right", pr: 5, mb: 3 }}>
            <Button
              variant="contained"
              sx={{ mr: 2, textTransform: "initial" }}
            >
              Approve
            </Button>
          </Box>
        </TableContainer>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>View Details</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </Box>
  );
};

const EntranceExam = () => {
  const [tab, setTab] = useState(0);
  const [opt, setOpt] = useState("Value 1");
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  return (
    <>
      <div className="flex bg-[#691e78] px-3 py-3 gap-3 mb-5 rounded-lg">
        <input
          type="date"
          className="bg-[#691e78] text-white ring-1 w-[400px] ring-white"
        />
        <Select
          options={optionsArr}
          title="School"
          index="name"
          selected={opt}
          setSelected={(val) => {
            console.log(val);
            setOpt(val.name);
          }}
          ring
          ringWhite
        />
        <Select
          options={optionsArr}
          title="School"
          index="name"
          selected={opt}
          setSelected={(val) => {
            console.log(val);
            setOpt(val.name);
          }}
          ring
          ringWhite
        />
        <Select
          options={optionsArr}
          title="School"
          index="name"
          selected={opt}
          setSelected={(val) => {
            console.log(val);
            setOpt(val.name);
          }}
          ring
          ringWhite
        />
        <div className="items-center text-[#691e78] bg-white flex justify-center px-10 py-3 rounded-sm">
          Load
        </div>
      </div>
      <div>
        <div className="flex justify-between mb-5">
          <div>{""}</div>
          <div className="w-[300px] items-center text-white bg-[#691e78] flex justify-center px-5 py-3 rounded-lg">
            Add Exam Type
          </div>
        </div>
        <div className="ring-1 ring-[#691e78] rounded-xl ">
          <ParentsMgt />
        </div>
      </div>

      {tab === 1 && (
        <div>
          <div className="flex bg-[#691e78] px-3 py-3 gap-3 mb-5 rounded-lg">
            <input
              type="date"
              className="bg-[#691e78] text-white ring-1 ring-white"
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <div className="items-center text-[#691e78] bg-white flex justify-center px-10 py-3 rounded-sm">
              Load
            </div>
          </div>
          <div className="ring-1 ring-[#691e78] rounded-xl ">
            <ParentsMgt />
          </div>
        </div>
      )}

      {tab === 2 && (
        <div>
          <div className="flex bg-[#691e78] px-3 py-3 gap-3 mb-5 rounded-lg">
            <input
              type="date"
              className="bg-[#691e78] text-white ring-1 ring-white"
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <Select
              options={optionsArr}
              title="School"
              index="name"
              selected={opt}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
              ring
              ringWhite
            />
            <div className="items-center text-[#691e78] bg-white flex justify-center px-10 py-3 rounded-sm">
              Load
            </div>
          </div>
          <div className="ring-1 ring-[#691e78] rounded-xl ">
            <ParentsMgt />
          </div>
        </div>
      )}
    </>
  );
};
