import React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import Pagination from "../../pagination_one/pagination";
import {
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  // InlineFormDiv,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import { ImgTextDiv } from "../../../pages/student_mgt/stu_mgt.style";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import PropTypes from "prop-types";
import { BsSearch } from "react-icons/bs";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import Modal from "../../modal/Modal";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";

import { toast } from "react-toastify";
import {
  checkAccess,
  paginateData,
  validateEmail,
  validateMobileNumber,
} from "../../../services/utils";


const EditCredentialComponent = ({
  accountType,
  showActivateButton,
  title,
}) => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);  

  const _createObj = useMemo(
    () => ({
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      schoolId: user.schoolId,
      countryCode: "234",

    }),
    [user.schoolId]
  );

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [query, setQuery] = useState("");
  const [obj, setObj] = useState(_createObj);
  const [createObj, setCreateObj] = useState(_createObj);
  const [showModal, setShowModal] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [showCreationModal, setShowCreationModal] = useState(false);
  
  const getData = useCallback(async () => {
    setPageCount(1);
    try {
      const res = await api.getUsersCredential(
        admToken,
        user.schoolId,
        accountType
      );
      console.log("studentss: ", res);

      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [accountType, admToken, user.schoolId]);

  console.log("obj", obj)
  console.log(obj?.countryCode); 

  const getStudent = useCallback(
    async (id) => {
      try {
        const res = await api.getUserCredential(
          admToken,
          user.schoolId,
          accountType,
          id
        );
        console.log("get students", res);
      } catch (error) {
        console.log(error);
      }
    },
    [accountType, admToken, user.schoolId]
  );
  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    paginateData(
      data,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [currentPage, data, itemOffSet]);

  const [id, setId] = useState("");
  const [paramsLoading, setParamsLoading] = useState(false);

  const activateUser = useCallback(
    async (id, bool) => {
      try {
        setParamsLoading(true);
        const res =
          accountType === 1
            ? await api.activateStudent(admToken, user.schoolId, id, bool)
            : accountType === 2
            ? await api.activateParent(admToken, id, bool)
            : undefined;
        console.log(res);
        if (res.status && res.statusCode === 2) {
          getData();
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
          });
        }

        res.status === 500 && toast.error(res.data.message);
        setParamsLoading(false);
      } catch (error) {
        setParamsLoading(false);

        console.log(error);
      }
    },
    [accountType, admToken, getData, user.schoolId]
  );
  const [loadSearch, setLoadSearch] = useState(false);

  const searchFunc = useCallback(async () => {
    try {
      setPageCount(1);
      setLoadSearch(true);
      const res = await api.searchUserCredential(
        admToken,
        user.schoolId,
        accountType,
        query
      );
      console.log("search res", res);
      if (res.status === true && res.statusCode === 2) {
        if (Array.isArray(res.data) && res.data.length > 0) {
          setData(res.data);
        } else {
          toast.error("No Results Found");
        }
      }
      if (res.status === false && res.statusCode === 6) {
        toast.error(res.message);
      }
      setLoadSearch(false);
    } catch (error) {
      console.log(error);
      setLoadSearch(false);
    }
  }, [accountType, admToken, query, user.schoolId]);
  const [formLoading, setFormLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const delFunc = useCallback(
    async (id) => {
      try {
        setClearLoading(true);
        const res = await api.clearCredential(admToken, accountType, id);
        if (res.status && res.statusCode === 2) {
          getData();
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
          });
          setShowModal(false);
          setObj(null);
        }
        setClearLoading(false);

        res.status === 500 && toast.error(res.data.message);
        res.status === 400 && toast.error(res.data.message);
      } catch (error) {
        console.log(error);
        setClearLoading(false);

        toast.error("An Error Occurred");
      }
    },
    [accountType, admToken, getData]
  );
  
  const editFunc = useCallback(async () => {
    try {
      setFormLoading(true);
      const res = await api.editCredentials(admToken, obj, accountType);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        getData();
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
        setShowModal(false);
        setObj(null);
      }

      res.status === 500 && toast.error(res.data.message);
      res.status === 400 && toast.error(res.data);
      !res.status && toast.error(res.message);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  }, [accountType, admToken, getData, obj]);

  const createFunc = useCallback(async () => {
    let check1 = validateEmail(createObj.email, "Student Email");
    let check3 = validateMobileNumber(createObj.phoneNumber);
    if (!check1 || !check3) {
      return;
    }

    try {
      setFormLoading(true);
      const res =
        title === "Manage Parent Account"
          ? await api.createParent(admToken, createObj)
          : await api.createStaff(admToken, createObj);

      if (res.status && res.statusCode === 2) {
        console.log("got here 1", res);
        getData();
        console.log("got here 2", res);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });

        console.log("got here 3", res);
      }

      res.status === 500 && toast.error(res.data.message);
      res.status === 400 && toast.error(res.data);
      !res.status && toast.error(res.message);
      setShowCreationModal(false);
      setCreateObj(_createObj);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  }, [_createObj, admToken, createObj, getData, title]);

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Update Credentials</BigHeader>
      {obj !== null && (
        <div>
          <InputWrapper>
            <label htmlFor="">Email</label>
            <input
              type="email"
              name=""
              placeholder="Enter Email Address"
              id=""
              value={obj.email}
              onChange={(e) => setObj({ ...obj, email: e.target.value })}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="phone number">Phone Number</label>
            <div className="countryCode flex items-center">
              <PhoneInput
                country={"ng"}
                disableSearchIcon={true}
                inputStyle={{ display: "none" }}
                value={obj?.countryCode || "234"}
                onChange={(value, country) => {
                  setObj({ ...obj, countryCode: `+${country.dialCode}` });
                }}
              />
              <input
                className="Phone-Input rounded-l-none"
                type="email"
                name=""
                id=""
                value={obj.phoneNumber}
                onChange={(e) => setObj({ ...obj, phoneNumber: e.target.value })}
              />
            </div>           
          </InputWrapper>
          <div>
            <AuthButton
              onClick={editFunc}
              disabled={formLoading}
              width="100%"
              mt="1rem"
            >
              {formLoading ? "Please Wait" : "Edit Credential"}
            </AuthButton>
          </div>
        </div>
      )}
    </>
  );
  const createContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {title === "Manage Parent Account" ? "Create Parent" : "Create Staff"}
      </BigHeader>
      <InputWrapper>
        <label htmlFor="">Email</label>
        <input
          type="email"
          name=""
          placeholder="Enter Email Address"
          id=""
          value={createObj.email}
          onChange={(e) =>
            setCreateObj({ ...createObj, email: e.target.value })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">First Name</label>
        <input
          type="text"
          name=""
          placeholder="Enter first name"
          id=""
          value={createObj.firstName}
          onChange={(e) =>
            setCreateObj({ ...createObj, firstName: e.target.value })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Last Name</label>
        <input
          type="text"
          name=""
          placeholder="Enter last name"
          id=""
          value={createObj.lastName}
          onChange={(e) =>
            setCreateObj({ ...createObj, lastName: e.target.value })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Middle Name</label>
        <input
          type="text"
          name=""
          placeholder="Enter middle name"
          id=""
          value={createObj.middleName}
          onChange={(e) =>
            setCreateObj({ ...createObj, middleName: e.target.value })
          }
        />
      </InputWrapper>
      <InputWrapper>
            <label htmlFor="phone number">Phone Number</label>
            <div className="countryCode flex items-center">
              <PhoneInput
                country={"ng"}
                disableSearchIcon={true}
                inputStyle={{ display: "none" }}
                value={createObj?.countryCode}
                onChange={(value, country) => {
                  setCreateObj({ ...createObj, countryCode: `+${country.dialCode}` });
                }}
              />
              <input
                className="Phone-Input rounded-l-none"
                type="email"
                name=""
                id=""
                value={createObj.phoneNumber}
                onChange={(e) => setCreateObj({ ...createObj, phoneNumber: e.target.value })}
              />
            </div>           
          </InputWrapper>
      <div>
      <AuthButton
  onClick={createFunc}
  disabled={
    formLoading 
  }
  width="100%"
  mt="0.3rem"
>
  {formLoading
    ? "Please Wait"
    : title === "Manage Parent Account"
    ? "Create Parent"
    : "Create Staff"}
</AuthButton>

      </div>
    </>
  );

  const isGlobalUser = accessObj?.privileges?.some((obj) => obj.id === 1);
  return (
    <Container>
      <GlobalStyles />
      <div>
        {title.length > 0 && (
          <BigHeader
            fs="27px"
            style={{
              borderBottom: `4px solid ${colors.primary}`,
              paddingBottom: "4px",
            }}
          >
            {title}
          </BigHeader>
        )}
      </div>

      {showModal && obj && (
        <Modal content={content} size="large" onClose={setShowModal} />
      )}
      {showCreationModal && (
        <Modal
          content={createContent}
          size="large"
          onClose={setShowCreationModal}
        />
      )}
      <TabContainer style={{ paddingBottom: "0px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "10px",
            paddingRight: "15px",
          }}
        >
          {checkAccess(accessObj.rights, "Create") &&
            title !== "Manage Student Account" && (
              <AuthButton
                disabled={!isGlobalUser}
                mt="0.6rem"
                pl="0px"
                width={isTab ? "50%" : "30%"}
                onClick={() => {
                  setShowCreationModal(true);
                }}
              >
                <div>
                  {title === "Manage Parent Account" ? (
                    <span>Create New Parent</span>
                  ) : (
                    <span>Create New Staff</span>
                  )}
                </div>
              </AuthButton>
            )}
        </div>
        <TabLinkContainer bg="rgb(238, 224, 241)">
          <Input mwidth="100%">
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search With Phone Number And Email Address"
              value={query}
              onChange={(e) => {
                if (e.target.value === "") {
                  getData();
                  setQuery("");
                } else {
                  setQuery(e.target.value);
                }
              }}
            />
          </Input>
          <AuthButton
            mt={isMobile ? "0.3rem" : "0rem"}
            width={isMobile ? "100%" : "150px"}
            onClick={searchFunc}
            disabled={loadSearch || query === ""}
          >
            {loadSearch ? "Please wait..." : "Search"}
          </AuthButton>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          {loading ? (
            <Loader />
          ) : (
            <table
              style={{
                borderRadius: "0px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              <tr
                className="table-head"
                style={{ color: "black", fontWeight: "bold" }}
              >
                <th>S/N</th>

                <th>Name ({data.length})</th>
                <th>Email</th>
                {/* {accountType === 1 && */}
                <th>Status</th>
                {/* } */}
                <th></th>
                <th></th>
              </tr>
              {list.length > 0 ? (
                list.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <ImgTextDiv>
                        <div>
                          <span style={{ width: "max-content" }}>
                            {item.fullName}
                          </span>
                          <div className='flex items-center'>
                            <span className="mr-2">{item?.countryCode}</span>
                          <span style={{ display: "block" }}>
                            {item?.phoneNumber}
                          </span>
                          </div>
                        </div>
                      </ImgTextDiv>
                    </td>
                    <td>{item.email}</td>
                    {accountType === 1 && (
                      <td>
                        {item.status === "10"
                          ? "Graduated"
                          : item.status === "9"
                          ? "Exited"
                          : item.status === "8"
                          ? "Admitted"
                          : item.status === "7"
                          ? "Shortlisted"
                          : item.status === "6"
                          ? "Declined"
                          : item.status === "5"
                          ? "In Process"
                          : item.status === "4"
                          ? "Cancelled"
                          : item.status === "3"
                          ? "Submitted"
                          : item.status === "2"
                          ? "Saved"
                          : "Not Confirmed"}
                      </td>
                    )}
                    {accountType === 3 && (
                      <td>
                        {item.status === "0"
                          ? "Inactive"
                          : item.status === "1"
                          ? "Active"
                          : item.status === "2"
                          ? "Exited"
                          : item.status === "3"
                          ? "Retired"
                          : item.status === "4"
                          ? "Absconded"
                          : item.status === "5"}
                      </td>
                    )}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {checkAccess(accessObj.rights, "Edit") && (
                          <button
                            style={{
                              borderRadius: "9px",
                              border: `1px solid ${colors.primary}`,
                              padding: "0.3rem 0.6rem",
                            }}
                            onClick={() => {
                              getStudent(item.phoneNumber);
                              setObj(Object.assign({}, item));
                              setShowModal(true);
                            }}
                          >
                            Edit Credentials
                          </button>
                        )}

                        {showActivateButton &&
                          checkAccess(accessObj.rights, "Activate") && (
                            <button
                              style={{
                                backgroundColor: colors.primary,
                                color: "white",
                                marginLeft: "5px",
                                borderRadius: "9px",
                                border: `1px solid ${colors.primary}`,
                                padding: "0.3rem 0.6rem",
                              }}
                              onClick={() => {
                                setId(item.phoneNumber);
                                activateUser(
                                  item.phoneNumber,
                                  item.statusId === 1 ? false : true
                                );
                              }}
                              disabled={
                                paramsLoading ||
                                item.phoneNumber === "" ||
                                item.phoneNumber === null
                              }
                            >
                              {" "}
                              {paramsLoading && id === item.phoneNumber ? (
                                <span>Please wait...</span>
                              ) : (
                                <span>
                                  {item.statusId === 1
                                    ? `Deactivate  Account`
                                    : `Activate Account`}
                                </span>
                              )}
                            </button>
                          )}

                        {
                          // showActivateButton &&
                          checkAccess(accessObj.rights, "Clear") && (
                            <button
                              onClick={() => {
                                setId(item.credentialId);
                                delFunc(item.credentialId);
                              }}
                              disabled={
                                item.phoneNumber === "" ||
                                item.phoneNumber === null ||
                                item.email === "" ||
                                item.email === null ||
                                (clearLoading && id === item.credentialId)
                              }
                              style={{
                                backgroundColor: "red",
                                borderRadius: "9px",
                                marginLeft: "0.3rem",
                                color: "white",
                                border: `1px solid red`,
                                padding: "0.3rem 0.6rem",
                              }}
                            >
                              {clearLoading && id === item.credentialId
                                ? "Plaease wait..."
                                : "Clear"}
                            </button>
                          )
                        }
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={accountType === 1 ? 5 : 4}
                    style={{ textAlign: "center" }}
                  >
                    No {title} Available
                  </td>
                </tr>
              )}
            </table>
          )}
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            currentPage={currentPage - 1}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

EditCredentialComponent.propTypes = {
  showActivateButton: PropTypes.bool,
  title: PropTypes.string,
};

EditCredentialComponent.defaultProps = {
  showActivateButton: true,
  title: "",
};
export default EditCredentialComponent;
