import React, { useCallback, useEffect, useState } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import StudentResultPDF from "./StudentResultPDF";
import { getSchoolStampAndSignature } from "../../../services/MySchool";
import { useSelector } from "react-redux";
import TableLoader from "../../TableLoader";
import FormLoader from "../../FormLoader";


const PreviewBulkDownload = ({data}) => {

  console.log("preview", data)
  const baseUrl = process.env.REACT_APP_API_ENDPOINT_TWO;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stampSign, setStampSign] = useState(null);
  const { admToken, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);


  const getStampSign = useCallback(async () => {
      setLoading(true);
      try {
        let res = await getSchoolStampAndSignature(admToken, user.schoolId);
  
        if (res.status && res.statusCode === 2) {
          setStampSign(res.data);
        }
      } catch (err) {
        console.log(err);
        // toast.error("Error!, Try Again Later");
        console.log("something is wrong");
      } finally {
        setLoading(false);
      }
    }, [admToken, user.schoolId]);

    useEffect(() => {
        getStampSign();
      }, [getStampSign]);

  return (
    <div className="">
      <button
      disabled={data.length === 0}
        onClick={() => setIsModalOpen(true)}
        className="text-xs md:text-base px-3 md:px-6 py-2 bg-[#6A0DAD] text-white font-medium rounded-lg hover:bg-[#6A0DAD]/70 transition"
      >
        Preview & Download Results
      </button>

      {isModalOpen && (
        <div className="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg w-5/6 p-6 h-full shadow-lg">
            <div className="border border-gray-300 h-[90%] overflow-auto">
              {loading ? (
                <>
                  <TableLoader />
                  <p className="text-center mt-7">Loading ...</p>
                </>
              ) : (
                <PDFViewer width="100%" height="100%">
                  <StudentResultPDF 
                    studentData={data} 
                    stampSign={stampSign} 
                  />
                </PDFViewer>
              )}
            </div>

            <div className="flex justify-between mt-4">
              <PDFDownloadLink
                document={<StudentResultPDF studentData={data} />}
                fileName="all_students_results.pdf"
                className="px-6 py-2 bg-green-600 text-white font-medium rounded-lg hover:bg-green-700 transition"
              >
                {({ loading }) => (loading ? <FormLoader /> : "Download Results")}
              </PDFDownloadLink>

              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2 bg-red-600 text-white font-medium rounded-lg hover:bg-red-700 transition"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewBulkDownload;
