import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TableCell,
  Popover,
  MenuList,
  MenuItem,
  Modal,
  TextField,
  InputLabel,
  Grid,
  Tooltip,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";

import { RiDeleteBin6Line } from "react-icons/ri";
import Title from "../Dashboard/Title";
import LoaderLine from "../LoaderLine";
import {
  useAddTutorialFileMutation,
  useGetAllTutorialUploadedFilesQuery,
  useDeleteTutorialAddedFilesMutation,
} from "../../api";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Tutorial_Second = () => {

  const { id } = useParams();
  const naviagte = useNavigate();
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [questionId, setQuestionId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [description, setDescription] = useState();
  const [fileId, setFIleId] = useState(0);
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const { data, isLoading } = useGetAllTutorialUploadedFilesQuery({
    tutorialId: id,
  });

  const [onAddTutorialFile] = useAddTutorialFileMutation();

  const handleUploadFile = async () => {
    setLoading(true);
    const details = {
      fileName: "string",
      tutoriaId: id,
      attchedFileContent: selectedFile,
      fileDescription: description,
    };
    try {
      const res = await onAddTutorialFile(details)?.unwrap();
      if (res) {
        toast.success(res?.message);
        setDescription("");
        setLoading(false);
        setSelectedFileName("");
      }
    } catch (err) {
      toast.error("there was a error uploading questions : ", err);
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    naviagte(-1)
  }
 const handleFileChange = (event) => {
    const file = event.target.files[0]; 
    setSelectedFileName(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result; // This will contain the base64 data
        setSelectedFile(base64Data); // Set the base64 data using setSelectedFile
      };
      // Read the file as a data URL, which will give you the base64 representation
      reader.readAsDataURL(file);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const idP = open ? "simple-popover" : undefined;
  const [onDeleteFile] = useDeleteTutorialAddedFilesMutation();

  const deleteTutorialQuestion = async () => {
    setLoading(true);
    try {
      const res = await onDeleteFile({
        fileId: fileId,
      }).unwrap();
      if (res) {
        setLoading(false);
        setOpenDelete(false);
        setAnchorEl(null);
        toast.success(res.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("there was an error", error);
    }
  };

  function truncateString(str, maxLength) {
    if (str?.length <= maxLength) {
      return str;
    }

    return str.slice(0, maxLength) + "...";
  }

  return (
    <>
      {loading || (isLoading && <LoaderLine />)}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title title="Tutorial/Resources - Add Files" />
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div onClick={handleGoBack}  className="cursor-pointer">
          <IconButton
            sx={{
              bgcolor: "#671e781a",
              width: "30px",
              height: "30px",
              mr: 2,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
        <Box>
          <Typography variant="caption" sx={{ fontFamily: "gilroy-regular" }}>
            Back to Tutorial
          </Typography>
        </Box>
      </Box>

      <Grid
        sx={{
          bgcolor: "#671e781a",
          padding: "40px",
          borderRadius: "20px",
        }}
        item
        lg={12}
        xs={12}
      >
        <Box>
          <InputLabel
            sx={{
              mb: 1.5,
              mt: 1,
              fontSize: "12px",
              fontWeight: "bolder",
              color: "#000",
              fontFamily: "Gilroy-Medium",
            }}
          >
            Description
          </InputLabel>
          <TextField
            fullWidth
            multiline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #671E78",
                },
              },
            }}
            InputProps={{
              style: {
                fontFamily: "Gilroy-regular",
                fontSize: "13px",
              },
            }}
          />
        </Box>
        <Grid item lg={12} xs={12}>
          <InputLabel
            sx={{
              fontFamily: "gilroy-regular",
              color: "#333",
              fontSize: "12px",
              mb: 1,
              mt: 3,
            }}
          >
            Upload File (optional)
          </InputLabel>
          <TextField
            type="file"
            fullWidth
            onChange={handleFileChange}
            InputProps={{
              style: {
                fontFamily: "Gilroy-regular",
                fontSize: "13px",
                borderRadius: "5px",
                offset: " 0.5px solid #671E78",
                border: " 0.5px solid #671E78",
              },
            }}
          />
        </Grid>
        <Box sx={{mt: 3}}>
          <Button
            disabled={!selectedFileName || !description || loading}
            variant="contained"
            fullWidth
            component="label"
            sx={{ textTransform: "initial" }}
            onClick={handleUploadFile}
          >
            {loading ? <FormLoader /> : <span>Add File</span>}
          </Button>
        </Box>
      </Grid>
      {/* <Box sx={{ mt: 3 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 700,
              fontFamily: "Gilroy-Regular",
              border: "1px solid #671E78",
              borderRadius: "20px",
              marginBottom: "50px",
            }}
            aria-label="customized table"
          >
            <TableHead
              sx={{
                backgroundColor: "rgba(103, 30, 120, 0.1)",
                width: "100%",
              }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>File Name</StyledTableCell>
                <StyledTableCell>Upload Date</StyledTableCell>

                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {data?.data?.length === 0 ? (
                <>
                  <Box className="flex justify-center">
                    <Typography sx={{ fontFamily: "gilroy-bold" }}>
                      You do not have any uploaded questions for this tutorial
                    </Typography>
                  </Box>
                </>
              ) : (
                data?.data?.length > 0 &&
                data?.data?.map((question, index) => {
                  const date = new Date(question?.uploadDate);
                  const day = date?.getDate();
                  const month = date?.getMonth() + 1;
                  const year = date?.getFullYear();
                  const formattedDateString = `${month}/${year}/${day}`;
                  return (
                    <StyledTableRow>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title={question?.fileDescription}>
                          {question?.fileDescription
                            ? truncateString(question?.fileDescription, 30)
                            : "--No question found"}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        {formattedDateString ?? "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div onClick={() => setFIleId(question?.id)}>
                          <Button
                            sx={{ border: "1px solid #671E78" }}
                            onClick={handleOpenDelete}
                          >
                            Delete
                          </Button>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button sx={{ border: "1px solid #671E78" }}>
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Box align="center">
            <RiDeleteBin6Line style={{ fontSize: "42px", color: "#d3302f" }} />
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontFamily: "gilroy-bold" }}>
                You're about to delete Question {questionId}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
              }}
            >
              This will delete the Qustion from the database
            </Typography>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
                mt: 2,
              }}
            >
              Are you sure?
            </Typography>
          </Box>
          <Box align="right" sx={{ mt: 2 }}>
            <Button
              sx={{
                color: "#333",
                textTransform: "initial",
                fontFamily: "gilroy-regular",
              }}
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteTutorialQuestion}
              sx={{
                bgcolor: "#d3302f",
                color: "#fff",
                ml: 2,
                px: 3,
                textTransform: "initial",
                fontFamily: "gilroy-regular",
                "&:hover": {
                  bgcolor: "#d3302fb7",
                  color: "#fff",
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
