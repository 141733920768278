import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;

// Assets Management
// all Assets
export const GetAllAsset = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/AssetManagement/GetAllAsset`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
// All School Assets
export const GetSchoolAsset = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/AssetManagement/Get-School-Assets?schoolId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

// Add Asset
export const AddAsset = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/AssetManagement/AddAsset`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

// edit assets
export const EditAsset = (token, data) =>
  axios
    .put(
      `${endpoint_two}/api/v${api_version}/AssetManagement/EditAsset`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

//add asset category
export const AddAssetCategory = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/AssetManagement/AddAssetCategory`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
// edit assets category
export const EditAssetCategory = (token, data) =>
  axios
    .put(
      `${endpoint_two}/api/v${api_version}/AssetManagement/EditAssetCategory`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

    //get all assets category

export const GetAllCategory = (token) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/AssetManagement/GetAssetCategoryList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

    //get schoolCategory
export const GetSchoolCategory = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/AssetManagement/Get-School-AssetCategoryList?schoolId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

    //delete assets category
export const DeleteAssetCategory = (token, id) =>
  axios
    .delete(
      `${endpoint_two}/api/v${api_version}/AssetManagement/DeleteAssetCategory?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => err.response);

// get asset category
export const GetAssetCategory = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/AssetManagement/GetAssetCategory/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
// delete assets category

// Library Section

// add book
export const AddBook = async (token, data) => {
  let formData = new FormData();
  formData.append("SchoolId", data?.schoolId);
  formData.append("BIN", data?.bin);
  formData.append("TItle", data?.title);
  formData.append("Author", data?.author);
  formData.append("SerialNo", data?.serialNo);
  formData.append("ISBN", data?.isbn);
  formData.append("SubjectId", data?.subjectId);
  formData.append("Published", data?.published);
  formData.append("Status", data?.status);
  formData.append("CategoryId", data?.categoryId);
  formData.append("Publisher", data?.publisher);
  formData.append("PlaceOfPublication", data?.placeOfpublication);
  formData.append("Editor", data?.editor);
  formData.append("Edition", data?.edition);
  formData.append("CopywriteDate", data?.copywriteDate.toISOString());
  formData.append("Copies", data?.copies);
  formData.append("ShelfNoId", data?.shelfNoId);
  formData.append("RowNo", data?.rowNo);
  formData.append("BookClassId", data?.bookClassId);
  formData.append("VendorId", data?.vendorId);
  formData.append("CoverPageFile", data?.file);
  formData.append("CoverType", data?.coverType);
  formData.append("LibrarySectionId", data?.librarySectionId);
  console.log(formData.get("CopywriteDate"));
  console.log(formData.get("CoverPageFile"));

  try {
    const res = await axios
      .post(`${endpoint_two}/api/v${api_version}/BookSetup/AddBook`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
  // const headers = { "Content-Type": "multipart/form-data" };
};

// add library section plus lend book
export const AddBookSetupType = (token, data, type) =>
  axios
    .post(`${endpoint_two}/api/v${api_version}/BookSetup/${type}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);

//get book setup by id
export const GetBookSetupByID = (token, id, type) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/BookSetup/${type}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

//delete book setup by id
export const DelBookSetupByID = (token, id, type) =>
  axios
    .delete(`${endpoint_two}/api/v${api_version}/BookSetup/${type}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
    
// update library section plus edit history
export const PutBookSetupType = (token, data, type) =>
  axios
    .put(`${endpoint_two}/api/v${api_version}/BookSetup/${type}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);

// get booksetups plus lending history
export const GetBookSetup = (token, type) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/BookSetup/${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

// book search
export const BookSearch = (token, schoolId, title) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/BookSetup/BookSearch?schoolId=${schoolId}&Title=${title}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

// get studentInfo
export const GetStudent = (token, admissionId, studentId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/BookSetup/GetStudentInfo?AdmissionId=${admissionId}&StudentId=${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

// get book info
export const ViewBookInfo = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/BookSetup/ViewBookInfo?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

// approve decline lending
export const TreatLending = (token, id, type) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/BookSetup/${type}?LendingId=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
