import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  Skeleton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  useActivateTutorialMutation,
  useCreateTutorialMutation,
  useDeleteTutorialMutation,
  useEditTutorialMutation,
  useGetTutorialsQuery,
} from "../../api/index";
import Title from "../Dashboard/Title";
import { classList, formatDateTime } from "../../utils";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderLine from "../LoaderLine";
import FormLoader from "../FormLoader";
const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 800, xs: 400 },
  height: "600px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",
  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {},
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Tutorial_First = () => {
  const userInfo = useSelector((state) => state.auth.user);

  const [isId, setIsId] = useState();
  const [loading, setIsLoading] = useState(false);
  const [activateDeactivate, setActivateDeactivate] = useState(false);

  const {
    data: allTutorial,
    isLoading,
    refetch,
  } = useGetTutorialsQuery({
    schoolId: userInfo.schoolId,
  });

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    handleRefetch();
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const filteredTutorial = allTutorial?.data?.filter((item) => {
    return (
      item?.subject?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.description?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.className?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  console.log("tutorial", allTutorial?.data)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [openAddClass, setOpenAddClass] = useState(false);
  const [isTutorialData, setTutorialData] = React.useState();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = () => setOpenModalEdit(!openModalEdit);
  const handleOpenAddClass = () => setOpenAddClass(true);

  const handleOpenModal = () => setOpenModal(true);

  const handleActivateModal = () => setActivateDeactivate(!activateDeactivate);

  const handleCloseModal = () => setOpenModal(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [onDeleteTutorial] = useDeleteTutorialMutation();

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const res = await onDeleteTutorial({
        TutorialId: isId,
      })?.unwrap();
      if (!res?.status) {
        toast.error(res?.message, { type: "error" });
        setIsLoading(false);
      } else {
        toast.success("You have deleted a tutorial!");
        setIsLoading(false);
      }
    } catch (err) {
      toast.error("Something went wrong!", { type: "error" });
      setIsLoading(false);
    }
  };
  const [onActivate] = useActivateTutorialMutation();

  const handelActivate = async () => {
    setIsLoading(true);
    const dataParams = {
      tutorialId: isId,
      IsActivate: true,
    };
    try {
      const res = await onActivate(dataParams)?.unwrap();
      if (res) {
        toast.success("You have activated this tutorial!");
        setIsLoading(false);
      }
      console.log("res", res);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong!", { type: "error" });
      handleActivateModal();
      handleClose();
    }
  };

  const handleDeactivate = async () => {
    setIsLoading(true);
    try {
      const res = await onActivate({
        tutorialId: isId,
        IsActivate: false,
      });
      if (res) {
        toast.success("You have successfully deactivated this tutorial!");
        setIsLoading(false);
        handleActivateModal();
        handleClose();
        refetch();
      }
    } catch (err) {
      toast.error("there was an error: ", err);
      setIsLoading(false);
      handleActivateModal();
      handleClose();
    }
  };

  function truncateString(str, maxLength) {
    if (str?.length <= maxLength) {
      return str;
    }

    return str.slice(0, maxLength) + "...";
  }
  return (
    <ThemeProvider theme={theme}>
      {isLoading || (loading && <LoaderLine />)}
      <Box>
        <Box>
          <Box className="grid">
            <Box className="grid lg:flex lg:justify-between">
              <Box></Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Bold",
                    height: "50px",
                  }}
                  className="w-full md:w-[400px]"
                  onClick={handleOpenModal}
                >
                  Setup Tutorials
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            mt={5}
            sx={{
              border: "1px solid #671E78",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                borderRadius: "20px 20px 0px 0px",
                pt: 4,
                background: "rgba(103, 30, 120, 0.1)",
                height: "100px",
                pl: { xs: 2.5 },
              }}
            >
              <Search
                sx={{
                  width: {
                    lg: "345px",
                    md: "330px",
                    sm: "auto",
                    xs: "auto",
                    pl: { xs: 6 },
                  },
                }}
                value={searchInput}
                onChange={handleSearchInputChange}
              >
                <SearchIconWrapper>
                  <BsSearch />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
                aria-label="customized table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "rgba(103, 30, 120, 0.1)",
                    width: "100%",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Subject</StyledTableCell>
                    <StyledTableCell>Tutorial Title/Description</StyledTableCell>
                    <StyledTableCell>Subject Teacher</StyledTableCell>
                    <StyledTableCell>Class</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                {filteredTutorial ? (
                  <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                    {filteredTutorial?.map((row, index) => {
                      const isoDateString = row?.date;
                      const date = new Date(isoDateString);
                      const day = date?.getDate()?.toString()?.padStart(2, "0");
                      const month = (date?.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const year = date.getFullYear();
                      const formattedDate = `${day}/${month}/${year}`;
                      return (
                        <StyledTableRow
                        style={
                          index % 2
                            ? { background: "rgba(103, 30, 120, 0.1)" }
                            : { background: "white" }
                        }
                        key={index ?? 1}
                        >
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            {formattedDate ?? "--"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row?.subject ?? "--"}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title={row?.description}>
                              {truncateString(row?.title, 10)} /{" "}
                              {truncateString(row?.description, 20) ?? "--"}
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row?.subjectTeacher ?? "--"}
                          </StyledTableCell>
                            <StyledTableCell>
                              {classList(row.assignClasses)}
                            </StyledTableCell>
                          <StyledTableCell>
                            <div
                              onClick={() => {
                                setIsId(row?.tutorialId);
                                setTutorialData(row);
                              }}
                            >
                              <IconButton onClick={handleClick}>
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={10} sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 2 }}>
                    No tutorial Found! Please add some tutorial first...{" "}
                    </StyledTableCell>
                </StyledTableRow>
                )}
              </Table>
            </TableContainer>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                bgcolor: "#671E78",
                color: "#fff",
                fontFamily: "Gilroy-Regular",
                px: 3,
              }}
            >
              <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
                <Link to={`/add-tutorial/${isId}`}>
                  <MenuItem
                    onClick={handleOpenAddClass}
                    sx={{ fontFamily: "Gilroy-Regular" }}
                  >
                    Add files
                  </MenuItem>
                </Link>
                <MenuItem onClick={handleOpenModalEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                <MenuItem onClick={handleActivateModal}>Activate/Deactivate</MenuItem>
              </MenuList>
            </Box>
          </Popover>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box align="right" sx={{}}>
              <IconButton onClick={handleCloseModal}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
              >
                Setup Resources/Tutorial
              </Typography>
              <Divider />
            </Box>
            <Box p={3} sx={{ overflowY: "scroll", height: "450px" }}>
              <Setup showModal={setOpenModal} />
            </Box>
          </Box>
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={handleOpenModalEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box align="right" sx={{}}>
              <IconButton onClick={handleCloseModal}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
              >
                Update Resources / Tutorial Setup
              </Typography>
              <Divider />
            </Box>
            <Box p={3} sx={{ overflowY: "scroll", height: "450px" }}>
              <EditTutorial
                allClasses={isTutorialData}
                showModal={setOpenModalEdit}
              />
            </Box>
          </Box>
        </Modal>
        <Modal
          open={activateDeactivate}
          onClose={handleActivateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleDelete}>
            <Typography>Activation</Typography>
            <Box sx={{ mt: 2 }} className="flex gap-5 items-center">
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  ml: 2,
                  px: 3,
                  textTransform: "initial",
                  fontFamily: "gilroy-regular",
                  "&:hover": {
                    bgcolor: "#d3302f",
                    color: "#fff",
                  },
                }}
                onClick={handleDeactivate}
                disabled={
                  loading 
                }
              >
                {loading ? <FormLoader /> : <span>Deactivate</span>}
              </Button>
              <Button
                onClick={handelActivate}
                disabled={
                  loading
                }
                variant="contained"
                sx={{
                  color: "#fff",
                  ml: 2,
                  px: 3,
                  textTransform: "initial",
                  fontFamily: "gilroy-regular",
                  "&:hover": {
                    bgcolor: "green",
                    color: "#fff",
                  },
                }}
              >
                {loading ? <FormLoader /> : <span>Activate</span>}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
//setup Modal
const Data = [];
const Setup = ({ showModal }) => {
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [subjectID, setSubjectID] = useState(0);
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);
  const adtoken = localStorage.getItem("admToken");
  const [subjectLoader, setSubjectLoader] = useState(false);
  const [classLoader, setClassLoader] = useState(false);
  // const [onCreateClasswork] = useCreateClassworkMutation();
  const [onCreateTutorial] = useCreateTutorialMutation();

  const handleChange = (event) => {
    setSubjectSelected(event?.target?.value);
    // setSubjectName(event?.target?.value?.name);
  };
  const handleChange2 = (event) => {
    const { value } = event.target;
    setSelectedClass(typeof value === "string" ? value.split(",") : value);
  };
  

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    setSelectedFileName(file.name);
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result; // This will contain the base64 data
        console.log("this is the file", base64Data);
        setSelectedFile(base64Data); // Set the base64 data using setSelectedFile
      };

      // Read the file as a data URL, which will give you the base64 representation
      reader.readAsDataURL(file);
    }
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };

  const fetchSubjects = async () => {
    setSubjectLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${userInfo.schoolId}`,
        { headers }
      );
      console.log("wawu", response);
      if (response.status !== 200) {
        setSubjectLoader(false);
        // console.log("Error: Unable to fetch data", response);
        return;
      }

      const data = response.data;
      setSubjectLoader(false);
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setSubjects(data?.data); // Save the subjects data in the state
      console.log(subjects, "Walle");
    } catch (error) {
      setSubjectLoader(false);
      console.error("Error:", error.message);
    }
  };

  const fetchClasses = async () => {
    setClassLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?subjectId=${subjectID}&schoolId=${userInfo.schoolId}`,
        { headers }
      );

      console.log("Error: Unable to fetch data", response);
      if (response.status !== 200) {
        setClassLoader(false);

        return;
      }
      setClassLoader(false);
      const data = response.data;
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setClassLoader(false);
      setClasses(data?.data); // Save the subjects data in the state
    } catch (error) {
      setClassLoader(false);
      console.error("Error:", error.message);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const assignClasses = selectedClass.map(
      ({ classId, className, classArm }) => ({
        classId,
        className,
        classArm,
      })
    );

    const tutorialData = {
      schoolId: userInfo?.schoolId,
      subjectId: subjectSelected,
      classId: 0,
      resourceTitle: title,
      description: description,
      class: "",
      file: selectedFile || "",
      subject: subjectName,
      PostedBy: "staff",
      assignClasses,
    };

    console.log("tutorialData", tutorialData)
    try {
      const res = await onCreateTutorial(tutorialData)?.unwrap();
      if (!res?.status) {
        toast.error(res?.message, { type: "error" });
      } else {
        toast.success("You have successfully created a Tutorial");
        setResponseMessage("Successfully created a Tutorial");
        setLoading(false);
        showModal(false);
      }
    } catch (err) {
      toast.error("Something went wrong!", { type: "error" });
      setErrorMessage("Error creating assignment");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
    if (subjectID) {
      fetchClasses();
    }
  }, [subjectID]);


  return (
    <>
      {loading && <LoaderLine />}
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
                onClick={fetchSubjects}
              >
                Choose Subject
              </InputLabel>

              <Select
                fullWidth
                placeholder="dssss"
                size="small"
                value={subjectSelected}
                onChange={handleChange}
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                    // border: "1px solid #671E78",
                    // Replace with your desired font family
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Choose Subject</em>
                </MenuItem>
                {subjectLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {subjects.length === 0 && !subjectLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Subject Available
                    </Typography>
                  </Box>
                ) : (
                  subjects.length > 0 &&
                  subjects.map((subject) => (
                    <MenuItem
                      value={subject?.subjectId}
                      onClick={(e) => {
                        setSubjectID(subject?.subjectId);
                        setSelectedClass("");
                        setSubjectName(subject?.subjectName);
                      }}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      {subject.subjectName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Choose Class
              </InputLabel>
              <Select
                fullWidth
                placeholder="Select Class"
                size="small"
                value={selectedClass || []}
                multiple
                onChange={handleChange2}
                renderValue={(selected) =>
                  Array.isArray(selected)
                    ? selected
                        .map(
                          (val) =>
                            `${val.className} ${val.classArm}` || val.classId
                        )
                        .join(", ")
                    : ""
                }
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Select Class</em>
                </MenuItem>
                {classLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {classes.length === 0 && !classLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        mt: 2,
                        mb: 1,
                        color: "#333",
                      }}
                    >
                      No Class Found.
                    </Typography>
                  </Box>
                ) : (
                  classes.map((each, index) => (
                    <MenuItem
                      key={index}
                      value={each}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      <Checkbox
                        checked={Array.isArray(selectedClass) && selectedClass.some(
                          (selected) => selected.classId === each.classId
                        )}
                      />

                      <ListItemText
                        primary={`${each?.className} ${each?.classArm}`}
                      />
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Title
              </InputLabel>
              <TextField
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                      fontFamily: "gilroy-regular",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Time
              </InputLabel>
              <TextField
                disabled
                fullWidth
                value={formatDateTime(new Date())}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item lg={12} xs={12}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
                mt: 3,
              }}
            >
              Upload File (Optional)
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  // offset: " 0.5px solid #671E78",
                  border: "10.5pxpx solid #671E78",
                },
              }}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Button
            disabled={!description || !title}
            variant="contained"
            fullWidth
            sx={{ textTransform: "initial" }}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Box>
      </Box>
    </>
  );
};

const EditTutorial = ({ allClasses, showModal }) => {
  console.log("allClasses", allClasses)

  const [classId, setClassId] = useState(allClasses.classId);
  const [tutorialId, setTutorialId] = useState(allClasses?.tutorialId);
  const [classes, setClasses] = useState([]);
  const [subjectID, setSubjectID] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState(allClasses?.subjectId);
  const [selectedClass, setSelectedClass] = useState(allClasses?.assignClasses || []);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState(
    allClasses?.filePath
  );
  const [title, setTitle] = useState(allClasses?.title);
  const [description, setDescription] = useState(allClasses?.description);
  const [score, setScore] = useState("");
  const [subjectName, setSubjectName] = useState(allClasses?.subject);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);
  const adtoken = localStorage.getItem("admToken");
  const [subjectLoader, setSubjectLoader] = useState(false);
  const [classLoader, setClassLoader] = useState(false);

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };

  useEffect(() => {
    if (allClasses?.assignClasses) {
      setSelectedClass(allClasses?.assignClasses);
    }
  }, [allClasses?.assignClasses]);  

  const fetchClasses = async () => {
    setClassLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?subjectId=${subjectSelected}&schoolId=${userInfo.schoolId}`,
        { headers }
      );
      if (response.status !== 200) {
        setClassLoader(false);
        return;
      }
      setClassLoader(false);
      const data = response.data;
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setClassLoader(false);
      setClasses(data?.data); // Save the subjects data in the state
    } catch (error) {
      setClassLoader(false);
      console.error("Error:", error.message);
    }
  };

  const handleChange = (event) => {
    setSubjectSelected(event?.target?.value);
    // setSubjectName(event?.target?.value?.name);
  };
  const handleChange2 = (event) => {
    const value = event.target.value;
  
    setSelectedClass((prevSelected) => {
      return prevSelected.some((item) => item.classId === value[value.length - 1].classId)
        ? prevSelected.filter((item) => item.classId !== value[value.length - 1].classId)
        : [...prevSelected, value[value.length - 1]];
    });
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0]; 
    setSelectedFileName(file?.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;

        setSelectedFile(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchSubjects = async () => {
    setSubjectLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${userInfo.schoolId}`,
        { headers }
      );
      console.log("wawu", response);
      if (response.status !== 200) {
        setSubjectLoader(false);
        // console.log("Error: Unable to fetch data", response);
        return;
      }

      const data = response.data;
      setSubjectLoader(false);
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setSubjects(data?.data); // Save the subjects data in the state
      console.log(subjects, "Walle");
    } catch (error) {
      setSubjectLoader(false);
      console.error("Error:", error.message);
    }
  };

  const [onEditTutorial] = useEditTutorialMutation();
  const handleSubmit = async () => {

    const classworkEditData = {
      id: tutorialId,
      subjectId: subjectSelected || 0,
      classId: classId,
      expectedScore: score,
      description: description,
      file: selectedFile ?? "",
      schoolId: userInfo?.schoolId,
      class: allClasses?.className || '',
      subject: subjectName,
      postedBy: "staff",
      resourceTitle: title,
    };
    try {
      const res = await onEditTutorial(classworkEditData)?.unwrap();
      if (!res?.status) {
        toast.error(res?.message, { type: "error" });
      } else {
        toast.success("You have successfully updated this Tutorial");
        setResponseMessage("Successfully updated a Tutorial");
        setLoading(false);
        showModal(false);
      }
    } catch (err) {
      toast.error("Something went wrong!", { type: "error" });
      setErrorMessage("Error creating assignment");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
    if(subjectSelected !== ""){
      fetchClasses();
    }
  },[subjectSelected]);

  return (
    <>
      {loading && <LoaderLine />}
      {responseMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={true}
          autoHideDuration={300}
          // onClose={true}
        >
          <Alert
            severity="success"
            sx={{ width: "100%", fontFamily: "gilroy-regular" }}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={true}
          autoHideDuration={300}
          // onClose={true}
        >
          <Alert
            severity="error"
            sx={{ width: "100%", fontFamily: "gilroy-regular" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
                onClick={fetchSubjects}
              >
                Choose Subject
              </InputLabel>

              <Select
                fullWidth
                placeholder="dssss"
                size="small"
                value={subjectSelected}
                onChange={handleChange}
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                    // border: "1px solid #671E78",
                    // Replace with your desired font family
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Choose Subject</em>
                </MenuItem>
                {subjectLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {subjects.length === 0 && !subjectLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Subject Available
                    </Typography>
                  </Box>
                ) : (
                  subjects.length > 0 &&
                  subjects.map((subject) => (
                    <MenuItem
                      value={subject?.subjectId}
                      onClick={(e) => {
                        setSubjectID(subject?.subjectId);
                        setSelectedClass("");
                        setSubjectName(subject?.subjectName);
                      }}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      {subject.subjectName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Choose Class
              </InputLabel>
              <Select
                disabled
                fullWidth
                placeholder="Select Class"
                size="small"
                value={selectedClass}
                multiple
                onChange={handleChange2}
                renderValue={(selected) =>
                  selected.length > 0
                    ? selected
                        .map(
                          (val) =>
                            `${val?.className} ${val?.classarm}` || val?.classId
                        )
                        .join(", ")
                    : "Select Class"
                }
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Select Class</em>
                </MenuItem>
                {classLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {classes.length === 0 && !classLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        mt: 2,
                        mb: 1,
                        color: "#333",
                      }}
                    >
                      No Class Found.
                    </Typography>
                  </Box>
                ) : (
                  classes?.map((each, index) => (
                    <MenuItem
                      key={index}
                      value={each}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      <Checkbox
                        checked={Array.isArray(selectedClass) && selectedClass.some(
                          (selected) => selected.classId === each.classId
                        )}
                      />

                      <ListItemText
                        primary={`${each?.className} ${each?.classArm}`}
                      />
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Title
              </InputLabel>
              <TextField
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                      fontFamily: "gilroy-regular",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Time
              </InputLabel>
              <TextField
                disabled
                fullWidth
                value={formatDateTime(new Date())}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item lg={12} xs={12}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
                mt: 3,
              }}
            >
              Upload File (optional)
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  // offset: " 0.5px solid #671E78",
                  border: "1px solid #671E78",
                },
              }}
            />
          </Grid>
        </Grid>

        <Box mt={5}>
          <Button
            disabled={!description || !title}
            variant="contained"
            fullWidth
            sx={{ textTransform: "initial" }}
            onClick={handleSubmit}
          >
            Setup
          </Button>
        </Box>
      </Box>
    </>
  );
};
