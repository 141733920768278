import { useState, useEffect } from "react";
import { UpdateStudentScoreSingle } from "../../services/Subject";
import FormLoader from "../../components/FormLoader";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

const EditScoreModal = ({
  isOpen,
  onClose,
  title,
  student,
  caScore,
  setCaScore,
  examScore,
  setExamScore,
  user,
  subject,
  classObj,
  session,
  term,
  admToken,
  getStudentScoreList,
  currentPage,
}) => {
  const [formLoading, setFormLoading] = useState(false);
  const [total, setTotal] = useState(0);
  console.log(caScore)
  console.log(examScore)

  useEffect(() => {
    if (isOpen && student?.caexam) {
      setCaScore(student.caexam.ca || 0);
      setExamScore(student.caexam.exam || 0);
      setTotal(student.caexam.wa || 0);
    }
  }, [isOpen, student, setCaScore, setExamScore]);

  useEffect(() => {
    setTotal(Number(caScore) + Number(examScore));
  }, [caScore, examScore]);

  const handleCancel = () => {
    setCaScore('');
    setExamScore('');
    setTotal('');
    onClose();
  }

  const handleUpdateScore = async () => {
    setFormLoading(true);
    const data = {
      schoolId: user.schoolId,
      subjectId: subject.id,
      classId: classObj.id,
      sessionId: session.id,
      termId: term.id,
      studentId: student?.studentId,
      studentCAEXAM: {
        ca: Number(caScore),
        exam: Number(examScore),
        wa: Number(caScore) + Number(examScore),
        isRecordSaved: true,
      },
      isDelete: false,
    };

    try {
      const res = await UpdateStudentScoreSingle(admToken, data);
      if (res.status && res.statusCode === 2) {
        getStudentScoreList(currentPage);
        toast.success(res.message);
        handleCancel();
      } else if(res.status && res.statusCode === 0) {
        toast.error(res.message)
      } else {
        toast.error(res.message)
      }
    } catch (error) {
      console.error("Error updating score", error);
      toast.error("Error updating score", error);

    } finally {
      setFormLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
      <div className="w-full mx-6 md:mx-0 max-w-[600px] bg-white shadow-lg rounded-md border border-[#891e78] p-8">
        <div className="flex justify-end">
          <IconButton onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </div>
        <h1 className="text-2xl font-bold text-center mb-5 mt-2">
          {title} Student Score
        </h1>

        <p className="text-base font-medium mb-4">
          Name: <span className="font-semibold ml-2">{student?.fullName}</span>
        </p>

        <div className="space-y-4">
          <div>
            <label className="text-base font-semibold">CA:</label>
            <input
              className="px-5 py-1 border border-black outline-none ring-0 w-full"
              type="number"
              value={caScore}
              onChange={(e) => setCaScore(e.target.value)}
            />
          </div>
          <div>
            <label className="text-base font-semibold">Exam:</label>
            <input
              className="px-5 py-1 border border-black/80 outline-none ring-0 w-full"
              type="number"
              value={examScore}
              onChange={(e) => setExamScore(e.target.value)}
            />
          </div>

          <p className="text-base font-bold my-4">Total: {total}</p>

          <div className="flex items-center justify-center gap-x-6 my-7">
            <button
              className="text-[#691e78] border border-[#691e78] px-6 py-2 text-center rounded-md w-full"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="text-white border border-[#691e78] bg-[#691e78] px-6 py-2 text-center rounded-md hover:bg-[#691e78]/80 w-full"
              onClick={handleUpdateScore}
              disabled={formLoading}
            >
              {formLoading ? <FormLoader /> : <span>Update</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditScoreModal;
