import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
// import logger from "redux-logger";
import authReducer from "../features/auth/authSlice";
import { classworkApi } from "../api/index";
import { AssignmentApi } from "../api/assigmentAPi";
import { lessonNoteApi } from "../api/lessonNoteApi";
import { reportApi } from "../api/reportAPI";

const store = configureStore({
  reducer: {
    auth: authReducer,
    [classworkApi.reducerPath]: classworkApi.reducer,
    [AssignmentApi.reducerPath]: AssignmentApi.reducer,
    [lessonNoteApi.reducerPath]: lessonNoteApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      classworkApi.middleware,
      AssignmentApi.middleware,
      lessonNoteApi.middleware,
      reportApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export default store;
