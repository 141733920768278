import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const RejectedModal = ({ isOpen, onClose, record }) => {

    if (!isOpen) return null;
    if (!record) return null;

    const comment = record?.comment

    return (
        <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
            <div className="w-full mx-6 md:mx-0 max-w-[400px] bg-white shadow-lg rounded-md border border-[#891e78] pt-2 px-4 pb-4 overflow-y-auto">
                <div className="flex justify-end">
                    <IconButton onClick={onClose}>
                        <CancelIcon />
                    </IconButton>
                </div>
                    <p className="text-sm text-black/80">
                        {comment}
                    </p>
            </div>
        </div>
    );
};

export default RejectedModal;
