const api_version = process.env.REACT_APP_API_VERSION;
const baseUrl = process.env.REACT_APP_API_ENDPOINT_TWO;
export const API = {
  BASE_URL: baseUrl,
  ALL_CLASSWORK: `/api/v${api_version}/ClassWork/AllClasswork`,
  ASSIGN_CLASSWORK: `/api/v${api_version}/ClassWork/AssignClasswork`,
  VIEW_SUBMITTED_CLASSWORK: `/api/v${api_version}/ClassWork/ViewSubmittedAnswers`,
  CREATE_CLASSWORK: `/api/v${api_version}/ClassWork/CreateClasswork`,
  EDIT_CLASSWORK: `/api/v${api_version}/ClassWork/EditClasswork`,
  DELETE_CLASSWORK: `/api/v${api_version}/ClassWork/DeleteClasswork`,
  GET_CLASS_LIST: `/api/v${api_version}/CBT/GetClasses`,
  GET_ALL_CLASS: `/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses`,
  GET_SUBJECTS: `/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects`,
  CREATE_TUTORIAL: `/api/v${api_version}/TutorialResource/AddTutorial`,
  EDIT_TUTORIAL: `/api/v${api_version}/TutorialResource/Edit-Tutorial`,
  GET_TUTORIALS: `/api/v${api_version}/TutorialResource/Class-Tutorials`,
  DELETE_TUTORIAL: `/api/v${api_version}/TutorialResource/DeleteTutorial`,
  DELETE_TUTORIAL_ADDED_FILE: `/api/v${api_version}/TutorialResource/DeleteAddedFile`,
  ACTIVATE_DEACTIVATE: `/api/v${api_version}/ClassWork/ActivateORDeactivateWork`,
  ADD_TUTORIAL_FILE: `/api/v${api_version}/TutorialResource/AddFile`,
  ALL_TUTORIAL_FILES: `/api/v${api_version}/TutorialResource/GetUploadedFiles`,
  ACTIVATE_TUTORIAL: `/api/v${api_version}/TutorialResource/ActivateOrDeactivateTutorial`,
  ALL_SUBMITTED_CLASSWORK: `/api/v${api_version}/ClassWork/GetSubmittedClassworks`,
  ALL_CLASSWORK_QUESTION: `api/v${api_version}/ClassWork/AllClassworkQuestions`,
  ADD_CLASSWORK_QUESTION: `/api/v${api_version}/ClassWork/AddClassWorkQuestion`,
  UPDATE_CLASSWORK_QUESTION: `/api/v${api_version}/ClassWork/UpdateClassWorkQuestion`,
  DELETE_CLASSWORK_QUESTION: `/api/v${api_version}/ClassWork/DeleteQuestion`,
  GRADE_CLASSWORK: `/api/v${api_version}/ClassWork/GradeClassWork`,
  GET_COMMENTS: `/api/v${api_version}/ClassWork/ClassworkComments`,
  SEND_COMMENTS: `/api/v${api_version}/ClassWork/Save-comment`,
  GET_ASSIGNMENTS: `/api/v${api_version}/Assignment/AllAssignments`,
  DELETE_ASSIGNMENT: `/api/v${api_version}/Assignment/DeleteAssignment`,

  SUBMIT_LESSON_NOTE: `/api/v${api_version}/LessonNotes`,
  ALL_LESSON_NOTES: `/api/v${api_version}/LessonNotes`,
  LESSON_NOTES_HOD_TEACHERS: `/api/v${api_version}/LessonNotes/HOD/Teachers`,
  LESSON_NOTES_REPORT: `/api/v${api_version}/LessonNotes/Report`,
  LESSON_NOTES_STAFF: `/api/v${api_version}/LessonNotes/Staff`,
  GET_EACH_LESSON_NOTES: `/api/v${api_version}/LessonNotes`,
  UPDATE_LESSON_NOTES: `/api/v${api_version}/LessonNotes`,
  DELETE_LESSON_NOTES: `/api/v${api_version}/LessonNotes`,
  UPLOAD_ATTACHMENT: `/api/v${api_version}/LessonNotes/Upload2`,
  ENDORSE_LESSON_NOTE: `/api/v${api_version}/LessonNotes`,
  REJECT_LESSON_NOTE: `/api/v${api_version}/LessonNotes`,
  
  LESSON_NOTES_SESSIONS: `/api/v${api_version}/Utilities/session`,
  LESSON_NOTES_SUBJECTS: `/api/v${api_version}/Utilities/subjects`,
  LESSON_NOTES_WEEKS: `/api/v${api_version}/Utilities/weeks`,
  LESSON_NOTES_TERMS: `/api/v${api_version}/Utilities/term`,
  LESSON_NOTES_CLASSES: `/api/v${api_version}/Utilities/classes`,
  ENDORSEMENT_TEACHERS_HOD: `/api/v${api_version}/Utilities/get-teachers-under-hod`,
  ENDORSEMENT_SUBJECT: `/api/v${api_version}/Utilities/subjects`,
  ENDORSEMENT_CLASS: `/api/v${api_version}/Utilities/classes`,
  ENDORSEMENT_WEEKS: `/api/v${api_version}/Utilities/submitted-lesson-note-week-number`,


  ALL_REPORTS: `/api/v${api_version}/StaffReports`,
  REPORT_TYPE: `/api/v${api_version}/Utilities/staff-roles`,
  REPORT_TYPES: `/api/v${api_version}/Utilities/report-types`,
  POST_REPORT: `/api/v${api_version}/StaffReports`,
  EDIT_REPORT: `/api/v${api_version}/StaffReports`,
  SUBMIT_REPORT: `/api/v${api_version}/StaffReports`,
  DELETE_REPORT: `/api/v${api_version}/StaffReports`,
  EACH_REPORT: `/api/v${api_version}/StaffReports`,
  UPLOAD_REPORT: `/api/v${api_version}/StaffReports/Upload`,
  SUBMITTED_REPORT: `/api/v${api_version}/StaffReports/submitted`,
  REJECT_REPORT: `/api/v${api_version}/StaffReports`,
  ENDORSE_REPORT: `/api/v${api_version}/StaffReports`,
  ALL_ENDORSE_REPORT: `/api/v${api_version}/StaffReports/Endorsed`,
  
};
