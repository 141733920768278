import { IconButton } from '@mui/material'
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from 'react';
import { toast } from 'react-toastify';
import api from "../../../services/ControlPanel";
import { useSelector } from 'react-redux';
import FormLoader from '../../FormLoader';

const CreateDepartment = ({ isOpen, onClose, refreshData }) => {
    const { admToken } = useSelector((state) => state.auth);
    const [formLoading, setFormLoading] = useState(false);
    const [department, setDepartment] = useState('')

    const handleCreate = async () => {
        setFormLoading(true)
        const data = { departmentName : department}
        try {
              const res = await api.createDepartment(admToken, data);
              console.log("res", res)
              if (res.status == true && res.statusCode === 2) {
                refreshData();
                toast.success(res.message);
                onClose();
              }
        } catch (error) {
            console.error("Error creating department", error);
            toast.error("Error creating department", error);
        } finally {
            setFormLoading(false);
        }
    }

  return (
    <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
    <div className="w-full mx-6 md:mx-0 max-w-[600px] bg-white shadow-lg rounded-md border border-[#891e78] px-8 pt-8 pb-12">
      <div className="flex justify-end">
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>
      <h1 className="text-2xl font-bold text-center mb-5">Create a Department</h1>

      <div className="space-y-4">
        <div>
          <label className="text-base font-semibold">Name</label>
          <input
          className="px-5 py-1 border border-black outline-none ring-0 w-full"
          type="text" 
          value={department} onChange={(e) => setDepartment(e.target.value)}
        />
        </div>

        <button 
        className="text-white border border-[#691e78] bg-[#691e78] px-6 py-2 text-center rounded-md hover:bg-[#691e78]/80 w-full"   onClick={handleCreate}
        disabled={formLoading}
        >
        {formLoading ? (
            <FormLoader />
            ) : (
            <span>Create</span>
        )}
        </button>
      </div>
    </div>
  </div>
  )
}

export default CreateDepartment

export const EditDepartment = ({ isOpen, onClose, refreshData, departmentInfor }) => {
    const { admToken } = useSelector((state) => state.auth);
    const [formLoading, setFormLoading] = useState(false);
    const [department, setDepartment] = useState(departmentInfor?.name)

    const handleUpdate = async () => {
        setFormLoading(true)
        try {
              const res = await api.editDepartment(admToken, {
                Id: departmentInfor.id, departmentName: department
              });
              console.log("res", res)
              if (res.status == true && res.statusCode === 2) {
                refreshData()
                toast.success(res.message);
                onClose();
              }
        } catch (error) {
            console.error("Error updating department", error);
            toast.error("Error updating department", error);
        } finally {
            setFormLoading(false);
        }
    }

  return (
    <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
    <div className="w-full mx-6 md:mx-0 max-w-[600px] bg-white shadow-lg rounded-md border border-[#891e78] px-8 pt-8 pb-12">
      <div className="flex justify-end">
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>
      <h1 className="text-2xl font-bold text-center mb-5">Update Department</h1>

      <div className="space-y-4">
        <div>
          <label className="text-base font-semibold">Name</label>
          <input
          className="px-5 py-1 border border-black outline-none ring-0 w-full"
          type="text" 
          value={department} onChange={(e) => setDepartment(e.target.value)}
        />
        </div>

        <button 
        className="text-white border border-[#691e78] bg-[#691e78] px-6 py-2 text-center rounded-md hover:bg-[#691e78]/80 w-full"   onClick={handleUpdate}
        disabled={formLoading}
        >
        {formLoading ? (
            <FormLoader />
            ) : (
            <span>Update</span>
        )}
        </button>
      </div>
    </div>
  </div>
  )
}

