/* eslint-disable eqeqeq */
import { useState, useCallback, useEffect } from "react";
import {
  AccountSummaryWrapper,
  Container,
  InlineLabelGroup,
  InlineLabelWrapper,
  TabContainer,
  BigHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import colors from "../../../assets/colors.json";
import { getAllClasses, getSessions } from "../../../services/Subject";
import api from "../../../services/ControlPanel";
import ErrorService from "../../../services/errorService";
import { useSelector } from "react-redux";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import Select from "../../select/select";
import Loader from "../../pageLoader/loader";
import { toast } from "react-toastify";
import { checkAccess } from "../../../services/utils";
import { IoRefreshOutline } from "react-icons/io5";
import { RxOpenInNewWindow } from "react-icons/rx";
import Modal from "../../modal/Modal";
import Pagination from "@mui/material/Pagination";

const ResultSummary = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loadClass, setLoadClass] = useState(false);
  const [allData, setAllData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [pubLoading, setPubLoading] = useState(false);
  const [sumId, setSumId] = useState("");
  const [openSummaryDetail, setOpenSummaryDetail] = useState(false);
  const [creLoading, setCreLoading] = useState(false);
  const [modalObj, setModalObj] = useState({
    className: "",
    session: "",
    term: "",
    classId: "",
  });
  const [studentList, setStudentList] = useState([]);
  const [studentsLoading, setStudentLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [paginatedData, setPaginatedData] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("");

  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);
      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getClasses = useCallback(async () => {
    try {
      setLoadClass(true);
      const res = await getAllClasses(admToken, user.schoolId);
      res.status && res.statusCode === 2 && setClasses(res.data);
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setLoadClass(false);
    } catch (error) {
      console.log(error);
      setLoadClass(false);
    }
  }, [admToken, user]);

  useEffect(() => {
    schoolSessions();
    getClasses();
  }, [schoolSessions, getClasses]);

  const getResults = useCallback(
    async (sessionId, classId) => {
      setLoading(true);
      try {
        const res = await api.getResultSummaries(
          admToken,
          user.schoolId,
          sessionId,
          classId
        );

        if (res.status === true && res.statusCode === 2) {
          setAllData(res.data);
        }

        if (res.status === false && res.statusCode === 6) {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [admToken, user.schoolId]
  );

  const lockFunc = useCallback(
    async (id, bool) => {
      setLockLoading(true);
      try {
        const res = await api.LockResult(admToken, id, bool);

        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          getResults(session.id, classObj.id);
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
        setLockLoading(false);
      } catch (error) {
        toast.error("An error occurred");
        setLockLoading(false);
      }
    },
    [admToken, classObj.id, getResults, session.id]
  );

  const generate = useCallback(
    async (item) => {
      setGenerateLoading(true);
      let data = {
        sessionId: session.id,
        currentTermId: item.termId,
        classId: item.classId,
        summaryId: item.summaryId,
        schoolId: item.schoolId,
      };
      // console.log("sds", data);
      try {
        const res = await api.GenerateResult(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An error occurred");
      } finally {
        setGenerateLoading(false);
      }
    },
    [admToken,  session.id]
  );

  const reverse = useCallback(
    async (item) => {
      setGenerateLoading(true);
      let data = {
        sessionId: session.id,
        currentTermId: item.termId,
        classId: item.classId,
        summaryId: item.summaryId,
      };
      // console.log("sds", data);
      try {
        const res = await api.ReverseResult(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          getResults(session.id, classObj.id);
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An error occurred");
      } finally {
        setGenerateLoading(false);
      }
    },
    [admToken, classObj.id, getResults, session.id]
  );

  const pubFunc = useCallback(
    async (id, bool) => {
      setPubLoading(true);
      try {
        const res = await api.publishResult(admToken, id, bool);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          if (session.id && classObj.id) {
            getResults(session.id, classObj.id);
          }
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
        setPubLoading(false);
      } catch (error) {
        toast.error("An error occurred");
        setPubLoading(false);
      }
    },
    [admToken, classObj.id, getResults, session.id]
  );

  const getTermStudentList = useCallback(
    async (classId, termId, sessionId) => {
      try {
        setStudentLoading(true);
        const res = await api.getSingleResultStudents(
          admToken,
          user.schoolId,
          classId,
          termId,
          sessionId
        );

        if (res.status === true && res.statusCode === 2) {
          setStudentList(res.data);
          setCurrentPage(1);
        }

        if (res.status === false && res.statusCode === 6) {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setStudentLoading(false);
      }
    },
    [admToken, user.schoolId]
  );

  const generateSingle = useCallback(
    async (item) => {
      setGenerateLoading(true);
      let data = {
        classId: modalObj.classId,
        schoolId: user.schoolId,
        studentId: item.studentId,
        sessionId: item.sessionId,
        currentTermId: item.termId,
        summaryId: sumId,
        staffId: user.staffId,
      };
      try {
        const res = await api.GenerateSingleResult(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          getTermStudentList(modalObj.classId, item.termId, item.sessionId);
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An error occurred");
      } finally {
        setGenerateLoading(false);
      }
    },
    [admToken, modalObj.classId, getTermStudentList, sumId, user.schoolId, user.staffId]
  );

  const reverseSingle = useCallback(
    async (item) => {
      setGenerateLoading(true);
      let data = {
        classId: modalObj.classId,
        schoolId: user.schoolId,
        studentId: item.studentId,
        sessionId: item.sessionId,
        currentTermId: item.termId,
        summaryId: sumId,
        staffId: user.staffId,
      };
      try {
        const res = await api.ReverseSingleResult(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          getTermStudentList(modalObj.classId, item.termId, item.sessionId);
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An error occurred");
      } finally {
        setGenerateLoading(false);
      }
    },
    [
      admToken,
      getTermStudentList,
      modalObj.classId,
      sumId,
      user.schoolId,
      user.staffId,
    ]
  );

  const createResultSummaryFunc = useCallback(async () => {
    try {
      setCreLoading(true);
      let data = { sessionId: session.id, schoolId: user.schoolId };
      const res = await api.createResultSummary(admToken, data);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        getResults(session.id, classObj.id);
      }
      if (res.status === 400 || res.status === 500 || res.status === false) {
        ErrorService.displayErrorAlert(res);
      }
      setCreLoading(false);
    } catch (error) {
      setCreLoading(false);

      toast.error("An error occurred");
    }
  }, [admToken, classObj.id, getResults, session.id, user.schoolId]);

  const isGlobalUser = accessObj?.privileges?.some((obj) => obj.id === 1);

  const paginateData = useCallback(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPaginatedData(studentList.slice(start, end));
  }, [currentPage, itemsPerPage, studentList]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    paginateData();
  }, [currentPage, paginateData]);

  const summaryDetailContent = (
    <section className="overflow-x-scroll">
      <BigHeader style={{ textAlign: "center", marginTop: "20px" }} fs="16px">
        Summary detail of {modalObj.className} {modalObj.session}{" "}
        {modalObj.term}
      </BigHeader>
      {studentsLoading ? (
        <Loader />
      ) : (
        <section className="overflow-x-scroll">
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Admission No</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                    <td>{item.admissionNo ? item.admissionNo : "N/A"}</td>
                    <td>{item.fullName}</td>
                    <td>
                      <button
                        disabled={
                          generateLoading ||
                          item.status === "1" ||
                          !isGlobalUser
                        }
                        onClick={() => {
                          setSelectedStudentId(item.studentId);
                          item.status === 1 && reverseSingle(item);
                          item.status === 0 && generateSingle(item);
                        }}
                        className={`${
                          item.status === 0 ? "bg-[green]" : "bg-[#671e78]"
                        } text-white`}
                        style={{
                          borderRadius: "9px",
                          border: `1px solid`,
                          padding: "0.3rem 0.6rem",
                        }}
                      >
                        {item.studentId === selectedStudentId &&
                        generateLoading ? (
                          <p className="text-xs">Please wait...</p>
                        ) : item.status === 0 ? (
                          "Generate"
                        ) : (
                          "Reverse"
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} style={{ textAlign: "center" }}>
                    No Result Summary At The Moment
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {studentList.length >= 1 && (
            <Pagination
              count={Math.ceil(studentList.length / 10)}
              page={currentPage}
              onChange={handleChange}
            />
          )}
        </section>
      )}
    </section>
  );

  return (
    <Container>
      {openSummaryDetail && (
        <Modal
          show={openSummaryDetail}
          content={summaryDetailContent}
          size="large"
          onClose={setOpenSummaryDetail}
        />
      )}
      <InlineLabelGroup>
        <InlineLabelWrapper flex={2}>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              {" "}
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions}
                  setSelected={(val) => {
                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });
                    // getClasses(val.id);
                    setAllData([]);

                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });
                    if (classObj.name.length > 0) {
                      getResults(val.id, classObj.id);
                    }
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadClass ? "Loading" : "Class"}
                  index="className" // no
                  selected={classObj.id} // false
                  options={classes}
                  thirdOption="classId"
                  // extraTitle="classArm"
                  setSelected={(val) => {
                    console.log(val);
                    setAllData([]);
                    setClassObj({
                      ...classObj,
                      id: val.classId,
                      name: `${val.className} ${val.classArm}`,
                    });
                    if (session.name.length > 0) {
                      getResults(session.id, val.classId);
                    }
                    // getStudents(val.classId);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </InlineLabelWrapper>
      </InlineLabelGroup>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {allData.length < 3 &&
          session.id &&
          classObj.id &&
          !loading &&
          checkAccess(accessObj.rights, "Create") && (
            <AuthButton
              mt="0.3rem"
              disabled={creLoading}
              onClick={() => createResultSummaryFunc()}
            >
              {creLoading ? "Please wait..." : "Create Result"}
            </AuthButton>
          )}
      </div>
      <TabContainer style={{ paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          {loading ? (
            <Loader />
          ) : (
            <table
              style={{
                borderRadius: "20px",
                // borderBottomLeftRadius: "20px",
                // borderBottomRightRadius: "20px",
              }}
            >
              <tr
                className="table-head"
                style={{ color: "black", fontWeight: "bold" }}
              >
                <th>S/N</th>
                <th>Class</th>
                <th>Session</th>
                <th>Term</th>
                <th></th>
                <th className="flex justify-end mr-10">
                  <button
                    // disabled={!session.id || classObj.id}
                    className={`p-2 rounded ${
                      loading ? "animate-spin" : ""
                    } border border-[${colors.primary}]`}
                    onClick={() => {
                      getResults(session.id, classObj.id);
                    }}
                  >
                    <IoRefreshOutline className="w-[3rem] h-7" />
                  </button>
                </th>
              </tr>
              {allData.length > 0 ? (
                allData.map((item, index) => {
                  // console.log("lk", item.lockR, "gr", item.gr);

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.className}</td>
                      <td>{item.session}</td>
                      <td>{item.term}</td>
                      <td style={{ paddingRight: "0.5rem" }}>
                        <div className="flex  flex-col md:flex-row justify-center items-start md:items-center gap-2">
                          <button
                            className="w-full"
                            disabled={
                              (lockLoading && sumId == item.summaryId) ||
                              item.gr === 1 ||
                              (item.lockR === 1 && !isGlobalUser)
                            }
                            onClick={() => {
                              setSumId(item.summaryId);
                              lockFunc(
                                item.summaryId,
                                item.lockR === 0 ? true : false
                              );
                            }}
                            style={{
                              borderRadius: "9px",
                              border: `1px solid ${colors.primary}`,
                              padding: "0.3rem 0.6rem",
                              color: colors.primary,
                            }}
                          >
                            {sumId === item.summaryId && lockLoading
                              ? "Please wait..."
                              : item.lockR === 1
                              ? "Unlock Result"
                              : "Lock Result"}
                          </button>
                          <button
                            disabled={
                              generateLoading ||
                              item.lockR === 0 ||
                              item.status === "1" ||
                              item.gr === 2 ||
                              (item.gr === 1 && !isGlobalUser)
                            }
                            // onClick={() => {
                            //   setSumId(item.summaryId);
                            //   item.gr === 1 ? reverse(item) : generate(item);
                            // }}
                            onClick={() => {
                              setSumId(item.summaryId);
                              item.gr === 1 ? reverse(item) : generate(item);
                              // Initiate getResults after 1 min
                              setTimeout(() => {
                                getResults(session.id, classObj.id);
                                console.log("here after 10 with", item);
                              }, 100000);
                            }}
                            className="bg-[green] text-white w-full"
                            style={{
                              borderRadius: "9px",
                              border: `1px solid green`,
                              padding: "0.3rem 0.6rem",
                            }}
                          >
                            {sumId === item.summaryId && generateLoading ? (
                              <p className="text-xs">Please wait...</p>
                            ) : item.gr === 1 ? (
                              "Reverse"
                            ) : item.gr === 2 ? (
                              <p className="text-xs">In progress...</p>
                            ) : (
                              "Generate"
                            )}
                          </button>
                          <button
                            className="w-full"
                            onClick={() => {
                              setSumId(item.summaryId);
                              pubFunc(
                                item.summaryId,
                                item.status == "0" ? true : false
                              );
                            }}
                            disabled={
                              (pubLoading && sumId == item.summaryId) ||
                              item.gr === 0 ||
                              item.lockR === 0 ||
                              item.gr === 2 ||
                              (item.status === "1" && !isGlobalUser)
                            }
                            style={{
                              backgroundColor: colors.primary,
                              color: "white",
                              // marginLeft: "5px",
                              borderRadius: "9px",
                              border: `1px solid ${colors.primary}`,
                              padding: "0.3rem 0.6rem",
                            }}
                          >
                            {sumId == item.summaryId && pubLoading
                              ? "Please wait..."
                              : item.status == "0"
                              ? "Publish Result"
                              : "Retrieve Result"}
                          </button>
                        </div>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            getTermStudentList(
                              item.classId,
                              item.termId,
                              item.sessionId
                            );
                            setSumId(item.summaryId);
                            setModalObj({
                              session: item.session,
                              term: item.term,
                              className: item.className,
                              classId: item.classId,
                            });
                            setOpenSummaryDetail(true);
                          }}
                        >
                          <RxOpenInNewWindow className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No Result Summary At The Moment
                  </td>
                </tr>
              )}
            </table>
          )}
        </AccountSummaryWrapper>
      </TabContainer>
    </Container>
  );
};

export default ResultSummary;
