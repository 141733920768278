import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getAllClasses,
  createNewClass,
  assignClassToTeacher,
  updateClass,
} from "../../../services/Subject";
import { checkAccess, paginateData } from "../../../services/utils";
import Pagination from "../../pagination_one/pagination";
import {
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  Input,
  MenuDiv,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import ErrorService from "../../../services/errorService";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import Select from "../../select/select";
import Modal from "../../modal/Modal";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";
import FormLoader from "../../FormLoader";
import { toast } from "react-toastify";
import { deleteClass } from "../../../services/MySchool";
const Class = () => {
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [openAssign, setOpenAssign] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const filterStaff = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      data.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setList(arr);
    },
    [data]
  );
  const getAllStaff = useCallback(async () => {
    try {
      const res = await api.getUsersCredential(admToken, user.schoolId, 3);
      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  const getClassesFunc = useCallback(async () => {
    try {
      const res = await getAllClasses(admToken, user.schoolId);
      setAllData(res.data);
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    getClassesFunc();
    getAllStaff();
  }, [getAllStaff, getClassesFunc]);
  
  const filterSearch = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );
  const [sQuery, setSQuery] = useState("");
  const resetData = () => {
    setCurrentPage(1);
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };
  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);
  const [showModal, setShowModal] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  let _obj = {
    schoolId: user.schoolId,
    cname: "",
    classNameShort: "",
    carm: "",
    classTeacherStaffId: 0,
    sortorder: 0,
    cat: "",
    reportcard: "",
    midterm: "",
    jsce_ssce: "",
  };
  const [formObj, setFormObj] = useState(_obj);
  const [formLoading, setFormLoading] = useState(false);
  const [staffQuery, setStaffQuery] = useState("");
  const [assignLoading, setAssignLoading] = useState(false);
  const [staffId, setStaffId] = useState("");
  const [id, setId] = useState("");
  const assignClassFunc = useCallback(
    async (classId, id, type) => {
      try {
        setAssignLoading(true);
        const res = await assignClassToTeacher(admToken, classId, id, type);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setOpenAssign(false);

          setCurrentPage(1);
          getClassesFunc();
        }
        setAssignLoading(false);
      } catch (error) {
        toast.error("Error Assigning Class To Teacher");
      }
    },
    [admToken, getClassesFunc]
  );

  const updateCreateFunc = useCallback(async () => {
    try {
      setFormLoading(true);
      const res = formObj.classId
        ? await updateClass(admToken, formObj)
        : await createNewClass(admToken, formObj);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setShowModal(false);
        setCurrentPage(1);
        getClassesFunc();
      }
      setFormLoading(false);

      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      !res.status && ErrorService.displayErrorAlert(res);
    } catch (error) {
      toast.error("Error Creating Class, Please Try Again Later");
      setFormLoading(false);
    }
  }, [admToken, formObj, getClassesFunc]);

  const delAction = async (id) => {
    try {
      setFormLoading(true);
      const res = await deleteClass(admToken, id);
      setFormLoading(false);

      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        getClassesFunc();
      !res.status && toast.error(res.message);
      res.status === 400 && ErrorService.displayErrorAlert(res);

      console.log(res);
    } catch (error) {
      setFormLoading(false);
    }
  };

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="13px">
        Assign Staff To {classObj.name}
      </BigHeader>
      <InputWrapper>
        <input
          type="text"
          placeholder="Search For Staff"
          onChange={(e) => {
            filterStaff(e);
            setStaffQuery(e.target.value);
          }}
          value={staffQuery}
        />
      </InputWrapper>
      <MenuDiv style={{ padding: isTab ? "0.6rem" : "0.8rem" }}>
        {list.length > 0 &&
          list.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{item.fullName}</div>
              <button
                disabled={staffId === item.credentialId && assignLoading}
                style={{
                  backgroundColor: colors.primary,
                  color: "white",
                  borderRadius: "20px",
                  padding: "0.3rem 0.6rem",
                }}
                onClick={() => {
                  setStaffId(item.credentialId);
                  assignClassFunc(classObj.id, item.credentialId, "Assign");
                }}
              >
                {staffId === item.credentialId && assignLoading
                  ? "Please wait..."
                  : "Assign"}
              </button>
            </div>
          ))}
      </MenuDiv>
    </>
  );
  const createContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {formObj.classId ? `Update ${formObj.cname} ` : "Create A New "}
        Class
      </BigHeader>

      <InputWrapper>
        <label htmlFor="">Class Name</label>
        <input
          type="text"
          placeholder="Enter Class Name"
          onChange={(e) => setFormObj({ ...formObj, cname: e.target.value })}
          value={formObj.cname}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Class Arm</label>
        <input
          type="text"
          placeholder="Enter Class Arm"
          onChange={(e) => setFormObj({ ...formObj, carm: e.target.value })}
          value={formObj.carm}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Class Short Name</label>
        <input
          type="text"
          placeholder="Enter Class Name"
          onChange={(e) =>
            setFormObj({ ...formObj, classNameShort: e.target.value })
          }
          value={formObj.classNameShort}
        />
      </InputWrapper>

      <InputWrapper>
        <label htmlFor="">Class Category</label>

        <Select
          title={"Choose Class Category"}
          index="name"
          thirdOption={"val"}
          selected={formObj.cat}
          options={[
            { id: 1, name: "JSS", val: "J" },
            { id: 2, name: "SSS", val: "S" },
            { id: 4, val: "K", name: "KG" },
            { id: 5, val: "N", name: "NUR" },
            { id: 6, val: "P", name: "PRY" },
          ]}
          setSelected={(val) => {
            setFormObj({ ...formObj, cat: val.val });
          }}
        />
      </InputWrapper>
      <div>
        <AuthButton
          onClick={() => updateCreateFunc()}
          width="100%"
          mt="0.6rem"
          disabled={
            formObj.carm === "" ||
            formObj.cat === "" ||
            formObj.classNameShort === "" ||
            formObj.cname === "" ||
            formLoading
          }
        >
          {formLoading ? (
            <FormLoader />
          ) : (
            <>
              {formObj.classId ? `Update ${formObj.cname} ` : "Create A New "}
              Class
            </>
          )}
        </AuthButton>
      </div>
    </>
  );

  if (pageLoading) {
    return <Loader />;
  }

  const isGlobalUser = accessObj?.privileges?.some((obj) => obj.id === 1);

  return (
    <Container>
      <GlobalStyles />
      {openAssign && (
        <Modal content={content} size="large" onClose={setOpenAssign} />
      )}
      {showModal && (
        <Modal content={createContent} size="large" onClose={setShowModal} />
      )}
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          School Classes
        </BigHeader>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        {checkAccess(accessObj.rights, "Create") && (
          <AuthButton
            disabled={!isGlobalUser}
            mt="0.6rem"
            pl="0px"
            width={isTab ? "50%" : "30%"}
            onClick={() => {
              setFormObj(Object.assign({}, _obj));
              setShowModal(!showModal);
            }}
          >
            <div>
              <span>Create New Class</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        )}
      </div>
      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Class "
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
              }}
            />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>Name ({allData.length > 0 && allData.length}) </th>
              <th>Category</th>
              <th>Staff</th>
              <th></th>
              <th></th>
            </tr>
            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.className}</td>
                  <td>
                    {item.cat === "J"
                      ? "JSS"
                      : item.cat === "S"
                      ? "SSS"
                      : "N/A"}
                  </td>
                  <td>{item.staffname}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {checkAccess(accessObj.rights, "Assign") && (
                        <button
                          disabled={staffId === item.staffID && assignLoading}
                          style={{
                            backgroundColor: item.staffID
                              ? colors.primary
                              : "white",
                            color: item.staffID ? "white" : colors.primary,
                            fontWeight: "bold",
                            border: `1px solid ${colors.primary}`,
                            borderRadius: "08px",
                            padding: "0.3rem 0.6rem",
                          }}
                          onClick={() => {
                            if (item.staffID) {
                              setStaffId(item.staffID);
                              assignClassFunc(
                                item.classId,
                                item.staffID,
                                "RemoveAssign"
                              );
                            } else {
                              setClassObj({
                                ...classObj,
                                id: item.classId,
                                name: item.className,
                              });
                              setOpenAssign(true);
                            }
                          }}
                        >
                          {staffId === item.staffID && assignLoading
                            ? "Please wait..."
                            : item.staffID
                            ? "Unassign Teacher"
                            : "Assign Teacher"}
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {checkAccess(accessObj.rights, "Edit") && (
                        <button
                          disabled={!isGlobalUser}
                          onClick={() => {
                            setFormObj({
                              ...formObj,
                              classId: item.classId,
                              schoolid: user.schoolId,
                              cname: item.className,
                              carm: item.classArm,
                              classNameShort: item.classShortName,
                              cat: item.category,
                            });

                            setShowModal(true);
                          }}
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                          }}
                        >
                          Edit
                        </button>
                      )}

                      {checkAccess(accessObj.rights, "Delete") && (
                        <button
                          disabled={!isGlobalUser}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                          }}
                          onClick={() => {
                            setId(item.classId);
                            delAction(item.classId);
                          }}
                        >
                          {formLoading && id === item.classId ? (
                            <FormLoader />
                          ) : (
                            " Delete"
                          )}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No Classes Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            currentPage={currentPage - 1}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

export default Class;
