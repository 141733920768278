import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Typography,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../assets/colors.json";
import { 
  useGetLessonSessionsQuery, useGetLessonSubjectQuery, useGetLessonTermsQuery, useGetLessonWeeksQuery, useGetLessonClassessQuery, usePostEditedLessonNoteMutation, useGetEachLessonNoteQuery
} from "../../api/lessonNoteApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { ArrowDropDown } from "@mui/icons-material";

const EditLessonNoteModal = ({ isOpen, onClose, item }) => {
  const [formLoading, setFormLoading] = useState(false);
  const { data, isLoading } = useGetEachLessonNoteQuery(item);
  const { data: sessions, isLoading: sessionsLoading } = useGetLessonSessionsQuery();
  const { data: lessonTerm, isLoading: termLoading } = useGetLessonTermsQuery();
  const { data: lessonSubject, isLoading: subjectLoading } = useGetLessonSubjectQuery();
  const { data: lessonWeeks, isLoading: weekLoading } = useGetLessonWeeksQuery();
  const { data: lessonNoteClass, isLoading: classLoading } = useGetLessonClassessQuery();
  const [postEditedLessonNote] = usePostEditedLessonNoteMutation();

  const selectedId = data?.data

    if(selectedId?.status === "Submitted" || selectedId?.status === "Endorsed") {
      // toast.error("You can't Edit a Submitted Lesson")
      onClose();
    }
  
  const validationSchema = Yup.object().shape({
    SessionId: Yup.string().required("School Year is required"),
    Term: Yup.string().required("Term is required"),
    SubjectId: Yup.string().required("Subject is required"),
    ClassArm: Yup.string().required("Class Arm is required"),
    WeekId: Yup.string().required("Week is required"),
  });

  const formik = useFormik({
    initialValues: {
      SessionId: selectedId?.sessionId || "",
      SubjectId: selectedId?.subjectId || "",
      WeekId: selectedId?.weekId || "",
      Term: selectedId?.term || "",
      ClassArm: selectedId?.className || "",
    },
    validationSchema,
    enableReinitialize: true,
    
    onSubmit: async (values) => {
      setFormLoading(true);
      const formData = new FormData();
      formData.append("SessionId", values.SessionId);
      formData.append("SubjectId", values.SubjectId);
      formData.append("WeekId", values.WeekId);
      formData.append("Term", values.Term);
      formData.append("ClassArm", values.ClassArm);

      try {
         const response = await postEditedLessonNote({ id: item, formData }).unwrap();

        if (response.status === true && response.statusCode === 2) {
          setFormLoading(false);
          toast.success(response.message);
          onClose();
        } else if (response.status === false && response.statusCode === 5) {
          setFormLoading(false);
          toast.error(response.message);
        } else {
          setFormLoading(false);
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting lesson note:", error);
      } finally{
        setFormLoading(false)
      }
    },
  });


  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
      <div className="m-8 w-[360px] sm:w-[400px] md:w-[600px] lg:w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4">
        <div className="flex justify-end">
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
          </div>
          <h1 className="text-center text-2xl lg:text-3xl font-semibold">
            Edit Lesson Note Setup
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <Box
              p={3}
              sx={{
                overflowY: {
                  xs: "scroll",
                  sm: "scroll",
                  md: "scroll",
                  lg: "scroll",
                  xl: "auto",
                },
                height: {
                  xs: "540px",
                  sm: "540px",
                  md: "450px",
                  lg: "450px",
                  xl: "auto",
                },
              }}
            >
              <Box>
                <Grid container spacing={2}>
                    {/* session */}
                  <Grid item md={6} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        School Year
                      </InputLabel>
                      <div style={{ position: "relative", width: "100%" }}>
                        <select
                          fullWidth
                          placeholder="dssss"
                          size="small"
                          id="SessionId"
                          name="SessionId"
                          value={formik.values.SessionId ?? ""}
                          onChange={(event) => formik.setFieldValue("SessionId", event.target.value)}
                          onBlur={formik.handleBlur}
                          style={{
                            width: "100%",
                            padding: "8px 16px",
                            fontSize: "13px",
                            border: "3px solid #671E78",
                            borderRadius: "4px",
                            fontFamily: "gilroy-regular",
                            backgroundColor: "white",
                            appearance: "none",
                            cursor: "pointer",
                          }}
                        >
                          <option value="" disabled>
                            <em> Choose Year</em>
                          </option>

                          {sessionsLoading ? (
                            <Box>
                              <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{ fontSize: "2rem", mx: 2 }}
                              />
                              <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                            </Box>
                          ) : sessions?.data.length > 0 ? (
                            sessions?.data.map((session) => (
                              <option
                                key={session.id}
                                value={session.id}
                                sx={{
                                  fontFamily: "gilroy-regular",
                                  fontSize: "13px",
                                }}
                              >
                                {session.name}
                              </option>
                            ))
                          ) : (
                            <Box>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: "bolder",
                                  color: "#333",
                                  fontFamily: "Gilroy-Medium",
                                }}
                              >
                                No Sessions Available
                              </Typography>
                            </Box>
                          )}
                        </select>
                        <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                       <ArrowDropDown color="action" />
                      </span>
                      </div>

                      {/* Display validation error */}
                      {formik.touched.SessionId && formik.errors.SessionId && (
                        <div className="text-red-600 text-xs">{formik.errors.SessionId}</div>
                      )}

                    </Box>
                  </Grid>
                  {/* term */}
                  <Grid item md={6} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        Term
                      </InputLabel>
                      <div style={{ position: "relative", width: "100%" }}>
                      <select
                        fullWidth
                        placeholder="dssss"
                        size="small"
                        id="Term"
                        name="Term"
                        value={formik.values.Term ?? ""}
                        onChange={(event) => {
                          formik.setFieldValue("Term", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        style={{
                          width: "100%",
                          padding: "8px 16px",
                          fontSize: "13px",
                          border: "3px solid #671E78",
                          borderRadius: "4px",
                          fontFamily: "gilroy-regular",
                          backgroundColor: "white",
                          appearance: "none",
                          cursor: "pointer",
                        }}
                      >
                        <option value="" disabled>
                          <em> Choose Term</em>
                        </option>
                        {termLoading && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1rem", mx: 2 }}
                          />
                        </Box>
                      )}
                        {(lessonTerm?.data?.length === 0) && !termLoading ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No term
                          </Typography>
                        </Box>
                      ) : (lessonTerm?.data?.length > 0 && 
                        lessonTerm?.data.map((term) => (
                          <option
                            key={term.id}
                            value={term.value}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {term.name}
                          </option>
                        ))
                      )}
                      </select>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                       <ArrowDropDown color="action" />
                      </span>
                      </div>
                      {formik.touched.Term && formik.errors.Term ? (
                        <div className="text-red-600 text-xs">
                          {formik.errors.Term}
                        </div>
                      ) : null}
                    </Box>
                  </Grid>
                  {/* Subject */}
                  <Grid item md={6} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        Subject
                      </InputLabel>
                      <div style={{ position: "relative", width: "100%" }}>
                      <select
                        fullWidth
                        placeholder="dssss"
                        size="small"
                        id="SubjectId"
                        name="SubjectId"
                        value={formik.values.SubjectId ?? ""}
                        onChange={(event) => {
                          formik.setFieldValue("SubjectId", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        style={{
                          width: "100%",
                          padding: "8px 16px",
                          fontSize: "13px",
                          border: "3px solid #671E78",
                          borderRadius: "4px",
                          fontFamily: "gilroy-regular",
                          backgroundColor: "white",
                          appearance: "none",
                          cursor: "pointer",
                        }}
                      >
                        <option value="" disabled>
                          <em>Choose Subject</em>
                        </option>

                        {/* Loading state */}
                        {subjectLoading && (
                          <Box>
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{ fontSize: "2rem", mx: 2 }}
                            />
                            <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{ fontSize: "1rem", mx: 2 }}
                            />
                          </Box>
                        )}

                        {/* No data available state */}
                        {!subjectLoading && (!lessonSubject || lessonSubject?.length === 0) ? (
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                                color: "#333",
                                fontFamily: "Gilroy-Medium",
                              }}
                            >
                              No Subject Available
                            </Typography>
                          </Box>
                        ) : (

                          lessonSubject?.map((subject) => (
                            <option
                              key={subject.id}
                              value={subject.id}
                              sx={{
                                fontFamily: "gilroy-regular",
                                fontSize: "13px",
                              }}
                            >
                              {subject.name}
                            </option>
                          ))
                        )}
                      </select>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                       <ArrowDropDown color="action" />
                      </span>
                      </div>
                      {formik.touched.SubjectId && formik.errors.SubjectId ? (
                        <div className="text-red-600 text-xs">
                          {formik.errors.SubjectId}
                        </div>
                      ) : null}
                    </Box>
                  </Grid>
                  {/* ClassArm */}
                  <Grid item md={6} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        Class Arm
                      </InputLabel>
                      <div style={{ position: "relative", width: "100%" }}>
                      <select
                        fullWidth
                        placeholder="dssss"
                        size="small"
                        id="ClassArm"
                        name="ClassArm"
                        value={formik.values.ClassArm ?? ""}
                        onChange={(event) => {
                          formik.setFieldValue("ClassArm", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        style={{
                          width: "100%",
                          padding: "8px 16px",
                          fontSize: "13px",
                          border: "3px solid #671E78",
                          borderRadius: "4px",
                          fontFamily: "gilroy-regular",
                          backgroundColor: "white",
                          appearance: "none",
                          cursor: "pointer",
                        }}
                      >
                        <option value="" disabled>
                          <em> Choose Class</em>
                        </option>
                        {classLoading && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1rem", mx: 2 }}
                          />
                        </Box>
                      )}
                        {!lessonNoteClass ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No Subject Available
                          </Typography>
                        </Box>
                      ) : (lessonNoteClass?.data?.length > 0 &&
                        lessonNoteClass?.data.map((classArm) => (
                          <option
                            key={classArm.id}
                            value={classArm.value}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {classArm.name}
                          </option>
                        ))
                      )}
                      </select>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none", // Prevent clicking on the icon
                        }}
                      >
                       <ArrowDropDown color='action' />
                      </span>
                  </div>
                      {formik.touched.ClassArm && formik.errors.ClassArm ? (
                        <div className="text-red-600 text-xs">
                          {formik.errors.ClassArm}
                        </div>
                      ) : null}
                    </Box>
                  </Grid>
                  {/* weeks */}
                  <Grid item lg={12} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        Week
                      </InputLabel>
                      <div style={{ position: "relative", width: "100%" }}>
                      <select
                        fullWidth
                        placeholder="dssss"
                        size="small"
                        id="WeekId"
                        name="WeekId"
                        value={formik.values.WeekId ?? ""}
                        onChange={(event) => {
                          formik.setFieldValue("WeekId", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        style={{
                          width: "100%",
                          padding: "8px 16px",
                          fontSize: "13px",
                          border: "3px solid #671E78",
                          borderRadius: "4px",
                          fontFamily: "gilroy-regular",
                          backgroundColor: "white",
                          appearance: "none",
                          cursor: "pointer",
                                          }}
                      >
                        <option value="" disabled>
                          <em> Choose Week</em>
                        </option>
                        {weekLoading && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1rem", mx: 2 }}
                          />
                        </Box>
                      )}
                        {!weekLoading && !lessonWeeks?.data ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No Subject Available
                          </Typography>
                        </Box>
                      ) : (lessonWeeks?.data.length > 0 &&
                        lessonWeeks?.data.map((weeks) => (
                          <option
                            key={weeks.id}
                            value={weeks.id}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {weeks.name}
                          </option>
                        ))
                      )}
                      </select>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none", // Prevent clicking on the icon
                        }}
                      >
                       <ArrowDropDown color='action' />
                      </span>
                      </div>
                      {formik.touched.WeekId && formik.errors.WeekId ? (
                        <div className="text-red-600 text-xs">
                          {formik.errors.WeekId}
                        </div>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Button
                    disabled={formLoading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      textTransform: "initial",
                      backgroundColor: colors.primary,
                    }}
                  >
                    {formLoading ? (
                        <FormLoader />
                      ) : (
                    <span>Update Lesson Note Setup</span>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </div>
    </div>
  );
};

export default EditLessonNoteModal;
