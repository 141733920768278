import {
  // IconButton,
  Typography,
  Grid,
  // List,
  ListItem,
  Avatar,
  Divider,
  TextField,
} from "@mui/material";
import { GiCancel } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { useRef } from "react";
import "./Result.css";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import PdfDownloader2 from "../PdfDownloader2";
import { LuFileSpreadsheet } from "react-icons/lu";
import colors from "../../assets/colors.json";

const ExcelJS = require("exceljs");

const ResultCard = ({
  resultData,
  close,
  print,
  stampSign,
  caMark,
  examMark,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const ref = useRef();

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Result sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.properties.header = "";
    sheet.columns = [
      {
        header: "Subject",
        key: "subjectName",
        width: "15",
      },
      {
        header: "Total C.A Score",
        key: "ca",
        width: "15",
      },
      {
        header: "Exam score",
        key: "exam",
        width: "10",
      },
      {
        header: "Weighted Average",
        key: "weightedAvg",
        width: "18",
      },
      {
        header: "Term I Score",
        key: "term1score",
        width: "15",
      },
      {
        header: "Term II Score",
        key: "term2score",
        width: "15",
      },
      {
        header: "Term III Score",
        key: "term3score",
        width: "15",
      },
      {
        header: "Total annual score",
        key: "totalAnnScore",
        width: "30",
      },
      {
        header: "Average annual score",
        key: "totalAnnAvgScore",
        width: "20",
      },
      {
        header: "Lowest in Class",
        key: "lowestInClass",
        width: "20",
      },
      {
        header: "Highest in Class",
        key: "highestInClass",
        width: "20",
      },
      {
        header: "Position Grade",
        key: "grade",
        width: "15",
      },
      {
        header: "Remark",
        key: "remark",
        width: "10",
      },
    ];

    resultData.resultSheet.map((each) => {
      sheet.addRow({
        subjectName: each?.subjectName,
        ca: each.subJectResult?.ca,
        exam: each.subJectResult?.exam,
        weightedAvg: each.subJectResult?.weightedAvg,
        term1score: each.subJectResult?.term1Score,
        term2score: each.subJectResult?.term2Score,
        term3score: each.subJectResult?.term3Score,
        totalAnnScore: each.subJectResult?.totalAnnScore,
        totalAnnAvgScore: each.subJectResult?.totalAnnAvgScore,
        lowestInClass: each.subJectResult?.lowestInClass,
        highestInClass: each.subJectResult?.highestInClass,
        grade: each.subJectResult?.grade,
        remark: each.subJectResult?.remark,
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <Box
        ref={ref}
        mb={10}
        sx={{
          position: "relative",
        }}
      >
        <GiCancel
          style={{
            position: "absolute",
            left: "10px",
            top: "10px",
            cursor: "pointer",
          }}
          onClick={close}
        />
        <div className="flex items-center gap-2 justify-end">
          <button
            className="flex gap-1 items-center border p-1"
            onClick={exportExcelFile}
          >
            <LuFileSpreadsheet className="w-6 h-6" />
            Export to excel
          </button>
          <PdfDownloader2 elementId={"pdf-content"} />
        </div>
        
        <Box id="pdf-content">
          <Box
            sx={{
              textAlign: "center",
              margin: "0 auto",
              width: { lg: "97%", md: "97%", sm: "98%", xs: "99%" },
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Avatar src={resultData.schoolDetails.logo} />
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Gilroy-Heavy",
                  fontSize: { xs: "14px", lg: "20px" },
                }}
              >
                {resultData.schoolDetails.schoolname}
              </Typography>
            </Box>
            <Box mb={2} sx={{ margin: "0 auto", width: "90%" }}>
              <Grid
                container
                sx={{
                  borderRadius: "20px",
                  fontFamily: "Gilroy-Regular",
                }}
              >
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <Box
                    sx={{
                      my: { lg: 2, xs: 1 },
                      pr: { lg: 3.5, xs: 0 },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      borderRight: {
                        lg: "1px solid #671e78",
                        xs: "none",
                        md: "1px solid #671e781a",
                        sm: "1px solid #671e781a",
                      },
                      textAlign: { lg: "right", xs: "center" },
                    }}
                  >
                    <span style={{ fontSize: "14px", marginBottom: "10px" }}>
                      {resultData.schoolDetails.schooladdress}

                      <AllInboxIcon
                        sx={{ ml: 1, mb: -0.6, fontSize: "18px" }}
                      />
                    </span>{" "}
                    <span style={{ fontSize: "14px" }}>
                      {resultData.schoolDetails.schoolemail}
                      <EmailIcon sx={{ ml: 1, mb: -0.6, fontSize: "18px" }} />
                    </span>{" "}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  sx={{ textAlign: "left", my: { lg: 2, xs: -0.6 } }}
                >
                  <Box
                    sx={{
                      textAlign: { lg: "left", xs: "center" },
                      ml: { lg: 2, xs: 0 },
                    }}
                  >
                    <span style={{ fontSize: "14px", marginBottom: "12px" }}>
                      <LocalPhoneIcon
                        sx={{ mr: 1, mb: -0.6, fontSize: "18px" }}
                      />
                      {resultData.schoolDetails.schoolphone}
                    </span>{" "}
                    <br />
                    <span style={{ fontSize: "14px" }}>
                      <LanguageIcon
                        sx={{ mr: 1, mb: -0.6, fontSize: "18px" }}
                      />
                      {resultData.schoolDetails.portal_url}
                    </span>{" "}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                bgcolor: "#671E78",
                p: 1,
                borderRadius: "20px",
                pb: 2,
                mt: 2,
              }}
            >
              <Typography
                variant="body"
                sx={{
                  color: "#fff",
                  fontFamily: "Gilroy-Medium",
                  fontSize: { xs: "10px", md: "16px", sm: "14px", lg: "16px" },
                }}
              >
                Student Internal Academic Report Sheet
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#671E78",
                width: "40%",
                margin: "0 auto 15px",
                p: 1,
                borderRadius: "20px",
                position: "relative",
                top: "-8px",
              }}
            >
              <Typography
                variant="body"
                sx={{
                  color: "#fff",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "10px" },
                  fontFamily: "Gilroy-Regular",
                }}
              >
                {resultData.studentDetails.term}
              </Typography>
            </Box>

            <Box sx={{ margin: "0 auto", width: "90%" }}>
              <Grid
                container
                sx={{
                  bgcolor: "#671E781a",
                  borderRadius: "20px",
                  fontFamily: "Gilroy-Regular",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={12}
                  display="flex"
                  sx={{ alignItems: "center" }}
                >
                  <Avatar
                    src={resultData.studentDetails.profileImage}
                    variant="rounded"
                    sx={{
                      border: "1px solid #671e78",
                      width: "100px",
                      height: "120px",
                      borderRadius: "20px",
                    }}
                  />
                  <Box
                    my={2}
                    pr={2}
                    sx={{
                      borderRight: {
                        // lg: "1px solid #671e78",
                        xs: "none",
                        // md: "1px solid #671e781a",
                        // sm: "1px solid #671e781a",
                      },
                      textAlign: "left",
                      ml: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        fontSize: "14px",
                        height: "100%",
                      }}
                    >
                      <p style={{ fontWeight: "bold" }}>
                        Name:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {resultData.studentDetails.name}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        House:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {resultData.studentDetails.house}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        Term:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {resultData.studentDetails.term}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        Class:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {resultData.studentDetails.studentClass}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        No. of Students:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {resultData.numberOfStudentInClass}
                        </span>
                      </p>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  my={2}
                  xs={12}
                  md={6}
                  sm={12}
                  sx={{ textAlign: "left" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      fontSize: "14px",
                      padding: isMobile ? "10px" : "0px",
                      height: isMobile ? "100px" : "100%",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      Admission Number:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {resultData.studentDetails.admissionNo}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      Academic Session:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {resultData.studentDetails.academicSession}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      No. of Times School Opened:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {resultData.numberOfTimeSchoolOpened}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      No. of Times Present:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {resultData.numberOfPresent}
                      </span>
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Table box */}
          <Box
            mt={3}
            className="sheet_table card_class"
            sx={{ overflowX: "scroll" }}
          >
            <table style={{ width: "100%" }}>
              <tr>
                <th>
                  <div className="" style={{}}>
                    SUBJECTS
                  </div>
                </th>
                <th style={{ position: "relative" }} class="transform_head">
                  <div className="tnh_1" style={{}}>
                    TOTAL C.A SCORE
                  </div>
                </th>
                <th
                  className=" transform_head"
                  style={{ position: "relative" }}
                >
                  <div className="tnh_2" style={{}}>
                    EXAM SCORE
                  </div>
                </th>
                <th
                  className="fourth-child transform_head"
                  style={{ position: "relative" }}
                >
                  <div className=" tnh_3" style={{}}>
                    WEIGHTED AVERAGE
                  </div>
                </th>

                <th className="transform_head" style={{ position: "relative" }}>
                  <div className=" tnh_3" style={{}}>
                    TERM I SCORE
                  </div>
                </th>
                <th className="transform_head" style={{ position: "relative" }}>
                  {/* TERM II SCORE */}
                  <div className=" tnh_3" style={{}}>
                    TERM II SCORE
                  </div>
                </th>
                <th className="transform_head" style={{ position: "relative" }}>
                  {/* TERM III SCORE */}
                  <div className=" tnh_3" style={{}}>
                    TERM III SCORE
                  </div>
                </th>
                <th
                  className="transform_head"
                  style={{ position: "relative", fontWeight: "bold" }}
                >
                  {/* TOTAL ANNUAL SC. */}
                  <div className=" tnh_4" style={{}}>
                    TOTAL ANNUAL SC.
                  </div>
                </th>
                <th
                  className="fourth-child transform_head"
                  style={{ position: "relative" }}
                >
                  {/* AVERAGE ANNUAL SC. */}
                  <div className=" tnh_3" style={{}}>
                    AVERAGE ANNUAL SC.
                  </div>
                </th>
                <th
                  className=" transform_head"
                  style={{ position: "relative" }}
                >
                  {/* LOWEST IN CLASS */}

                  <div className=" tnh_5" style={{}}>
                    LOWEST IN CLASS
                  </div>
                </th>
                <th
                  className=" transform_head"
                  style={{ position: "relative" }}
                >
                  {/* HIGHEST IN CLASS */}
                  <div className=" tnh_5" style={{}}>
                    HIGHEST IN CLASS
                  </div>
                </th>
                <th
                  className="fourth-child  transform_head"
                  style={{ position: "relative" }}
                >
                  {/* POSITION GRADE */}
                  <div className=" tnh_6" style={{}}>
                    POSITION GRADE
                  </div>
                </th>
                <th>REMARK</th>
              </tr>
              <tr style={{ fontSize: "14px" }}>
                <th></th>
                <th style={{ textAlign: "center" }}>{caMark} </th>
                <th style={{ textAlign: "center" }} className="">
                  {examMark}
                </th>
                <th style={{ textAlign: "center" }} className="">
                  100
                </th>

                <th style={{ textAlign: "center" }} className="">
                  100
                </th>
                <th style={{ textAlign: "center" }} className="">
                  100
                </th>
                <th style={{ textAlign: "center" }} className="">
                  100
                </th>
                <th style={{ textAlign: "center" }} className="">
                  300
                </th>
                <th style={{ textAlign: "center" }} className="">
                  100
                </th>

                <th className=""></th>
                <th className=""></th>
                <th className=""></th>

                <th>{/* REMARK */}</th>
              </tr>

              {resultData?.resultSheet.length > 0 ? (
                resultData.resultSheet.map((item, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: "justify", paddingLeft: "10px" }}>
                      <div>
                        {i + 1}. {item.subjectName}
                      </div>
                    </td>
                    <td style={{ width: "max-content" }}>
                      {item.subJectResult.ca}
                    </td>
                    <td>{item.subJectResult.exam}</td>
                    <td className="fourth-child">
                      {item.subJectResult.weightedAvg}
                    </td>
                    <td>
                      {item.subJectResult.term1Score
                        ? item.subJectResult.term1Score
                        : ""}
                    </td>
                    <td>
                      {item.subJectResult.term2Score
                        ? item.subJectResult.term2Score
                        : ""}
                    </td>
                    <td>
                      {item.subJectResult.term3Score
                        ? item.subJectResult.term3Score
                        : ""}
                    </td>
                    <td>
                      {item.subJectResult.totalAnnScore
                        ? item.subJectResult.totalAnnScore
                        : ""}
                    </td>{" "}
                    <td className="fourth-child">
                      {item.subJectResult.totalAnnAvgScore
                        ? Math.round(
                            Number(item.subJectResult.totalAnnAvgScore)
                          )
                        : ""}
                    </td>{" "}
                    <td>{item.subJectResult.lowestInClass}</td>{" "}
                    <td>{item.subJectResult.highestInClass}</td>
                    <td className="fourth-child">{item.subJectResult.grade}</td>
                    <td style={{ padding: "0.85rem" }}>
                      {item.subJectResult.remark}
                    </td>
                  </tr>
                ))
              ) : (
                <div>
                  No records found for this student in the selected academic
                  year!{" "}
                </div>
              )}

              <tr>
                <th style={{ height: "40px" }}>
                  <div style={{ width: "max-content", padding: "5px 10px" }}>
                    TOTAL FINAL SCORE:
                  </div>
                </th>
                <td colSpan={3} className="fourth-child">
                  <span>{resultData.finalTotalScore}</span>
                </td>
                <td colSpan={5} className="fourth-child">
                  <strong>AVERAGE PERCENTAGE SCORE</strong>
                </td>
                <td colSpan={4}>{resultData.averagePercentage} %</td>
              </tr>
            </table>
          </Box>

          <Box mt={2} mb={2}>
            <Grid container>
              {resultData?.psyChomotorReport &&
              resultData?.psyChomotorReport.length > 0 ? (
                resultData.psyChomotorReport.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    lg={2.9}
                    md={5.9}
                    xs={12}
                    sx={{
                      bgcolor: "#671e781a",
                      border: "1px solid #671e78",
                      borderRadius: "10px",
                      mr: { lg: 0.5, md: 0.5, xs: 0.5 },
                      mb: { lg: 0.5, md: 0.5, xs: 0.5 },
                    }}
                  >
                    <Typography
                      variant="body"
                      sx={{
                        fontFamily: "Gilroy-Regular",
                        fontWeight: "bolder",
                        fontSize: { xs: "14px", lg: "12px" },
                        m: 2,
                      }}
                    >
                      {item.headLine}
                    </Typography>
                    <Divider />
                    <Box p={0}>
                      {item.subHeadList.length > 0 &&
                        item.subHeadList.map((i, index) => (
                          <ListItem display="flex" key={index}>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "9px" }}
                            >
                              {i.name}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "9px", marginLeft: "auto" }}
                            >
                              {i.resultScore}
                            </Typography>
                          </ListItem>
                        ))}
                    </Box>
                  </Grid>
                ))
              ) : (
                <div>No Psychomotor Result Available</div>
              )}
            </Grid>
          </Box>
          <Divider sx={{ borderColor: "#671e78 " }} />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item lg={4} xs={12}>
                <Typography
                  variant="body"
                  sx={{ fontSize: "13px", fontFamily: "Gilroy-Regular" }}
                >
                  CLASS TEACHER'S COMMENTS
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  margin="normal"
                  multiline
                  value={resultData.resultComments.classTeacherRemark}
                  rows={4}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography
                  variant="body"
                  sx={{ fontSize: "13px", fontFamily: "Gilroy-Regular" }}
                >
                  BOARDING HOUSE MASTER'S COMMENTS
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  disabled
                  value={resultData.resultComments.houseMasterRemark}
                  margin="normal"
                  rows={4}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography
                  variant="body"
                  sx={{ fontSize: "13px", fontFamily: "Gilroy-Regular" }}
                >
                  PRINCIPAL'S COMMENTS
                </Typography>
                <TextField
                  margin="normal"
                  disabled
                  fullWidth
                  value={resultData.resultComments.principalRemark}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="flex flex-col lg:flex-row gap-8 items-center">
            <div className="w-full lg:w-[50%]">
              <Box
                mt={2}
                p={2}
                sx={{
                  bgcolor: "#671E78",
                  fontFamily: "Gilroy-Regular",
                  color: "#fff",
                  borderRadius: "10px",
                  // width: { lg: "50%", sm: "100%", xs: "100%" },
                }}
              >
                <Typography
                  variant="body"
                  sx={{ fontWeight: "500", marginRight: "10px" }}
                >
                  Check-in-Date:
                </Typography>
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  {/* {moment(resultData.checkInDate).format("DD, MMMM, YYYY")} */}
                  {resultData.checkInDate}
                </span>
                <br />
                <Typography
                  variant="body"
                  sx={{ fontWeight: "500", marginRight: "10px" }}
                >
                  Next Term Begins:
                </Typography>
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  {/* {moment(resultData.nextTermBegin).format("DD, MMMM, YYYY")} */}
                  {resultData.nextTermBegin}
                </span>
              </Box>
              <Typography
                variant="caption"
                sx={{ fontFamily: "Gilroy-Regular" }}
              >
                ..any alteration will render the result invalid
              </Typography>
            </div>
            {(stampSign.signature || stampSign.stamp) && (
              <div
                className="w-full lg:w-[50%] rounded-2xl h-32 lg:h-20 bg-center relative flex justify-center items-center bg-contain"
                style={{
                  backgroundImage: `url(${stampSign.stamp})`,
                  backgroundColor: stampSign.stamp ? "none" : "#671E781A ",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  className="w-[15%] h-10  rounded-2xl absolute  top-[25%]"
                  src={`${stampSign.signature}`}
                  alt="signature"
                />
              </div>
            )}
          </div>
        </Box>
      </Box>
      {/* </Print> */}
    </>
  );
};

ResultCard.propTypes = {
  resultData: PropTypes.object,
};

ResultCard.defaultProps = {
  resultData: null,
};

export default ResultCard;
