import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import BlockIcon from "@mui/icons-material/Block";
import { useGetEachLessonNoteQuery } from "../../api/lessonNoteApi";
import TableLoader from "../TableLoader";

const PreviewLessonNoteModal = ({ isOpen, onClose, record }) => {
  const { data, error, isLoading } = useGetEachLessonNoteQuery(record?.id); 
  const selectedId = data?.data;
  const [pdfLoading, setPdfLoading] = useState(true);

  if (!record?.id) return null;
  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
      <div className="w-full mx-6 md:mx-0 max-w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4">
        <div className="flex justify-end">
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </div>
        <h1 className="text-center text-xl lg:text-3xl font-semibold">
          Attach File Preview
        </h1>

        {isLoading ? (
          <TableLoader />
        ) : record && record?.path !== "N" ? (
          <div className="mt-3">
            {pdfLoading && <TableLoader />}
            
            <iframe
              src={record?.path}
              width="100%"
              height="450px"
              onLoad={() => setPdfLoading(false)}
              style={{ display: pdfLoading ? "none" : "block" }}
            />
          </div>
        ) : (
          <div className="flex gap-x-3 items-center justify-center mt-8">
            <BlockIcon />
            <p className="text-center font-medium">No Attached File yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewLessonNoteModal;
