import {
  Avatar,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

import logo from "../../assets/school-logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import { NavData } from "../../assets/data/NavData";

import { checkAccess } from "../../services/utils";

let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});
const DashboardNavbar = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const router = useLocation();

  const { accessObj } = useSelector((state) => state.auth);

  return (
    <>
      {isMatch ? (
        <Box></Box>
      ) : (
        <ThemeProvider theme={Body}>
          <Box
            sx={{
              width: "301px",
              bgcolor: "#671E78",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: 5,
              minHeight: "100vh",
            }}
          >
            <Avatar
              src={logo}
              sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
            />
            <Box>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#ffffff",
                  color: "#671e78",
                  fontFamily: "gilroy-semibold",
                  textTransform: "inherit",
                  display: "none",
                  width: "200px",
                  "&:hover": {
                    color: "#fff",
                  },
                }}
              >
                Switch to User
              </Button>
            </Box>
            <Box sx={{ width: "100%", mt: 4, mb: 3 }}>
              <Typography sx={{ ml: 6, color: "#fff" }} variant="caption">
                MENU
              </Typography>
              <List>
                {NavData.map(
                  (nav, id) =>
                    (checkAccess(accessObj.menuItems, nav.label) ||
                      checkAccess(accessObj.menuItems, nav.name)) && (
                      <Link to={nav.link} key={id}>
                        <ListItemButton
                          selected={
                            router.pathname === nav.link ||
                            router.pathname.includes(nav.altPath)
                          }
                          sx={{
                            color: "white",
                            mr: 2,
                            pl: 5,
                            " 	&.Mui-selected": {
                              bgcolor: "rgba(217, 217, 217, 0.1)",
                              borderLeft: "4px solid #fff",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{ color: "#fff", fontSize: "20px" }}
                          >
                            {nav.icon}
                          </ListItemIcon>
                          <ListItemText>{nav.label}</ListItemText>
                        </ListItemButton>
                      </Link>
                    )
                )}
              </List>
            </Box>
            <p className="text-white mb-2">
              Last updated : 1st April, 2025 (72)
            </p>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default DashboardNavbar;

export const getMatchPath = (pathname = "", link = "") => {
  let links = pathname.split("/");

  return link.length === 2 ? links[1] === link : links[2] === link;
};
