import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Container } from "../Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import Title from "../../components/Dashboard/Title";
import { TabRouteContainer, TabRoute } from "../student_mgt/stu_mgt.style";
import { checkAccess } from "../../services/utils";
import CBTFirst from "../../components/CBT/CBTFirst";
import Classwork_First from "../../components/classwork/Classwork_First";
import { Tutorial_First } from "../../components/tutorial/Tutorial_First";
import { LessonNote } from "../Dashboard";
import StaffReport from "../../components/StaffReport/StaffReport";
import Assignment_First from "../../components/Assignment/Assignment_First";

const AcademicActivivities = () => {
  const location = useLocation();
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [studentCount, setStudentCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab_id = Number(new URLSearchParams(location.search).get("tab"));
  
  const handleChange = (event, newValue) => {
    setSearchParams({ ...searchParams, tab: newValue });
  };
  return (
    <Container>
      <GlobalStyles />
      <div>
        <Title title="Academic Activities" />
      </div>
      <TabRouteContainer>
        {checkAccess(accessObj.menuItems, "Classwork") && (
          <TabRoute isActive={tab_id === 1} onClick={(e) => handleChange(e, 1)}>
            <span>Classwork</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Assignment") && (
          <TabRoute isActive={tab_id === 2} onClick={(e) => handleChange(e, 2)}>
            <span>Assignment</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Tutorials") && (
          <TabRoute isActive={tab_id === 3} onClick={(e) => handleChange(e, 3)}>
            <span>Tutorials</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "CBT") && (
          <TabRoute isActive={tab_id === 4} onClick={(e) => handleChange(e, 4)}>
            <span>CBT</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Lesson Note") && (
          <TabRoute isActive={tab_id === 5} onClick={(e) => handleChange(e, 5)}>
            <span>Lesson Note</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Staff Report") && (
          <TabRoute isActive={tab_id === 6} onClick={(e) => handleChange(e, 6)}>
            <span>Staff Report</span>
          </TabRoute>
        )}
      </TabRouteContainer>

      {checkAccess(accessObj.menuItems, "Classwork") && tab_id === 1 && (
        <Classwork_First />
      )}
      {checkAccess(accessObj.menuItems, "Assignment") && tab_id === 2 && (
        <Assignment_First />
      )}
      {checkAccess(accessObj.menuItems, "Tutorials") && tab_id === 3 && (
        <Tutorial_First />
      )}
      {checkAccess(accessObj.menuItems, "CBT") && tab_id === 4 && <CBTFirst />}
      {checkAccess(accessObj.menuItems, "Lesson Note") && tab_id === 5 && (
        <LessonNote />
      )}
      {checkAccess(accessObj.menuItems, "Staff Report") && tab_id === 6 && (
        <StaffReport />
      )}
    </Container>
  );
};

export default AcademicActivivities;
