import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font} from "@react-pdf/renderer";

import Heavy from '../../../assets/fonts/Gilroy-Heavy.ttf'
import Medium from '../../../assets/fonts/Gilroy-Medium.ttf'
import Regular from '../../../assets/fonts/Gilroy-Regular.ttf'
import Light from '../../../assets/fonts/Gilroy-Light.ttf'
import ExtraBold from '../../../assets/fonts/Gilroy-ExtraBold.ttf'
import Thin from '../../../assets/fonts/Gilroy-Thin.ttf'

Font.register({ family: "Gilroy-Heavy", src: Heavy  });
Font.register({ family: 'Gilroy-Medium', src: Medium });
Font.register({ family: 'Gilroy-Regular', src: Regular });
Font.register({ family: 'Gilroy-Light', src: Light  });
Font.register({ family: 'Gilroy-ExtraBold', src: ExtraBold });
Font.register({ family: 'Gilroy-Thin', src: Thin });



const styles = StyleSheet.create({
  page: { padding: 20, fontSize: 12, },

 // School Header Section
  schoolHeader: { alignItems: "center", textAlign: "center", marginBottom: 10},
  logo: { width: 30, height: 30,},
  flex: { 
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "center", 
    alignItems: "center",
  },
  flexDetails: { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontSize: 10 },
  flexContact: { display: "flex", flexDirection: "row", justifyContent: "space-between",},
  flexBtw: { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",},
  schoolName: { 
    fontSize: 12, 
    fontWeight: "bold", 
    textTransform: "uppercase",
    fontFamily: "Gilroy-Heavy",

  },
  contactInfo: { 
    flexDirection: "row", 
    justifyContent: "center", 
    marginTop: 2 ,
    fontFamily: "Gilroy-Regular",
  },

  contactItemLeft: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  contactItemRight: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  icon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },

  // Report Sheet Title Section
  reportTitle: {
    backgroundColor: "#691e78",
    color: "white",
    textAlign: "center",
    padding: 8,
    fontWeight: "bold",
    marginTop: 10,
    borderRadius: 20,
    fontFamily: "Gilroy-Medium",

  },
  reportSubTitle: {
      backgroundColor: "#691e78",
      color: "white",
      textAlign: "center",
      padding: 8,
      fontWeight: "bold",
      borderRadius: 20,
      width: "40%",
      border: "none",
      fontFamily: "Gilroy-Regular",
      position: "relative",
      top: -1,
  },


  // Student Information Section
    studentInfoContainer: {
      backgroundColor: "#671E781a",
      borderRadius: 20,
      padding: 10,
      marginVertical: 10,
      display: "flex",
      flexDirection: "row", 
      alignItems: "center",
      marginVertical: 14,
      width: "85%",
      fontFamily: "Gilroy-Regular",
    },

    studentRow: { 
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 4 
    },
    studentImage: { 
      width: 80,
      height: 80, 
      borderRadius: 16, 
      marginRight: 10, 
      border: "2px solid #671E781",
      borderRadius: 20,

    },

  studentInfo:{
    marginLeft: 10,
    marginTop: 7,
    fontFamily: "Gilroy-Regular",
  },

  studentD :{
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Gilroy-Regular",
  },

  studentInfoTitle : {
    fontWeight: "bold",
    marginRight: 1,
    fontFamily: "Gilroy-Regular"
  },
  
  studentInfor: {
    fontFamily: "Gilroy-Regular"
  },
  
  // Table Styling
  sheetTable: {
    border: "2px solid black",
    borderRadius: 10,
    fontFamily: "Gilroy-Regular",
    width: "auto",
  },
  
  table: {
    display: "table",
    width: "auto",
    borderCollapse: "collapse",
    fontSize: 10,
    border: "1px solid black",
    fontFamily: "Gilroy-Regular",
  },
  
  tableRow: { 
    flexDirection: "row",
  },

  tableCol: {
    width: "15%",
    textAlign: "center",
    fontSize: 10,
  },
  
  tableCellHeader: {
    width: "15%",
    border: "1px solid black",
    fontWeight: "bold",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    fontSize: 10,
  },
  tableCellHeaderScore: {
    width: "15%",
    fontWeight: "bold",
    textAlign: "center",
 
  },
  tableCell: {
    border: "1px solid black",
    textAlign: "center",
    fontSize: 10,
    width: "15%"
  },

  tableFirstChild : {
    width: "34%",
    border: "1px solid black",
    fontSize: 10,
    paddingLeft: 3,
  },
  tableFirstChildScore : {
    borderCollapse: "collapse",
    width: "25%",
    fontSize: 10,
    marginRight: 5,
    textAlign: "center",

  },

  tableLastChild: {
    borderRight: "none",
    border: "1px solid black",
    fontSize: 10,
    textAlign: "center",
    width: "15%",

  },

  fourthChild: {
    borderRight: "0.5px solid red",
    border: "1px solid black",
    fontSize: 10,
    textAlign: "center"

  },


  // psyChomotorReport
  gridContainer: {
    flexDirection: "row",
    rowGap: 5,
    marginTop: 10,
    flexWrap: 'wrap',
    justifyContent: "space-between",
    fontFamily: "Gilroy-Medium"
  },
  card : {
    backgroundColor: "#671e781a",
    border: "2px solid #671e78",
    borderRadius: 10,
    marginRight: 0.5,
    marginBottom: 0.5,
    marginTop: 10,
    padding: 10,
    width: '23%',
  },

  gridItem: {
    marginBottom: 10,
    padding: 10,
    border: '1px solid black',
    textAlign: 'center',
  },

  //line
  line: {
    width: '100%',
    height: 2,
    backgroundColor: '#671e78',
    marginVertical: 10,
  },
  verticalLine: {
    width: 2,
    height: 40,
    backgroundColor: '#671e78',
    marginHorizontal: 4,
  },

  //comment section
  label: { fontSize: 11, fontWeight: 'bold', marginBottom: 4 },
  commentBox: {
    border: '2px solid #888',
    borderRadius: 4,
    width: 250,
    padding: 12,
    height: 120,
    fontSize: 10,
  },
  commentText: { fontSize: 12, color: '#888' },

  resumption: {
    backgroundColor: "#691e78",
    color: "white",
    textAlign: "center",
    padding: 20,
    borderRadius: 16,
    width: "60%",
    marginTop: 8,
    border: "none",
    fontFamily: "Gilroy-Regular",

},
container: {
  width: "100%",
  height: 100,
  borderRadius: 10,
  backgroundColor: "#fff",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
},
stamp: {
  width: 100,
  height: 100,
  objectFit: "contain",
},
signature: {
  width: 60,
  height: 40,
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translateX(-50%)",
},

});

// Student Result PDF Component
const StudentResultPDF = ({ studentData, stampSign, }) => {

  console.log("studentData", studentData)
  console.log("stampSign", stampSign)
  return (
    <Document>
      {studentData?.map((student, index) => (
        <Page key={index} size={{ width: 800, height: 800}} style={styles.page}>
          {/* School Header */}
         <View style={styles.schoolHeader}>
            <View style={styles.flex}>
              {student?.schoolDetails?.logo && (
                <Image src={student?.schoolDetails?.logo} style={styles.logo} />
              )}
              <Text style={[styles.schoolName, {
                fontWeight: "heavy", fontsize: 15
              }]}>{student?.schoolDetails?.schoolname}</Text>
            </View>
              {/* school details */}
            <View style={[styles.flexContact,{ marginTop: 12}]}>
              <View style={{textAlign: "right", marginRight: 12}}>
                <View style={styles.contactItemLeft}>
                  <Text style={[styles.contactItemLeft, {textAlign: "right", marginRight: 5}]}>{student?.schoolDetails?.schooladdress}</Text>
                  <Image style={[styles.icon, {alignItems: "right", marginLeft: 5}]} src="https://img.icons8.com/ios-filled/50/000000/marker.png" />
                </View>
                <View style={styles.contactItemLeft}>
                  <Text style={[styles.contactItemLeft, {textAlign: "right", marginRight: 5}]}>{student?.schoolDetails?.schoolemail}</Text>
                  <Image style={[styles.icon, {alignItems: "right", marginLeft: 5}]} src="https://img.icons8.com/ios-filled/50/000000/new-post.png" />
                </View>
              </View>
              <View style={styles.verticalLine} />
              <View style={{marginLeft: 12}}>
                <View style={styles.contactItemRight}>
                <Image style={styles.icon} src="https://img.icons8.com/ios-filled/50/000000/phone.png" />
                  <Text style={styles.contactItemRight}>{student?.schoolDetails?.schoolphone}</Text>
                </View>
                <View style={styles.contactItemRight}>
                <Image style={styles.icon} src="https://cdn-icons-png.flaticon.com/512/1077/1077063.png" />
                  <Text style={styles.contactItemRight}>{student?.schoolDetails?.portal_url}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.reportTitle}>
            <Text>Student Internal Academic Report Sheet</Text>
          </View>
          <View style={styles.flex}>
            <View style={styles.reportSubTitle}>
              <Text>{student?.studentDetails?.term}</Text>
            </View>
          </View>

          {/* Student Info */}
          <View style={styles.flex}>
            <View style={styles.studentInfoContainer}>
              <View>
                {student?.studentDetails?.profileImage && (
                  <Image src={student?.studentDetails?.profileImage} style={styles.studentImage} />
                )}
              </View>
              <View style={styles.studentInfo}>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>Name:</Text>
                  <Text style={styles.studentInfor}>{student?.studentDetails?.name}</Text>
                </View>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>House:</Text>
                  <Text style={styles.studentInfor}>{student?.studentDetails?.house}</Text>
                </View>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>Class:</Text>
                  <Text style={styles.studentInfor}>{student?.studentDetails?.studentClass}</Text>
                </View>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>No. of Student:</Text>
                  <Text style={styles.studentInfor}>{student?.numberOfStudentInClass}</Text>
                </View>
              </View>

              <View style={[styles.studentInfo, {marginLeft: 20},]}>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>Admission Number:</Text>
                  <Text style={styles.studentInfor}>{student?.studentDetails?.admissionNo}</Text>
                </View>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>Academic session:</Text>
                  <Text style={styles.studentInfor}></Text>
                </View>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>No. of Times School Opened:</Text>
                  <Text style={styles.studentInfor}>{student?.numberOfTimeSchoolOpened}</Text>
                </View>
                <View style={styles.studentD}>
                  <Text style={[styles.studentInfoTitle, {
                    fontFamily: "Gilroy-Medium"
                  }]}>No. of Times Present:</Text>
                  <Text style={styles.studentInfor}>{student?.numberOfPresent}</Text>
                </View>
              </View>
            </View>
          </View  >

          {/* Results Table */}
          <View style={styles.sheetTable}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableFirstChild, {
                  paddingVertical: 5
                }]}>SUBJECTS</Text>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>TOTAL</Text>
                  <Text>C.A</Text>
                  <Text>SCORE</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>EXAM</Text>
                  <Text>SCORE</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>WEIGHTED</Text>
                  <Text>AVERGAE</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>TERM I</Text>
                  <Text>SCORE</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>TERM II</Text>
                  <Text>SCORE</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>TERM III</Text>
                  <Text>SCORE</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>AVERAGE</Text>
                  <Text>ANNUAL</Text>
                  <Text>ANNUAL</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>LOWEST</Text>
                  <Text>IN</Text>
                  <Text>CLASS</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>HEIGHEST</Text>
                  <Text>IN</Text>
                  <Text>CLASS</Text>
                </View>
                <View style={[styles.tableCellHeader, {
                  paddingVertical: 5
                }]}>
                  <Text>POSITION</Text>
                  <Text>GRADE</Text>
                </View>
                <Text style={[styles.tableLastChild, {
                  paddingVertical: 5
                }]}>REMARK</Text>
              </View>
              <View style={styles.tableRow}>
                  <Text style={styles.tableFirstChildScore}>{" "}</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>30</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>70</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>100</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>100</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>100</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>100</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>300</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>100</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>{" "}</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>{" "}</Text>
                  <Text style={[styles.tableCellHeaderScore, { marginLeft: 5, paddingVertical: 5 }]}>{" "}</Text>
                </View>
              </View>
              {student?.resultSheet?.map((subject, idx) => (
                <View style={styles.tableRow} key={idx}>
                    <Text style={[styles.tableFirstChild, {paddingVertical: 5}]}>{idx + 1}. {subject.subjectName}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.ca}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.exam}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.weightedAvg}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.term1Score}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.term2Score}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.term5Score}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.totalAnnScore}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.totalAnnAvgScore}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult.lowestInClass}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.highestInClass}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.grade}</Text>
                    <Text style={[styles.tableCell, { paddingVertical: 5 }]}>{subject?.subJectResult?.remark}</Text>
                </View>
              ))}
              <View style={styles.tableRow}>
                <Text style={[styles.tableFirstChild, { paddingVertical: 5, alignItems:"center", fontSize: 14,  border: "1px solid black", textAlign: "center",  width: "34%",}]}>TOTAL FINAL SCORE</Text>
                <Text style={{paddingVertical: 5, alignItems:"center", width: "30%",  border: "1px solid black", textAlign: "center", fontSize: 9 }}>{student?.finalTotalScore}</Text>
                <Text  style={{paddingVertical: 5, alignItems:"center", width: "30%",  border: "1px solid black", textAlign: "center", fontSize: 9 }}>AVERAGE PERCENTAGE SCORE</Text>
                <Text  style={{paddingVertical: 5, alignItems:"center",  border: "1px solid black", textAlign: "center", width: "25%", fontSize: 9 }}>{student?.averagePercentage}%</Text>
              </View>
              <View>
            </View>
          </View>
          {/* Psychomotor Skills */}
          <View style={styles.gridContainer}>
            {student?.psyChomotorReport?.map((category, idx) => (
              <View key={idx} style={styles.card}>
                <Text style={{ fontSize: 12, fontFamily: "Gilroy-Medium", margin: 2 }}>{category?.headLine}</Text>
                {category?.subHeadList?.map((skill, skillIdx) => (
                  <View key={skillIdx} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                    <Text style={{fontSize: 9, textAlign: "left", marginTop: 5, paddingVertical: 5}}>
                      {skill?.name}
                    </Text>
                    <Text style={{fontSize: 9, marginLeft: "auto", marginTop: 5}}>
                      {skill?.resultScore}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
          <View style={styles.line} />

          {/* Remarks */}
          <View style={[styles.gridContainer, { marginTop: 10}]}>
            <View>
              <Text style={styles.label}>CLASS TEACHER'S COMMENTS</Text>
              <View style={styles.commentBox}>
                <Text style={styles.commentText}>{student?.resultComments?.classTeacherRemark}</Text>
              </View>
            </View>
            <View>
              <Text style={styles.label}>BOARDING HOUSE MASTER'S COMMENT</Text>
              <View style={styles.commentBox}>
                <Text style={styles.commentText}>{student?.resultComments?.houseMasterRemark}</Text>
              </View>
            </View>
            <View>
              <Text style={styles.label}>PRINCIPAL'S COMMENT</Text>
              <View style={styles.commentBox}>
                <Text style={styles.commentText}>{student?.resultComments?.principalRemark}</Text>
              </View>
            </View>
          </View>

          {/* School Closing and Next Term Date */}
          <View style={styles.flexBtw}>
            <View style={styles.resumption}>
              <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 2}}>
                <Text style={{ fontSize: 14 }}>Check-in-Date:</Text>
                <Text style={{ fontSize: 10 }}> {student?.checkInDate}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 2, marginTop: 3}}>
                <Text style={{ fontSize: 14 }}>Next Term Begins:</Text>
                <Text style={{ fontSize: 10 }}> {student?.nextTermBegin}</Text>
              </View>
            </View>
            <View>
              {(stampSign?.signature || stampSign?.stamp) && (
                <View style={styles.container}>
                  {stampSign?.stamp && <Image style={styles.stamp} src={stampSign?.stamp} />}

                  {/* {stampSign?.signature && <Image style={styles?.signature} src={stampSign?.signature} />} */}
                </View>
              )}
            </View>
          </View>
          <Text style={{fontSize: 8, marginTop: 2}}>..any alteration will render the result invalid</Text>
        </Page>
      ))}
    </Document>
  );
};

export default StudentResultPDF;
