import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Typography,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../assets/colors.json";

import { 
  useGetLessonSessionsQuery, useGetLessonTermsQuery, useGetLessonWeeksQuery, useGetLessonClassessQuery
} from "../../api/lessonNoteApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { useGetEachReportQuery, useGetReportTypeQuery, usePostEditedReportMutation } from "../../api/reportAPI";
import { ArrowDropDown } from "@mui/icons-material";

const EditReport = ({ isOpen, onClose, item  }) => {
  const [formLoading, setFormLoading] = useState(false);
  const { data } = useGetEachReportQuery(item)
  const { data: sessions, isLoading: sessionsLoading } = useGetLessonSessionsQuery();
  const { data: lessonTerm, isLoading: termLoading } = useGetLessonTermsQuery();
  const { data: lessonWeeks, isLoading: weekLoading } = useGetLessonWeeksQuery();
  const { data: reportType, isLoading: reportTypeLoading } = useGetReportTypeQuery();
  const [EditReport] = usePostEditedReportMutation();

  const selectedId = data?.data

    if(selectedId?.status === "Submitted" || selectedId?.status === "Endorsed") {
      // toast.error("You can't Edit a Submitted Report")
      onClose();
    }

  
  const validationSchema = Yup.object().shape({
    sessionId: Yup.string().required("School Year is required"),
    term: Yup.string().required("Term is required"),
    weekId: Yup.number().required("Week is required"),
    reportTypeId: Yup.number().required("Report Type is required"),
  });

  const initialValues = {
    reportTypeId: selectedId?.reportTypeId || "",
    sessionId: selectedId?.sessionId || "",
    weekId: selectedId?.weekId || "",
    term: selectedId?.term || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setFormLoading(true);
    
      try {
        const response = await EditReport({ id: item, payload: values });
        console.log(response)
        if (response.data.status === true && response.data.statusCode === 2) {
          setFormLoading(false);
          toast.success(response.data.message);
          onClose();
        } else if (response.data.status === false && response.data.statusCode === 5) {
          setFormLoading(false);
          toast.error(response.data.message);
        } else if (response.data.status === false && response.data.statusCode === 0) {
          setFormLoading(false);
          toast.error("Internal Server Error");
        } else{
          setFormLoading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error submitting lesson note:", error);
      } finally{
        setFormLoading(false)
      }
    },
  });

  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 z-50 bg-black/50 backdrop-blur-[2px] flex items-center justify-center">
      <div className="w-full mx-6 md:mx-0 max-w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4">
        <div className="flex justify-end ">
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </div>
        <h1 className="text-center text-2xl lg:text-3xl font-semibold">
          Edit Report
        </h1>
        <form onSubmit={formik.handleSubmit}>
          <Box
            p={3}
            sx={{
              overflowY: {
                xs: "scroll",
                sm: "scroll",
                md: "scroll",
                lg: "scroll",
                xl: "auto",
              },
              height: {
                xs: "450px",
                sm: "550px",
                md: "450px",
                lg: "450px",
                xl: "auto",
              },
            }}
          >
            <Box>
              <Grid container spacing={2}>
                {/* session */}
                <Grid item lg={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      School Year
                    </InputLabel>
                    <div style={{ position: "relative", width: "100%" }}>
                    <select
                      placeholder="dssss"
                      size="small"
                      id="sessionId"
                      name="sessionId"
                      value={formik.values.sessionId ?? ""} 
                      onChange={(event) => {
                        formik.setFieldValue("sessionId", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      style={{
                        width: "100%",
                        padding: "8px 16px",
                        fontSize: "13px",
                        border: "3px solid #671E78",
                        borderRadius: "4px",
                        fontFamily: "gilroy-regular",
                        backgroundColor: "white",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <option value="" disabled>
                        <em> Choose Year</em>
                      </option>
                      {sessionsLoading && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton
                            variant="rounded"
                            height={60}
                            sx={{ mx: 2 }}
                          />
                        </Box>
                      )}
                      {(!sessions?.data || sessions?.data?.length === 0) && !sessionsLoading ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No Sessions Available
                          </Typography>
                        </Box>
                      ) : (
                        sessions?.data.map((session) => (
                          <option
                            key={session.id}
                            value={session.id}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {session.name}
                          </option>
                        ))
                      )}
                    </select>
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none", 
                      }}
                    >
                      <ArrowDropDown color='action' />
                        </span>
                    </div>
                    {formik.touched.sessionId && formik.errors.sessionId ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.sessionId}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* term */}
                <Grid item lg={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Term
                    </InputLabel>
                    <div style={{ position: "relative", width: "100%" }}>
                    <select
                      placeholder="dssss"
                      size="small"
                      id="term"
                      name="term"
                      value={formik.values.term ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("term", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      style={{
                        width: "100%",
                        padding: "8px 16px",
                        fontSize: "13px",
                        border: "3px solid #671E78",
                        borderRadius: "4px",
                        fontFamily: "gilroy-regular",
                        backgroundColor: "white",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <option value="" disabled>
                        <em> Choose Term</em>
                      </option>
                      {termLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", mx: 2 }}
                        />
                      </Box>
                    )}
                      {(lessonTerm?.data?.length === 0) && !termLoading ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No term
                        </Typography>
                      </Box>
                    ) : (lessonTerm?.data?.length > 0 && 
                      lessonTerm?.data?.map((term) => (
                        <option
                          key={term.id}
                          value={term.value}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {term.name}
                        </option>
                      ))
                    )}
                    </select>
                     <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <ArrowDropDown color="action" />
                    </span>
                    </div>
                    {formik.touched.term && formik.errors.term ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.term}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* weeks */}
                <Grid item lg={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Week
                    </InputLabel>
                    <div style={{ position: "relative", width: "100%" }}>
                    <select
                      placeholder="dssss"
                      size="small"
                      id="weekId"
                      name="weekId"
                      value={formik.values.weekId ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("weekId", event.target.value);
                      }}
                      style={{
                        width: "100%",
                        padding: "8px 16px",
                        fontSize: "13px",
                        border: "3px solid #671E78",
                        borderRadius: "4px",
                        fontFamily: "gilroy-regular",
                        backgroundColor: "white",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <option value="" disabled>
                        <em> Choose Week</em>
                      </option>
                      {weekLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", mx: 2 }}
                        />
                      </Box>
                    )}
                      {!weekLoading && !lessonWeeks?.data ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No Subject Available
                        </Typography>
                      </Box>
                    ) : ( lessonWeeks?.data?.length > 0 &&
                      lessonWeeks?.data.map((weeks) => (
                        <option
                          key={weeks.id}
                          value={weeks.id}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {weeks.name}
                        </option>
                      ))
                    )}
                    </select>
                     <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <ArrowDropDown color="action" />
                    </span>
                    </div>
                    {formik.touched.weekId && formik.errors.weekId ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.weekId}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
                {/* Report */}
                <Grid item lg={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Report Type
                    </InputLabel>
                    <div style={{ position: "relative", width: "100%" }}>
                    <select
                      placeholder="dssss"
                      size="small"
                      id="reportTypeId"
                      name="reportTypeId"
                      value={formik.values.reportTypeId ?? ""}
                      onChange={(event) => {
                        formik.setFieldValue("reportTypeId", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      style={{
                        width: "100%",
                        padding: "8px 16px",
                        fontSize: "13px",
                        border: "3px solid #671E78",
                        borderRadius: "4px",
                        fontFamily: "gilroy-regular",
                        backgroundColor: "white",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <option value="" disabled>
                        <em> Choose Report</em>
                      </option>
                      {reportTypeLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", mx: 2 }}
                        />
                      </Box>
                    )}
                      {!reportTypeLoading && !reportType?.data ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No Report Type
                        </Typography>
                      </Box>
                    ) : ( reportType?.data?.length > 0 &&
                      reportType?.data.map((reportId) => (
                        <option
                          key={reportId.id}
                          value={reportId.id}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {reportId.name}
                        </option>
                      ))
                    )}
                    </select>
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <ArrowDropDown color="action" />
                    </span>
                    </div>
                    {formik.touched.reportTypeId && formik.errors.reportTypeId ? (
                      <div className="text-red-600 text-xs">
                        {formik.errors.reportTypeId}
                      </div>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Button
                  disabled={formLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "initial",
                    backgroundColor: colors.primary,
                  }}
                >
                  {formLoading ? (
                      <FormLoader />
                    ) : (
                  <span>Create</span>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default EditReport;
