import { format } from "date-fns";
import { enUS } from "date-fns/locale";

export default function truncateString(str, maxLength) {
  if (str?.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
}

export function getOrdinalSuffix(num) {
  const lookup = ["th", "st", "nd", "rd"];
  const mod100 = num % 100;
  return num + (lookup[(mod100 - 20) % 10] || lookup[mod100] || lookup[0]);
}

export const classList = (classes) => {
  if (classes.length === 0) {
    return "--";
  }
  return classes?.map((each) => each.className).join(" | ");
};


export const FormateDate = (date) => {
    return format(new Date(date), "do MMM yyyy", { locale: enUS });

}

export const formatDateTime = (date) => {
    return format(new Date(date), "do MMM yyyy, hh:mm a");
  };

  export const FormattedText = (data, charLimit) => {
    const trimmedData = data.slice(0, charLimit);
  
    return trimmedData + (data.length > charLimit ? '...' : '');
  };
