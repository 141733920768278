import { WarningAmberRounded } from "@mui/icons-material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDeleteLessonNoteMutation, useGetEachLessonNoteQuery } from "../../api/lessonNoteApi";
import FormLoader from "../FormLoader";

export default function DeleteModal({isOpen, onClose, id}) {
      const [formLoading, setFormLoading] = useState(false);
      const { data, error, isLoading } = useGetEachLessonNoteQuery(id);
      const [deleteLessonNote] = useDeleteLessonNoteMutation();
      
      const selectedId = data?.data
    
    if (!id) return;

    const deleteFn = async () => {
      if(selectedId.status === "Submitted" || selectedId.status === "Approved" || selectedId.status === "Endorsed"){
        setFormLoading(false);
        toast.error("Sorry, you cannot delete this");
        return;
      }

      if (selectedId.status === "Pending" || selectedId.status === "Rejected") {
          try {
          const response = await deleteLessonNote(id).unwrap();

          if (response.status === true && response.statusCode === 2) {
                  setFormLoading(false);
                  toast.success(response.message);
                  onClose();
          } else if (response.status === false && response.statusCode === 5) {
          setFormLoading(false);
          toast.error(response.message);
          }else {
            setFormLoading(false);
            toast.error(response.message);
          }
          } catch (error) {
              console.error("Error submitting lesson note:", error);
          }
      };
    }
  
    if (!isOpen) return null;
  
    return (
      <div className="modal fixed inset-0 bg-orange/20 backdrop-blur-[1px] grid place-items-center z-100">
        <div className='bg-white rounded-2xl py-5 px-6 max-w-md border border-[#691e78]'>
          <div className=" flex justify-center rounded-full">
            <WarningAmberRounded sx={{
                bgcolor: "#fff",
                color: "red",
                fontSize: "60px",
              }} 
            />
          </div>
          <div className='mt-5'>
            <h2 className="text-4xl text-primaryRed text-center">Confirm Delete</h2>
            <div>
              <h4 className="text-center py-4">
                Are you sure you want to Delete this Lesson?
              </h4>
            </div>
            <div className='flex items-center justify-center gap-x-6 mt-5'>
              <button className="text-[#691e78] border border-[#691e78] px-6 py-2 text-center rounded-md w-full" onClick={onClose}>Cancel</button>
              <button 
                className="text-white border border-[#691e78] bg-[#691e78] px-6 py-2 text-center rounded-md hover:bg-[#691e78]/80 w-full"  onClick={deleteFn}>
                {formLoading ? (
                    <FormLoader />
                  ) : (
                    <span>submit</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
