import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  Skeleton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useActivateDeactivateMutation,
  useCreateClassworkMutation,
  useDeleteClassworkMutation,
  useEditClassworkMutation,
  useGetAllClassworkQuery,
} from "../../api/index";
import Title from "../Dashboard/Title";
import FormLoader from "../FormLoader";
import LoaderLine from "../LoaderLine";
import truncateString from "../../utils";
import { classList } from "../../utils";
import { IoRemoveCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import CachedIcon from "@mui/icons-material/Cached";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const styleEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 650, xs: "90%" },
  height: { lg: "auto", xs: 600 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 650, xs: "90%" },
  height: { lg: "auto", xs: 600 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const promptStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 300, xs: 200 },
  height: "200px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};

const statusStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, xs: 400 },
  height: "400px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};

const DeleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 200, xs: 200 },
  height: "200px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {},
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Classwork_First = () => {
  const userInfo = useSelector((state) => state.auth.user);

  const {
    data: allClassWork,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllClassworkQuery({
    schoolId: userInfo?.schoolId,
  });

  const handRefetch = () => {
    refetch();
  };

  useEffect(() => {
    handRefetch();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [isId, setId] = React.useState();
  const [openAddClass, setOpenAddClass] = React.useState(false);
  const [classworkData, setClassworkData] = React.useState();
  const [loading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openDelete, seTOpenDelete] = useState();
  const [activateDeactivate, setActivateDeactivate] = useState(false);
  
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredClassWork = allClassWork?.data?.filter((item) => {
    // You can modify the condition as per your filtering requirements
    return (
      item?.subject?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.description?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.className?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  const handleOpenModal = () => setOpenModal(true);
  const handleOpenModalEdit = () => {
    setOpenModalEdit(!openModalEdit);
    setAnchorEl(null);
  };
  const handleCloseAddClass = () => setOpenAddClass(false);
  const handleOpenClass = () => setOpenAddClass(true);
  const handleOpenDeleteModal = () => seTOpenDelete(!openDelete);
  const handleCloseModal = () => setOpenModal(false);
  const handleActivateModal = () => setActivateDeactivate(!activateDeactivate);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const [onDeleteClasswork] = useDeleteClassworkMutation();
  const [onActivate] = useActivateDeactivateMutation();

  const handleActivate = async () => {
    setIsLoading(true);
    try {
      const res = await onActivate({
        classWorkId: isId,
        IsActivate: true,
      });
      if (res) {
        toast.success("You have successfully activated this classwork");
        setIsLoading(false);
        handleActivateModal();
        handleClose();
        refetch();
      }
    } catch (err) {
      toast.error("there was an error: ", err);
      setIsLoading(false);
      handleActivateModal();
      handleClose();
    }
  };

  const handleDeactivate = async () => {
    setIsLoading(true);
    try {
      const res = await onActivate({
        classWorkId: isId,
        IsActivate: false,
      });
      if (res) {
        toast.success("You have successfully deactivated this classwork");
        setIsLoading(false);
        handleActivateModal();
        handleClose();
        refetch();
      }
    } catch (err) {
      toast.error("there was an error: ", err);
      setIsLoading(false);
      handleActivateModal();
      handleClose();
    }
  };

  const handleDeleteClasswork = async () => {
    if (
      classworkData?.isSubmitted === true ||
      classworkData?.isActivated === true ||
      classworkData?.isQuestionAdded === true
    ) {
      handleOpenDeleteModal();
    } else {
      setIsLoading(true);
      try {
        const res = await onDeleteClasswork({
          id: isId,
        });
        if (res) {
          toast.success("You have successfully deleted this classwork");
          setIsLoading(false);
          handleClose();
        }
      } catch (err) {
        toast.error("there was an error: ", err);
        setIsLoading(false);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <ThemeProvider theme={theme}>
      {loading || isLoading || (isFetching && <LoaderLine />)}
      <Box>
        <Box className="grid">
          <Box className="grid lg:flex lg:justify-between">
            <Box></Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Bold",
                  height: "50px",
                }}
                className="w-full md:w-[400px]"
                onClick={handleOpenModal}
              >
                Create class work
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          mt={5}
          sx={{
            border: "1px solid #671E78",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              borderRadius: "20px 20px 0px 0px",
              background: "rgba(103, 30, 120, 0.1)",
            }}
            className="items-center p-[30px] flex justify-between"
          >
            <Search
              sx={{
                width: {
                  lg: "345px",
                  md: "330px",
                  sm: "auto",
                  xs: "auto",
                },
              }}
              value={searchInput}
              onChange={handleSearchInputChange}
            >
              <SearchIconWrapper>
                <BsSearch />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <IconButton onClick={handRefetch} sx={{ mr: 3 }}>
              <CachedIcon />
            </IconButton>
          </Box>
          <TableContainer>
            <Table
              sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
              aria-label="customized table"
            >
              <TableHead
                sx={{
                  backgroundColor: "rgba(103, 30, 120, 0.1)",
                  width: "100%",
                }}
              >
                <TableRow>
                  <StyledTableCell>S/N</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Subject</StyledTableCell>
                  <StyledTableCell>Classwork Title/Description</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Mark</StyledTableCell>
                  <StyledTableCell>Subject Teacher</StyledTableCell>
                  <StyledTableCell>Class</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              {/* {allClassWork?.data?.length <= 0 && (
                <div>
                  <Typography
                    sx={{ fontFamily: "Gilroy-Regular", textAlign: "center" }}
                  >
                    No classwork Found! Please add some classwork first...
                  </Typography>
                </div>
              )} */}
              <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                {filteredClassWork?.map((row, index) => {
                  const isoDateString = row?.post_date;
                  const date = new Date(isoDateString);
                  const day = date?.getDate()?.toString()?.padStart(2, "0");
                  const month = (date?.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = date?.getFullYear();
                  const formattedDate = `${day}/${month}/${year}`;
                  return (
                    <StyledTableRow
                      style={
                        index % 2
                          ? { background: "rgba(103, 30, 120, 0.1)" }
                          : { background: "white" }
                      }
                      key={index ?? 1}
                    >
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>

                      <StyledTableCell>{formattedDate}</StyledTableCell>
                      <StyledTableCell>{row?.subject ?? "--"}</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title={row?.description}>
                          {truncateString(row?.title, 10)} /{" "}
                          {truncateString(row?.description, 20) ?? "--"}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        {" "}
                        {row.status === 2 ? (
                          <span className="bg-[#ff00001a] text-[#ff0000] px-3 py-1 rounded-lg">
                            Closed
                          </span>
                        ) : (
                          <span className="bg-[#13f06673] text-[#035703fc] px-3 py-1 rounded-lg ">
                            Open
                          </span>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{row?.score ?? "--"}</StyledTableCell>
                      <StyledTableCell>
                        {row?.subjectTeacher ?? "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {classList(row.assignClasses)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          onClick={() => {
                            setId(row?.classWorkId);
                            setClassworkData({
                              subject: row?.subject,
                              classId: row?.classId,
                              subjectId: row?.subJectId,
                              classWorkTitle: row?.title,
                              expectedScore: row?.score,
                              description: row?.description,
                              file: row?.file,
                              schoolId: row?.schoolid,
                              className: row.className,
                              title: row?.title,
                              classWorkId: row?.classWorkId,
                              isActivated: row?.isActivate,
                              isSubmitted: row?.isSubmitted,
                              isQuestionAdded: row?.isQuestionadded,
                              isClosed: row?.isClosed,
                              assignClasses: row?.assignClasses,
                            });
                          }}
                        >
                          <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              bgcolor: "#671E78",
              color: "#fff",
              fontFamily: "Gilroy-Regular",
              px: 3,
            }}
          >
            <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
              <MenuItem onClick={handleOpenClass}>Status</MenuItem>
              <Link
                to={`/classwork/add-classwork/${encodeURIComponent(
                  JSON.stringify(classworkData)
                )}`}
              >
                <MenuItem>Manage Classwork</MenuItem>
              </Link>

              <MenuItem onClick={handleOpenModalEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteClasswork}>Delete</MenuItem>
              <MenuItem onClick={handleActivateModal}>
                Activate/Deactivate
              </MenuItem>
              <Link
                to={`/classwork/submission/${encodeURIComponent(
                  JSON.stringify(classworkData)
                )}`}
              >
                <MenuItem>Submission</MenuItem>
              </Link>
            </MenuList>
          </Box>
        </Popover>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleCloseModal}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              Setup Classwork
            </Typography>
            <Divider />
          </Box>
          <Box
            p={3}
            sx={{
              overflowY: {
                xs: "scroll",
                sm: "scroll",
                md: "scroll",
                lg: "scroll",
                xl: "auto",
              },
              height: {
                xs: "550px",
                sm: "550px",
                md: "550px",
                lg: "550px",
                xl: "auto",
              },
            }}
          >
            <Setup showModal={setOpenModal} />
          </Box>
        </Box>
      </Modal>
      {/* status */}
      <Modal
        open={openAddClass}
        onClose={handleCloseAddClass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={statusStyle}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleCloseAddClass}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              Status
            </Typography>
            <Divider />
          </Box>
          <Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Question(s) Added
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={classworkData?.isQuestionAdded} />
              </Box>
            </Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Activated
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={classworkData?.isActivated} />
              </Box>
            </Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Submitted
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={classworkData?.isSubmitted} />
              </Box>
            </Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Closed
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={classworkData?.isClosed} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalEdit}
        onClose={handleOpenModalEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box p={classworkData?.isActivated === true ? 3 : 2}>
            {classworkData?.isActivated === true ? (
              <Box sx={promptStyle}>
                <Box align="right" sx={{}}>
                  <IconButton onClick={handleOpenModalEdit}>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  This classwork has been activated, it cannot be edited
                </Typography>
              </Box>
            ) : (
              <Box sx={styleEdit}>
                <Box align="right" sx={{}}>
                  <IconButton onClick={handleOpenModalEdit}>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: "center",
                      mb: 2,
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Edit Classwork
                  </Typography>
                  <Divider />
                </Box>
                <EditClasswork
                  allClasses={classworkData}
                  showModal={setOpenModalEdit}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleOpenDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={DeleteModalStyle} className="bg-white  p-[20px]">
          <Box align="right">
            <IconButton onClick={handleOpenDeleteModal}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className="items-center flex flex-col justify-center">
            <Typography
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              You can not delete this classwork because questions have been
              added, activated or submitted
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={activateDeactivate}
        onClose={handleActivateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Typography>Activation</Typography>
          <Box sx={{ mt: 2 }} className="flex gap-5 items-center">
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                ml: 2,
                px: 3,
                textTransform: "initial",
                fontFamily: "gilroy-regular",
                "&:hover": {
                  bgcolor: "#d3302f",
                  color: "#fff",
                },
              }}
              onClick={handleDeactivate}
              disabled={
                loading ||
                classworkData?.isActivated === false ||
                // classworkData?.isQuestionAdded === true ||
                classworkData?.isSubmitted === true ||
                classworkData?.isClosed === true
              }
            >
              {loading ? <FormLoader /> : <span>Deactivate</span>}
            </Button>
            <Button
              onClick={handleActivate}
              disabled={
                loading ||
                classworkData?.isActivated === true ||
                classworkData?.isQuestionAdded === false ||
                classworkData?.isClosed === true
              }
              variant="contained"
              sx={{
                color: "#fff",
                ml: 2,
                px: 3,
                textTransform: "initial",
                fontFamily: "gilroy-regular",
                "&:hover": {
                  bgcolor: "green",
                  color: "#fff",
                },
              }}
            >
              {loading ? <FormLoader /> : <span>Activate</span>}
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default Classwork_First;

//setUp Modal 

const Data = [];
const Setup = ({ showModal }) => {
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [subjectID, setSubjectID] = useState(0);
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);
  const adtoken = localStorage.getItem("admToken");
  const [subjectLoader, setSubjectLoader] = useState(false);
  const [classLoader, setClassLoader] = useState(false);

  const [onCreateClasswork] = useCreateClassworkMutation();
  const inputRef = useRef();
  const handleChange = (event) => {
    setSubjectSelected(event.target.value);
  };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedClass(typeof value === "string" ? value.split(",") : value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    setSelectedFileName(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result; // This will contain the base64 data
        setSelectedFile(base64Data); // Set the base64 data using setSelectedFile
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    const input = inputRef.current;

    input.addEventListener("keydown", (e) => {
      if (e.key !== "Backspace" && e.key !== "Delete" && !/^\d$/.test(e.key)) {
        e.preventDefault();
      }
    });
    return () => {
      input.removeEventListener("keydown", (e) => {
        if (
          e.key !== "Backspace" &&
          e.key !== "Delete" &&
          !/^\d$/.test(e.key)
        ) {
          e.preventDefault();
        }
      });
    };
  }, [inputRef]);

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };

  const fetchSubjects = async () => {
    setSubjectLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${userInfo.schoolId}`,
        { headers }
      );
      if (response.status !== 200) {
        setSubjectLoader(false);
        console.log("Error: Unable to fetch data", response);
        return;
      }

      const data = response.data;
      setSubjectLoader(false);
      if (!data?.status) {
        return;
      }
      setSubjects(data?.data); // Save the subjects data in the state
    } catch (error) {
      setSubjectLoader(false);
      console.error("Error:", error.message);
    }
  };
  // console.log("setup", selectedClass);

  const handleSubmit = async () => {
    setLoading(true);
    const assignClasses = selectedClass.map(
      ({ classId, className, classArm }) => ({
        classId,
        className,
        classArm,
      })
    );
    
    const classworkData = {
      subjectId: subjectSelected,
      classId: 0,
      classWorkTitle: title,
      expectedScore: score,
      description: description,
      file: selectedFile ? selectedFile : "",
      // SubjectTeacher: "staff",
      // postedBy: "staff",
      // post_date: new Date(),
      schoolId: userInfo?.schoolId,
      assignClasses: assignClasses,
    };

    try {
      const res = await onCreateClasswork(classworkData)?.unwrap();
      if (!res?.status) {
        toast.error(res?.message, { type: "error" });
      } else {
        toast.success("You have successfully created a classwork");
        setResponseMessage("Successfully created  a classwork");
        showModal(false);
        setLoading(false);
      }
    } catch (err) {
      toast.error("Something went wrong!", { type: "error" });
      setErrorMessage("Error creating  a classwork");
      setLoading(false);
      showModal(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  //fetch classess
  const fetchClasses = async () => {
    setClassLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?subjectId=${subjectID}&schoolId=${userInfo.schoolId}`,
        { headers }
      );
      if (response.status !== 200) {
        setClassLoader(false);

        return;
      }
      setClassLoader(false);
      const data = response.data;
      if (!data?.status) {
        return;
      }
      setClassLoader(false);
      setClasses(data?.data); // Save the subjects data in the state
    } catch (error) {
      setClassLoader(false);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchSubjects();
    if (subjectID) {
      fetchClasses(); // Call the fetchClasses function when subjectId changes
    }
  }, [subjectID]);

  return (
    <>
      {loading && <LoaderLine />}
      {responseMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={true}
          autoHideDuration={300}
          // onClose={true}
        >
          <Alert
            severity="success"
            sx={{ width: "100%", fontFamily: "gilroy-regular" }}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={true}
          autoHideDuration={300}
          // onClose={true}
        >
          <Alert
            severity="error"
            sx={{ width: "100%", fontFamily: "gilroy-regular" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Choose Subject
              </InputLabel>

              <Select
                fullWidth
                placeholder="dssss"
                size="small"
                value={subjectSelected}
                onChange={handleChange}
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Choose Subject</em>
                </MenuItem>
                {subjectLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {subjects.length === 0 && !subjectLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Subject Available
                    </Typography>
                  </Box>
                ) : (
                  subjects.length > 0 &&
                  subjects.map((subject, _index) => (
                    <MenuItem
                      key={_index}
                      value={subject.subjectId}
                      onClick={(e) => {
                        setSubjectName(subject.subjectName);
                        setSubjectID(subject.subjectId);
                        // No need to call fetchClasses() here, it's triggered by useEffect
                        setSelectedClass([]);
                      }}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      {subject.subjectName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Choose Class
              </InputLabel>
              <Select
                fullWidth
                placeholder="Select Class"
                size="small"
                value={selectedClass}
                multiple
                onChange={handleChange2}
                renderValue={(selected) =>
                  Array.isArray(selected)
                    ? selected
                        .map(
                          (val) =>
                            `${val.className} ${val.classArm}` || val.classId
                        )
                        .join(", ")
                    : ""
                }
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Select Class</em>
                </MenuItem>
                {classLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {classes.length === 0 && !classLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        mt: 2,
                        mb: 1,
                        color: "#333",
                      }}
                    >
                      No Class Found.
                    </Typography>
                  </Box>
                ) : (
                  classes.map((each, index) => (
                    <MenuItem
                      key={index}
                      value={each}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      <Checkbox
                        checked={selectedClass.some(
                          (selected) => selected.classId === each.classId
                        )}
                      />
                      <ListItemText
                        primary={`${each?.className} ${each?.classArm}`}
                      />
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Title
              </InputLabel>
              <TextField
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                      fontFamily: "gilroy-regular",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Time
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    value={new Date()}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #671E78",
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        fontFamily: "Gilroy-regular",
                        fontSize: "13px",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Score
                  </InputLabel>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    ref={inputRef}
                    onChange={(e) => setScore(e.target.value)}
                    value={score}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #671E78",
                          fontFamily: "gilroy-regular",
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        fontFamily: "Gilroy-regular",
                        fontSize: "13px",
                      },
                      inputProps: {
                        pattern: "[0-9]", // Allow only numbers
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
                mt: 3,
              }}
            >
              Upload Picture (optional)
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                },
              }}
            />
          </Grid>

          {/* <Grid
            item
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: { lg: "80%", xs: "70%" } }}>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Upload Picture(optional)
              </InputLabel>
              <Box
                sx={{
                  border: "1px solid #671E78",
                  borderRadius: "5px",
                  py: 1,
                  px: 2,
                  height: "45px",
                }}
              >
                {selectedFile && (
                  <p style={{ fontFamily: "gilroy-regular", fontSize: "14px" }}>
                    {" "}
                    {selectedFileName}
                  </p>
                )}
              </Box>
            </Box>
            <Box align="center" sx={{ mt: 4, ml: 2 }}>
              <Button
                variant="contained"
                component="label"
                sx={{ fontFamily: "gilroy-regular" }}
              >
                upload
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
          </Grid> */}
        </Grid>

        {/* <Box mt={5}>
          <Table sx={{ border: "1px solid #671E78", borderRadius: "20px" }}>
            <TableHead sx={{ bgcolor: "#671E781a" }}>
              <TableCell sx={{ fontFamily: "Gilroy-Medium" }}>S/N</TableCell>
              <TableCell sx={{ fontFamily: "Gilroy-Medium" }}>
                File Name
              </TableCell>
              <TableCell sx={{ fontFamily: "Gilroy-Medium" }}>
                Upload Date
              </TableCell>
              <TableCell
                sx={{ fontFamily: "Gilroy-Medium" }}
                align="right"
              ></TableCell>
              <TableCell
                sx={{ fontFamily: "Gilroy-Medium" }}
                align="right"
              ></TableCell>
            </TableHead>
            <TableBody>
              {Data.map((item, i) => (
                <TableRow>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.file}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "initial" }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "initial" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box> */}

        <Box mt={5}>
          <Button
            disabled={!score || !description || !title}
            variant="contained"
            fullWidth
            sx={{ textTransform: "initial" }}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Box>
      </Box>
    </>
  );
};

const EditClasswork = ({ allClasses, showModal }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [subjectID, setSubjectID] = useState(allClasses?.subjectId);
  const [selectedFile, setSelectedFile] = useState(allClasses?.file);
  const [description, setDescription] = useState(allClasses?.description);
  const [title, setTitle] = useState(allClasses?.title);
  const [subjects, setSubjects] = useState([]);
  const [score, setScore] = useState(allClasses?.expectedScore);
  const [responseMessage, setResponseMessage] = useState("");
  const [subjectLoader, setSubjectLoader] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(allClasses?.assignClasses);
  const [subjectSelected, setSubjectSelected] = useState(allClasses?.subject);
  const [onEditClasswork] = useEditClassworkMutation();
  const [classLoader, setClassLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showClasses, setShowClasses] = useState(false);
  const inputRef = useRef();
  const classesRef = useRef(null);
  const adtoken = localStorage.getItem("admToken");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);

  const handleChange = (event) => {
    setSubjectSelected(event.target.value);
    setSubjectID(event.target.value);
  };

  const handleChange2 = (event) => {
    const { value } = event.target;
    const values = typeof value === "string" ? value.split(",") : value;

    setSelectedClass((prevState) => {
      // Convert prevState to an array of classIds
      const selectedClassIds = prevState.map((item) => item.classId);

      // Convert new values to an array of classIds
      const newClassIds = values.map((item) => item.classId);

      // Classes to add
      const classesToAdd = values.filter(
        (valueItem) => !selectedClassIds.includes(valueItem.classId)
      );

      // Classes to remove
      const classesToRemove = prevState.filter(
        (item) => !newClassIds.includes(item.classId)
      );

      // Filter out the classes to remove from prevState
      const newState = prevState.filter(
        (item) =>
          !classesToRemove.some((toRemove) => toRemove.classId === item.classId)
      );

      // Add the new classes
      return [...newState, ...classesToAdd];
    });
  };

  const fetchSubjects = async () => {
    setSubjectLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${userInfo.schoolId}`,
        { headers }
      );
      if (response.status !== 200) {
        setSubjectLoader(false);
        console.log("Error: Unable to fetch data", response);
        return;
      }

      const data = response.data;
      setSubjectLoader(false);
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setSubjects(data?.data); // Save the subjects data in the state
    } catch (error) {
      setSubjectLoader(false);
      console.error("Error:", error.message);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    setSelectedFileName(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result; // This will contain the base64 data
        console.log("this is the file", base64Data);
        setSelectedFile(base64Data); // Set the base64 data using setSelectedFile
      };

      // Read the file as a data URL, which will give you the base64 representation
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const classWorkId = allClasses.classWorkId; // Get classWorkId once

    const assignedClasses = selectedClass?.map((each) => ({
      classId: each.classId,
      className: each.className,
      classarm: each.classArm,
    }));

    const { ...classworkEditData } = {
      subjectId: subjectID ?? 0, // Set the correct subjectId
      classWorkTitle: title,
      expectedScore: score,
      description: description,
      file: selectedFile ?? "",
      schoolId: userInfo.schoolId,
      classId: allClasses.classId, // Include classWorkId in the body
      assignClasses:
        assignedClasses.length > 0
          ? assignedClasses
          : allClasses?.assignClasses,
    };
    try {
      const res = await onEditClasswork({
        classWorkId,
        ...classworkEditData,
      })?.unwrap();
      if (!res?.status) {
        toast.error(res?.message, { type: "error" });
      }
      toast.success(res?.data, { type: "success" });
      setResponseMessage("You have edited this classwork: " + res?.message);
      setLoading(false);
      showModal(false);
    } catch (err) {
      toast.error("Something went wrong!", { type: "error" });
      setErrorMessage("There was an error trying to edit classwork: " + err);
      setLoading(false);
      showModal(false);
    }
  };

  const fetchClasses = async () => {
    setClassLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?subjectId=${subjectID}&schoolId=${userInfo.schoolId}`,
        { headers }
      );

      if (response.status !== 200) {
        setClassLoader(false);

        return;
      }
      setClassLoader(false);
      const data = response.data;
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setClassLoader(false);
      setClasses(data?.data); // Save the subjects data in the state
      // console.log(subjects, "Walle")
    } catch (error) {
      setClassLoader(false);
      console.error("Error:", error.message);
    }
  };

  const handleToggleClass = async (classItem, e) => {
    e.stopPropagation();

    const isSelected = selectedClass.some(
      (selected) => selected.classId === classItem.classId
    );
    const url = `${endpoint_two}/api/v${api_version}/ClassWork/RemoveClass-FromClassWork?classId=${classItem.classId}&classworkId=${allClasses.classWorkId}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adtoken}`,
    };
    if (isSelected) {
      try {
        const res = await axios.delete(url, { headers });
        // if (res && res.statusCode === 2) {
        setSelectedClass((prevSelected) =>
          prevSelected.filter(
            (selected) => selected.classId !== classItem.classId
          )
        );
        // }
      } catch (error) {
        console.error("Error removing class:", error);
      }
    } else {
      setSelectedClass((prevSelected) => [...prevSelected, classItem]);
    }
  };

  useEffect(() => {
    fetchSubjects();
    // if (subjectID) {
    fetchClasses();
    // }
  }, [subjectID]);
  useEffect(() => {
    const input = inputRef.current;

    input.addEventListener("keydown", (e) => {
      if (e.key !== "Backspace" && e.key !== "Delete" && !/^\d$/.test(e.key)) {
        e.preventDefault();
      }
    });
    return () => {
      input.removeEventListener("keydown", (e) => {
        if (
          e.key !== "Backspace" &&
          e.key !== "Delete" &&
          !/^\d$/.test(e.key)
        ) {
          e.preventDefault();
        }
      });
    };
  }, [inputRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        classesRef.current &&
        !classesRef.current.contains(e.target) &&
        !e.target.closest(".toggle-class-btn")
      ) {
        setShowClasses(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading && <LoaderLine />}
      {responseMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={true}
          autoHideDuration={300}
          // onClose={true}
        >
          <Alert
            severity="success"
            sx={{ width: "100%", fontFamily: "gilroy-regular" }}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={true}
          autoHideDuration={300}
          // onClose={true}
        >
          <Alert
            severity="error"
            sx={{ width: "100%", fontFamily: "gilroy-regular" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
                onClick={fetchSubjects}
              >
                Choose Subject
              </InputLabel>
              <Select
                fullWidth
                placeholder="dssss"
                size="small"
                value={subjectID}
                onChange={handleChange}
                sx={{
                  fontSize: "13px",
                  border: "0.5px solid #671E78",
                  fontFamily: "gilroy-regular",
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    fontSize: "13px",
                    borderRadius: "10px",
                    fontFamily: "gilroy-regular",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em> Choose Subject</em>
                </MenuItem>
                {subjectLoader && (
                  <Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "2rem", mx: 2 }}
                    />
                    <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem", mx: 2 }}
                    />
                  </Box>
                )}
                {subjects.length === 0 && !subjectLoader ? (
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#333",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      No Subject Available
                    </Typography>
                  </Box>
                ) : (
                  subjects.length > 0 &&
                  subjects.map((subject, _index) => (
                    <MenuItem
                      key={_index}
                      value={subject.subjectId}
                      onClick={(e) => {
                        // setSubjectName(subject.subjectName);
                        setSubjectID(subject.subjectId);
                        // No need to call fetchClasses() here, it's triggered by useEffect
                        setSelectedClass([]);
                      }}
                      sx={{ fontFamily: "gilroy-regular", fontSize: "13px" }}
                    >
                      {subject.subjectName}
                    </MenuItem>
                  ))
                )}
              </Select>
              {/* <TextField
                fullWidth
                value={selectedSubject}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                      fontFamily: "gilroy-regular",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              /> */}
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} ref={classesRef}>
            <Box position={"relative"}>
              <button
                className="mb-[2px] mt-[30px] text-black font-bold flex justify-between w-full border border-primary p-2 rounded"
                onClick={() => setShowClasses(!showClasses)}
              >
                <span> Edit Class</span>
                {showClasses ? <FaSortUp /> : <FaSortDown />}
              </button>
              <div
                className={`flex flex-col bg-white  mt-5 gap-2 absolute w-full ${
                  showClasses ? "z-10 shadow-md" : "z-0 shadow-none"
                } py-5 px-3`}
              >
                {showClasses &&
                  classes.map((each) => {
                    const isSelected = selectedClass.some(
                      (selected) => selected.classId === each.classId
                    );

                    return (
                      <div
                        key={each.classId}
                        className="flex justify-between items-center gap-3"
                      >
                        {`${each.className} ${
                          each.classArm ? each.classArm : ""
                        }`}
                        <button
                          className="toggle-class-btn"
                          onClick={(e) => handleToggleClass(each, e)}
                        >
                          {isSelected ? (
                            <IoRemoveCircleOutline className="text-red-500 w-5 h-5" />
                          ) : (
                            <IoAddCircleOutline className="text-green-500 w-5 h-5" />
                          )}
                        </button>
                      </div>
                    );
                  })}
              </div>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Title
              </InputLabel>
              <TextField
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                      fontFamily: "gilroy-regular",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Time
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    value={new Date()}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #671E78",
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        fontFamily: "Gilroy-regular",
                        fontSize: "13px",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Score
                  </InputLabel>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    onChange={(e) => setScore(e.target.value)}
                    value={score}
                    ref={inputRef}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #671E78",
                          fontFamily: "gilroy-regular",
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        fontFamily: "Gilroy-regular",
                        fontSize: "13px",
                      },
                      inputProps: {
                        pattern: "[0-9]*", // Allow only numbers
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt: 1,
                  fontSize: "12px",
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
                mt: 3,
              }}
            >
              Upload Picture(optional)
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* <Box mt={5}>
          <Table sx={{ border: "1px solid #671E78", borderRadius: "20px" }}>
            <TableHead sx={{ bgcolor: "#671E781a" }}>
              <TableCell sx={{ fontFamily: "Gilroy-Medium" }}>S/N</TableCell>
              <TableCell sx={{ fontFamily: "Gilroy-Medium" }}>
                File Name
              </TableCell>
              <TableCell sx={{ fontFamily: "Gilroy-Medium" }}>
                Upload Date
              </TableCell>
              <TableCell
                sx={{ fontFamily: "Gilroy-Medium" }}
                align="right"
              ></TableCell>
              <TableCell
                sx={{ fontFamily: "Gilroy-Medium" }}
                align="right"
              ></TableCell>
            </TableHead>
            <TableBody>
              {Data.map((item, i) => (
                <TableRow>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.file}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "initial" }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "initial" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box> */}

        <Box mt={5}>
          <Button
            disabled={!score || !description || !title}
            variant="contained"
            fullWidth
            sx={{ textTransform: "initial" }}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
};
